import { BeadlState } from "@customTypes/model/beadlEditor/beadlState";
import {
  BeadlEditorData,
  BeadlEditorState,
  BeadlFlowData,
} from "@customTypes/store/beadlEditor";
import { Edge, Elements } from "react-flow-renderer";
import { STATE_EXPIRED_EVENT_ID } from "@util/constants";

export const checkIfStateExpiredRequired = (beadlState: BeadlState) =>
  beadlState.duration.value !== undefined &&
  beadlState.duration.value !== "" &&
  beadlState.duration.value !== "inf";

export const checkIfStateExpiredExists = (beadlState: BeadlState) =>
  beadlState.events &&
  beadlState.events[0] &&
  beadlState.events[0].event_id === STATE_EXPIRED_EVENT_ID;


export const getBeadlEditorStateNameIndexMap = (
  flowElements: Elements<BeadlFlowData>
): BeadlEditorData["stateNameIndexMap"] =>
  flowElements.reduce((stateNameIndexMap, flowElement, index) => {
    const edgeFlowElement = flowElement as Edge;
    if (
      edgeFlowElement.source === undefined &&
      edgeFlowElement.target === undefined
    ) {
      stateNameIndexMap[(flowElement.data as BeadlState).name] = index;
    }
    return stateNameIndexMap;
  }, {} as BeadlEditorData["stateNameIndexMap"]);

export const updateActiveTabData = (
  state: BeadlEditorState,
  flowElements?: BeadlEditorData["flowElements"],
  stateNameIndexMap?: BeadlEditorData["stateNameIndexMap"]
) => ({
  ...state,
  tabNameDataMap: {
    ...state.tabNameDataMap,
    [state.activeTab]: {
      flowElements:
        flowElements || state.tabNameDataMap[state.activeTab].flowElements,
      stateNameIndexMap:
        stateNameIndexMap ||
        state.tabNameDataMap[state.activeTab].stateNameIndexMap,
    },
  },
});
