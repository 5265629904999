import { UserOrganization } from '../../types';
import { createReducer } from '@reduxjs/toolkit';
import { addOrganization, deselectOrganization, fetchOrganizations, selectOrganization, updateOrganization } from './organization.actions';
import { uniqBy } from 'lodash';

type OrganizationState = {
  pending: boolean;
  organizations: UserOrganization[];
  selectedOrganization: UserOrganization | null;
  totalCount: number;
}

const initialState : OrganizationState = {
  organizations: [],
  selectedOrganization: null,
  pending: false,
  totalCount: 0
};

export const organizationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchOrganizations.pending, (state) => {
      state.pending = true;
    })
    .addCase(fetchOrganizations.fulfilled, (state, { payload }) => {
      state.organizations = payload.organizations || [];
      state.totalCount = payload.totalCount;
      state.pending = false;
    })
    .addCase(addOrganization, (state, { payload }) => {
      state.organizations = uniqBy([...state.organizations, payload], 'id');
    })
    .addCase(updateOrganization, (state, { payload }) => {
      state.organizations = state.organizations.map((t) => (
        t.id === payload.id ? { ...payload } : t
      ));
    })
    .addCase(selectOrganization, (state, { payload }) => {
      state.selectedOrganization = payload;
    })
    .addCase(deselectOrganization, (state, { payload }) => {
      state.selectedOrganization = null;
    })
});
