import { Button, Form, Input } from "antd";
import { useSignIn } from '../../../hooks';

export const FirstTimeLogin = () => {
  const { loading, onFirstTimeLogin } = useSignIn();

  return (
    <div className="flex items-center justify-center h-screen bg-indigo-100">
      <div className="w-2/3 lg:w-2/5 md:w-1/2">
        <div className="min-w-full p-10 bg-white shadow-lg">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFirstTimeLogin}
            autoComplete="off"
          >
          <h1 className="mb-6 text-2xl font-bold text-center text-gray-600">Reset your password!</h1>
          <div>
            <label className="block my-3 font-semibold text-left text-gray-800 text-md">Enter New Password</label>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
                () => ({
                  validator(_, value) {
                    let regExp = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,);
                    if (!value || regExp.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Password must contain number, uppercase, lowercase, one special character. Password must be at least 8 characters long!'));
                  },
                }),
              ]}
            >
            <Input.Password
              className="w-full bg-gray-100 cs_input focus:outline-none"
              style={{ width: 'calc(100% + 50%)' }}
            />
            </Form.Item>
          </div>
          <div>
            <label className="block my-3 font-semibold text-left text-gray-800 text-md">Repeat Password</label>
            <Form.Item
              name="rPassword"
              dependencies={['password']}
              rules={[
                {
                  required: true,
                  message: 'Please repeat your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }),
                () => ({
                  validator(_, value) {
                    let regExp = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,);
                    if (!value || regExp.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Password must contain number, uppercase, lowercase, one special character. Password must be at least 8 characters long!'));
                  },
                }),
              ]}
            >
            <Input.Password
              className="w-full bg-gray-100 cs_input focus:outline-none"
              style={{ width: 'calc(100% + 50%)' }}
            />
            </Form.Item>
          </div>
          <Button
            htmlType="submit"
            loading={loading}
            type="primary"
            className="mt-6 text-lg font-semibold tracking-wide text-white cs_input"
          >
            Submit
          </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};
