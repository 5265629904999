import { uniqBy } from 'lodash';
import { User, UserProfile } from '../../types';
import { createReducer } from '@reduxjs/toolkit';
import { fetchAllUsers, selectUser, deselectUser, deleteUser, fetchUserProfile } from './user.actions';
import { addUser, updateUser } from '.';

type AllUsers = {
  pending: boolean;
  users: User[];
  selectedUser: User | null;
  userProfile: UserProfile | null;
  totalCount: number;
}

const initialState : AllUsers = {
  users: [],
  selectedUser: null,
  userProfile: null,
  totalCount: 0,
  pending: false,
};

export const userReducer = createReducer(initialState, (builder) => {
  builder
  .addCase(fetchAllUsers.pending, (state) => {
    state.pending = true;
  })
  .addCase(fetchAllUsers.fulfilled, (state, { payload }) => {
    state.users = payload.users;
    state.totalCount = payload.totalCount || 0;
    state.pending = false;
  })
  .addCase(fetchAllUsers.rejected, (state) => {
    state.pending = false;
  })
  .addCase(fetchUserProfile.pending, (state) => {
    state.pending = true;
  })
  .addCase(fetchUserProfile.fulfilled, (state, { payload }) => {
    state.userProfile = payload;
    state.totalCount = 0;
    state.pending = false;
  })
  .addCase(fetchUserProfile.rejected, (state) => {
    state.pending = false;
  })
.addCase(addUser, (state, { payload }) => {
      state.users = uniqBy([...state.users, payload], 'id');
    })
    .addCase(updateUser, (state, { payload }) => {
      state.users = state.users.map((u) => (
        u.id === payload.id ? { ...payload, lastLogin: u.lastLogin } : u
      ));
    })
    .addCase(selectUser, (state, { payload }) => {
      state.selectedUser = payload;
    })
    .addCase(deselectUser, (state, { payload }) => {
      state.selectedUser = null;
    })
    .addCase(deleteUser, (state, { payload }) => {
      state.users = state.users.filter((u) => u.id !== payload);
    });
});
