import { useState } from 'react';
import { clearAllUserLogs, fetchAllUserLogs, userAllLogsSelector } from '@store/log-event';
import { Card, Pagination, Tabs, Typography } from 'antd';
import { PAGINATION_LIMIT } from '../../../../config/constants.config';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { fetchUserProfile, selectUserProfile } from '@store/user';
import { useComponentDidMount, useComponentDidUnmount } from '../../../../hooks';

const limit = PAGINATION_LIMIT;

export const SingleUserProfile = () => {
  const [defaultPage, setDefaultPage] = useState(1);
  const { id: user_id } = useParams();

  const dispatch = useDispatch();

  useComponentDidMount(() => {
    if (user_id) {
      dispatch(fetchUserProfile(user_id))
      dispatch(fetchAllUserLogs({
        user_id,
        params: { limit, offset: 0 }
      }));
    }
  });

  useComponentDidUnmount(() => dispatch(clearAllUserLogs()));

  const userProfile = useSelector(selectUserProfile);

  const { userLoginEvents, totalCount } = useSelector(userAllLogsSelector);

  if (!user_id || !userProfile) return <div />;

  return (
    <Tabs>
      <Tabs.TabPane tab="Details" key="details">
        <Card>
          <div className="flex flex-col w-full text-left">
            <div className="flex flex-row p-2">
              <div className="p-2 w-36">Name:</div>
              <div className="p-2 text-base italic font-bold w-60">
                {userProfile?.user?.firstName}
              </div>
            </div>
            <div className="flex flex-row p-2">
              <div className="p-2 w-36">Username:</div>
              <div className="p-2 text-base italic font-bold w-60">
                {userProfile?.user?.username}
              </div>
            </div>
            <div className="flex flex-row p-2">
              <div className="p-2 w-36">Email:</div>
              <div className="p-2 text-base italic font-bold w-60">
                {userProfile?.user?.email}
              </div>
            </div>
            <div className="flex flex-row p-2">
              <div className="p-2 w-36">Organization:</div>
              <div className="p-2 text-base italic font-bold w-60">
                {userProfile?.user?.organization?.title}
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full text-left">
            <div className="flex flex-row p-2">
              <div className="p-2 w-36">Files count :</div>
              <div className="p-2 text-base italic font-bold w-60">
                {userProfile.filesCount}
              </div>
            </div>

            <div className="flex flex-row p-2">
              <div className="p-2 w-36">Folders count :</div>
              <div className="p-2 text-base italic font-bold w-60">
                {userProfile.foldersCount}
              </div>
            </div>
          </div>
        </Card>
      </Tabs.TabPane>
      <Tabs.TabPane tab="Logs" key="logs">
        <Card>
          <div className="text-left">
            {userLoginEvents.map((d) => (
              <Typography key={d.id}>{dayjs(d.createdAt).format('lll')}</Typography>
            ))}

            {totalCount > userLoginEvents.length && (
              <Pagination
                simple
                className='flex-end'
                current={defaultPage}
                total={totalCount}
                pageSize={limit}
                onChange={async (page: number) => {
                  setDefaultPage(page)
                  dispatch(fetchAllUserLogs({
                    user_id,
                    params: { limit, offset: limit * (page - 1) },
                  }))
                }}
              />
            )}
          </div>
        </Card>
      </Tabs.TabPane>
    </Tabs>
  );
};
