import { Workspace, WorkspaceFile, WorkspaceFolder, WorkspaceItemType, WorkspaceSupportedKeyboardKeys } from '../../types';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';
import axios from 'axios';
import { sendError } from '@util/helpers/notifications.helper';

export const fetchMyWorkspace = createAsyncThunk<Workspace>('workspace/fetchMyWorkspace', async () => {
  try {
    const { data } = await api.get<Workspace>('/workspaces');

    return data;
  } catch(err) {
    sendError(err);
    throw err;
  }
});

export const fetchMyWorkspaceFile = createAsyncThunk<string, string>('workspace/fetchMyWorkspaceFile', async (id) => {
  try {
    const { data } = await api.get<string>(`/uploads/${id}`);
    if (!data) throw new Error();

    const { data: jsonData } = await axios.get(data);

    return jsonData;
  } catch (err) {
    sendError(err);
    throw err;
  }
});

export const selectWorkspaceItem = createAction<WorkspaceItemType | null>('workspace/selectWorkspaceItem');
export const updateWorkspaceItemByKeyboard = createAction<WorkspaceSupportedKeyboardKeys>('workspace/updateWorkspaceItemByKeyboard');
export const updateWorkspaceItem = createAction<WorkspaceFile | WorkspaceFolder>('workspace/updateWorkspaceItem');
export const addWorkspaceFolder = createAction<WorkspaceFolder>('workspace/addWorkspaceFolder');
export const addWorkspaceFile = createAction<WorkspaceFile>('workspace/addWorkspaceFile');
export const setOpenFolder = createAction<WorkspaceFolder | null>('workspace/setOpenFolder');
export const deleteItem = createAction<string>('workspace/deleteItem');
