import { User } from "./user.types";

export type Notification = {
  id: string;
  notification_types: NotificationType[];
  user: User;
  createdAt: Date;
  updatedAt: Date;

}

export interface NotificationResponse {
  notifications: Notification[];
  totalCount: number;
  limit: number;
  offset: number;
}


export enum NotificationType {
  NEW_USER_REQUEST = 'NEW_USER_REQUEST',
}

export const notificationTypeColors = ['green', 'magenta', 'blue', 'purple', 'volcano', 'cyan'];

export interface NotificationForm {
  notification_types: NotificationType[];
  users: string[];
}

export interface NotificationUpdateForm {
  notification_types: NotificationType[];
  user: string;
}
