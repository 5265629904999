import Space from "antd/lib/space";
import { DrawerProps } from "@customTypes/components/core/Drawer";
import Button from "antd/lib/button";

type HeaderActionsProps = Pick<
  DrawerProps,
  "successAction" | "failAction" | "additionalActions"
>;

const HeaderActions = ({
  successAction,
  failAction,
  additionalActions = [],
}: HeaderActionsProps) => {
  const {
    action: successOnClick,
    children: successChildren,
    ...successButtonProps
  } = successAction || {};
  const {
    action: failOnClick,
    children: failChildren,
    ...failButtonProps
  } = failAction || {};
  return successAction || failAction || additionalActions.length > 0 ? (
    <Space>
      {additionalActions.map(({ label, action, ...buttonProps }, index) => (
        <Button
          key={`additional-header-action-${index}`}
          {...buttonProps}
          onClick={action}
        >
          {label}
        </Button>
      ))}
      <Button {...failButtonProps} onClick={failOnClick}>
        {failAction?.label || failChildren}
      </Button>
      <Button type="primary" {...successButtonProps} onClick={successOnClick}>
        {successAction?.label || successChildren}
      </Button>
    </Space>
  ) : (
    <></>
  );
};

export default HeaderActions;
