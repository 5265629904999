import { User, UserProfile } from '../../types';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';
import { sendError } from '@util/helpers/notifications.helper';
import { IPaginationVars } from 'types/pagination.types';

interface Response {
  users: User[];
  totalCount: number;
  limit: number;
  offset: number;
}

export const fetchAllUsers = createAsyncThunk<Response, { payload: IPaginationVars }>(
  'user/allUsers',
  async ({ payload }) => {
  try {
    const { data } = await api.get<Response>('/users', { params: payload });

    return data;
  } catch (err) {
    sendError(err);
    throw err;
  }
});


export const fetchUserProfile = createAsyncThunk<UserProfile, string>(
  'user/profile',
  async (id) => {
  try {
    const { data } = await api.get<UserProfile>(`/users/profile/${id}`);
    if (!data) throw new Error();

    return data;
  } catch (err) {
    sendError(err);
    throw err;
  }
});

export const selectUser = createAction<User | null>('user/selectUser');
export const deselectUser = createAction('user/deselectUser');
export const addUser = createAction<User>('user/addUser');
export const updateUser = createAction<User>('user/updateUser');
export const deleteUser = createAction<string>('user/deleteUser');
