import { useCallback, useEffect } from "react";
import FormItem from "@components/BeadlEditor/Form/Item";
import Input from "@components/core/Input";
import { ProtocolSettingsProps } from "@customTypes/components/Main/LeftSiderContent/ProtocolSettings";
import HardwareSelector from "@components/selectors/HardwareSelector";
import StateSelector from "@components/selectors/StateSelector";
import { ProtocolSettingsState, UpdateProtocolSettingPayload, } from "@customTypes/store/beadlConfiguration";
import { setProtocolSettingsValue } from "@store/beadlConfiguration";
import { RootState } from "@store/index";
import { noEmptyRule } from "@util/ruleGenerators";
import Form, { useForm } from "antd/lib/form/Form";
import Title from "antd/lib/typography/Title";
import { useDispatch, useSelector } from "react-redux";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import { CustomTooltip } from "@components/CustomTooltip";
import { workspaceTooltip } from "../../../../tooltip.text";

const ProtocolSettings = (props: ProtocolSettingsProps) => {
  const { form: propForm, handleSave } = props;

  const protocolSettings = useSelector<RootState, ProtocolSettingsState>(
    (state) => state.beadlConfiguration.protocolSettings
  );
  const dispatch = useDispatch();

  const [form] = useForm<ProtocolSettingsState>(propForm);

  const handleChange = useCallback(
    (field: keyof ProtocolSettingsState, value: number | string) => {
      dispatch(
        setProtocolSettingsValue({
          field,
          value,
        } as UpdateProtocolSettingPayload)
      );
    },
    [dispatch]
  );

  useEffect(() => {
    form.setFieldsValue({ ...protocolSettings });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [protocolSettings]);

  const handleSubmit = useCallback(() => {
    handleSave && handleSave(protocolSettings);
  }, [protocolSettings, handleSave]);

  const validateNumberOfTrials = (value: string | number) => {
    if( (isNaN(value as number) && (value as string).toLowerCase() !== 'inf') || (value < 1)){
      form.setFields([
        {
          name:'numberOfTrials',
          errors: ["Only a valid positive number or 'INF' allowed!"],
        }
      ])
    }
  }

  return (
    <Form className="w-full" form={form} onFinish={handleSubmit} layout="vertical">
      <Col className="text-center align-middle" span={24}>
        <Title level={4} className="text-lg truncate">Protocol Settings</Title>
      </Col>
      <Col span={24}>
        <FormItem
          name="name"
          label={<CustomTooltip title="Protocol name" tooltipText={workspaceTooltip.protocolSettings.protocolName} />}
          rules={[noEmptyRule("Name")]}
        >
          <Input
            size="middle"
            value={protocolSettings.name}
            onChange={(event) => handleChange("name", event.target.value)}
            type="text"
            className="w-full"
          />
        </FormItem>
      </Col>
      <Col span={24}>
        <FormItem
          name="hardwareId"
          label={<CustomTooltip title="Hardware" tooltipText={workspaceTooltip.protocolSettings.hardware} />}
          rules={[noEmptyRule("Hardware")]}
        >
          <HardwareSelector
            size="middle"
            selected={protocolSettings.hardwareId}
            onChange={(value) => handleChange("hardwareId", value)}
            className="w-full"
          />
        </FormItem>
      </Col>
      <Col span={24}>
        <FormItem
          name="initialStateId"
          label={<CustomTooltip title="Select start state" tooltipText={workspaceTooltip.protocolSettings.startRate} />}
          rules={[noEmptyRule("Start State")]}
        >
          <StateSelector
            size="middle"
            selected={protocolSettings.initialStateId}
            onChange={(value) => handleChange("initialStateId", value)}
            className="w-full"
          />
        </FormItem>
      </Col>
      <Col span={24}>
        <FormItem
          name="numberOfTrials"
          label={<CustomTooltip title="Number of trials" tooltipText={workspaceTooltip.protocolSettings.noTrails} />}
          rules={[
            noEmptyRule("Number of trials")
          ]}
        >
          <Input
            size="middle"
            value={protocolSettings.numberOfTrials}
            onChange={(event) =>
              handleChange("numberOfTrials", event.target.value)
            }
            type="text"
            className="w-full"
            onBlur={(e) => validateNumberOfTrials(e.target.value)}
          />
        </FormItem>
      </Col>
      <Button hidden htmlType="submit">
        Export to xml
      </Button>
    </Form>
  );
};

export default ProtocolSettings;
