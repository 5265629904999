import { Workspace, WorkspaceFile, WorkspaceFolder, WorkspaceItemType, WorkspaceSupportedKeyboardKeys } from '../../types';
import { createReducer } from '@reduxjs/toolkit';
import { addWorkspaceFile, addWorkspaceFolder, deleteItem, fetchMyWorkspace, selectWorkspaceItem, setOpenFolder, updateWorkspaceItem, updateWorkspaceItemByKeyboard } from './workspace.actions';

type WorkspaceState = {
  pending: boolean;
  workspace: Workspace | null;
  currentOpenFolder: WorkspaceFolder | null;
  selectedItem: WorkspaceItemType | null;
  subfolders: WorkspaceFolder[];
  subfiles: WorkspaceFile[];
}

const initialState : WorkspaceState = {
  workspace: null,
  selectedItem: null,
  currentOpenFolder: null,
  subfolders: [],
  subfiles: [],
  pending: false,
};

export const workspaceReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchMyWorkspace.pending, (state) => {
      state.pending = true;
    })
    .addCase(fetchMyWorkspace.fulfilled, (state, { payload }) => {
      state.workspace = payload;
      state.pending = false;
    })
    .addCase(selectWorkspaceItem, (state, { payload }) => {
      state.selectedItem = payload;
    })
    .addCase(updateWorkspaceItemByKeyboard, (state, { payload }) => {
      const isNextId = payload === WorkspaceSupportedKeyboardKeys.ArrowDown;
      const folderToConsider = state.currentOpenFolder || state.workspace;
      const combineState = [...folderToConsider?.folders || [], ...folderToConsider?.files || []];
      const totalCount = combineState.length;
      const lastIndex = totalCount - 1;

      if (!state.selectedItem) {
        const newSelectedItemId = isNextId
          ? combineState[0] || null
          : combineState[lastIndex] || null;

        state.selectedItem = newSelectedItemId as WorkspaceItemType | null;

        return;
      }

      const currentSelectedIdIndex = combineState.findIndex((item) => item.id === state.selectedItem?.id);
      const nextIndex = (currentSelectedIdIndex + 1) === totalCount ? 0 : currentSelectedIdIndex + 1;
      const prevIndex = (currentSelectedIdIndex - 1) < 0 ? lastIndex : currentSelectedIdIndex - 1;

      const newSelectedItemId = isNextId
          ? combineState[nextIndex] || null
          : combineState[prevIndex] || null;

      state.selectedItem = newSelectedItemId as WorkspaceItemType | null;
    })
    .addCase(addWorkspaceFolder, (state, { payload }) => {
      if (!state.workspace) return;
      if (!payload.parentFolder?.id) {
        state.workspace.folders.push(payload);
        return;
      }

      state.subfolders.push(payload);
    })
    .addCase(addWorkspaceFile, (state, { payload }) => {
      if (!payload.folder?.id) {
        state.workspace?.files.push(payload);
        return;
      }

      state.subfiles.push(payload);
    })
    .addCase(setOpenFolder, (state, { payload }) => {
      state.currentOpenFolder = payload;
    })
    .addCase(deleteItem, (state, { payload: id }) => {
      if (state.currentOpenFolder?.id === id) {
        state.currentOpenFolder = null;
      }
      state.selectedItem = null;
      state.subfiles = state.subfiles.filter((s) => s.id !== id);
      state.subfolders = state.subfolders.filter((s) => s.id !== id);
      if (state.workspace?.files) {
        state.workspace.files = state.workspace?.files.filter((s) => s.id !== id);
      }
      if (state.workspace?.folders) {
        state.workspace.folders = state.workspace?.folders.filter((s) => s.id !== id);
      }
      if (state.currentOpenFolder?.files) {
        state.currentOpenFolder.files = state.currentOpenFolder.files.filter((s) => s.id !== id);
      }
      if (state.currentOpenFolder?.folders) {
        state.currentOpenFolder.folders = state.currentOpenFolder.folders.filter((s) => s.id !== id);
      }
    })
    .addCase(updateWorkspaceItem, (state, { payload }) => {
      state.subfiles = state.subfiles.map((s) => s.id === payload.id ? payload as WorkspaceFile : s);
      state.subfolders = state.subfolders.map((s) => s.id === payload.id ? payload : s);
      if (state.workspace?.files) {
        state.workspace.files = state.workspace?.files.map((s) => s.id === payload.id ? payload as WorkspaceFile : s);
      }
      if (state.workspace?.folders) {
        state.workspace.folders = state.workspace?.folders.map((s) => s.id === payload.id ? payload : s);
      }
      if (state.currentOpenFolder?.files) {
        state.currentOpenFolder.files = state.currentOpenFolder.files.map((s) => s.id === payload.id ? payload as WorkspaceFile : s);
      }
      if (state.currentOpenFolder?.folders) {
        state.currentOpenFolder.folders = state.currentOpenFolder.folders.map((s) => s.id === payload.id ? payload : s);
      }
    });
});
