import { createSlice } from "@reduxjs/toolkit";
import {
  connectionMappingState,
  connectionMappingReducers,
} from "@store/beadlConfiguration/connectionMapping";
import {
  eventDefinitionState,
  eventDefinitionReducers,
} from "@store/beadlConfiguration/eventDefinition";
import {
  actionDefinitionState,
  actionDefinitionReducers,
} from "./actionDefinition";
import {
  argumentDefinitionState,
  argumentDefinitionReducers,
} from "./argumentDefinition";
import {
  protocolSettingsState,
  protocolSettingsReducers,
} from "./protocolSettings";

const beadlConfigurationSlice = createSlice({
  name: "slice",
  initialState: {
    connectionMapping: connectionMappingState,
    eventDefinition: eventDefinitionState,
    actionDefinition: actionDefinitionState,
    argumentDefinition: argumentDefinitionState,
    protocolSettings: protocolSettingsState,
  },
  reducers: {
    ...connectionMappingReducers,
    ...eventDefinitionReducers,
    ...actionDefinitionReducers,
    ...argumentDefinitionReducers,
    ...protocolSettingsReducers,
  },
});

export const {
  setConnectionMappings,
  updateConnectionMapping,
  addNewConnectionMapping,
  deleteConnectionMapping,
  setEventDefinitions,
  updateEventDefinition,
  addNewEventDefinition,
  deleteEventDefinition,
  setActionDefinitions,
  updateActionDefinition,
  addNewActionDefinition,
  deleteActionDefinition,
  setArgumentDefinitions,
  updateArgumentDefinitionById,
  addNewArgumentDefinition,
  deleteArgumentDefinition,
  setProtocolSettingsValue,
  setProtocolSettings,
} = beadlConfigurationSlice.actions;
export default beadlConfigurationSlice.reducer;
