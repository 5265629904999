import { BeadlArgument } from "@customTypes/model/beadlConfiguration/argumentDefinition";
import { BeadlConfigurationState } from "@customTypes/store/beadlConfiguration";
import { PayloadAction } from "@reduxjs/toolkit";
import { ArgumentDefinitionState } from "@customTypes/store/beadlConfiguration";
import { stringToNumber } from "@util/helpers/stringToNumber.helper";
import { FlowElement } from "react-flow-renderer";
import { BeadlState } from "@customTypes/model/beadlEditor/beadlState";

export const argumentDefinitionState: ArgumentDefinitionState = {
  data: [],
  dataIndexMappedById: {},
};

export const argumentDefinitionReducers = {
  setArgumentDefinitions: (
    state: BeadlConfigurationState,
    action: PayloadAction<BeadlArgument[]>
  ) => {
    const { payload } = action;
    return {
      ...state,
      argumentDefinition: {
        data: payload.map((d) => ({ ...d, type: stringToNumber(d.type) })),
      },
    };
  },

  updateArgumentDefinitionById: (
    state: BeadlConfigurationState,
    { payload }: PayloadAction<BeadlArgument>
  ) => {
    const data = state.argumentDefinition.data.map((d) => (d.id !== payload.id ? d : payload));

    return {
      ...state,
      argumentDefinition: {
        ...state.argumentDefinition,
        data,
      },
    };
  },

  addNewArgumentDefinition: (
    state: BeadlConfigurationState,
    { payload }: PayloadAction<BeadlArgument>
  ) => {
    const data = [...state.argumentDefinition.data, payload];

    return {
      ...state,
      argumentDefinition: {
        ...state.argumentDefinition,
        data,
      },
    };
  },

  deleteArgumentDefinition: (
    state: BeadlConfigurationState,
    { payload }: PayloadAction<{ flowElements: FlowElement<BeadlState>[]; item: BeadlArgument }>
  ) => {
    const { flowElements, item } = payload;

    const argumentInUse = flowElements.some((fe) => fe?.data?.duration?.value === item.name);
    if (argumentInUse) throw new Error('Argument is in use and cannot be deleted. Please unlink to delete it!');

    const data = state.argumentDefinition.data.filter((d) => d.id !== item.id);

    return {
      ...state,
      argumentDefinition: {
        ...state.argumentDefinition,
        data,
      },
    }
  },
};
