/* eslint-disable react-hooks/exhaustive-deps */
import { v4 as uuid } from 'uuid';
import Input from "@components/core/Input";
import Table from "@components/core/Table";
import InteractionComponentSelector from "@components/selectors/InteractionComponentSelector";
import InteractionConnectionSelector from "@components/selectors/InteractionConnectionSelector";
import { Column } from "@customTypes/components/core/Table";
import { BeadlAction } from "@customTypes/model/beadlConfiguration/actionDefinition";
import { ConnectionMappingState } from "@customTypes/store/beadlConfiguration";
import { VirtualEntity } from "@store/beadl";
import {
  addNewActionDefinition,
  deleteActionDefinition,
  updateActionDefinition,
} from "@store/beadlConfiguration";
import { RootState } from "@store/index";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomTooltip } from '@components/CustomTooltip';
import { workspaceTooltip } from "../../../tooltip.text";
import { message } from 'antd';
import { FlowElement } from 'react-flow-renderer';
import { BeadlState } from "@customTypes/model/beadlEditor/beadlState";

const ActionDefinitionTable = () => {
  const dispatch = useDispatch();
  const actionDefinitions = useSelector<RootState, BeadlAction[]>(
    (state) => state.beadlConfiguration.actionDefinition.data
  );
  const connectionMappingState = useSelector<RootState, ConnectionMappingState>(
    (state) => state.beadlConfiguration.connectionMapping
  );
  const flowElements = useSelector<RootState>(
    (state) => state.beadlEditor.tabNameDataMap[state.beadlEditor.activeTab].flowElements
  ) as FlowElement<BeadlState>[];


  const { data: connectionMappings } = connectionMappingState;

  const handleInteractionConnectionChange = useCallback(
    (connection, payload) => {
      const item = { ...payload }

      item.connection = {
        component_id: undefined,
        mapping_id: undefined,
        virtual_entity_id: undefined,
        virtual_entity_component_id: undefined,
      };

      if ((connection as VirtualEntity).xmlNodeName) {
        item.connection.virtual_entity_id = +connection.id;
      } else {
        item.connection.mapping_id = connection.id;
      }

      dispatch(updateActionDefinition(item));
    },
    [dispatch, actionDefinitions]
  );

  const handleInteractionComponentChange = useCallback(
    (id, item) => {
      const isVirtual = item.connection?.virtual_entity_id !== undefined;

      const payload: BeadlAction = {
        ...item,
        connection: {
          ...item.connection,
          component_id: isVirtual ? undefined : id,
          virtual_entity_component_id: isVirtual ? id : undefined,
        },
      };

      dispatch(updateActionDefinition(payload));
    },
    [actionDefinitions, dispatch]
  );

  const handleItemCreate = useCallback(() => {
    const item: BeadlAction = {
      id: uuid(),
      name: "New Item",
      type: "",
      comment: "",
      connection: {
        mapping_id: undefined,
        component_id: undefined,
        virtual_entity_id: undefined,
        virtual_entity_component_id: undefined,
      },
    };

    dispatch(addNewActionDefinition(item));
  }, [connectionMappings, dispatch]);

  const handleItemDelete = useCallback(
    (_, { id }) => {
      try {
        dispatch(deleteActionDefinition({ flowElements, id }));
      } catch (err) {
        message.error((err as Error)?.message)
      }
    },
    [dispatch]
  );

  const handleItemDuplicate = useCallback(
    (_: number, item: BeadlAction) => {
      dispatch(addNewActionDefinition({ ...item, id: uuid() }));
    },
    [dispatch]
  );

  const columns: Column<BeadlAction>[] = useMemo(() => {
    return [
      {
        title: () => ( <CustomTooltip title="Action Name" tooltipText={workspaceTooltip.actions.actionName} />),
        dataIndex: "name",
        render: (_: string, item: BeadlAction) => {
          return (
            <Input
              value={item.name}
              className="borderless_input"
              onChange={(e) =>
                dispatch(updateActionDefinition({ ...item, name: e.target.value }))
              }
            />
          );
        },
      },
      {
        title: () => ( <CustomTooltip title="Connection" tooltipText={workspaceTooltip.actions.connection} />),
        dataIndex: "connection",
        render: (_: string, item: BeadlAction) => (
          <div className="grid grid-cols-2 space-x-2">
            <InteractionConnectionSelector
              onChange={(_, connection) =>
                handleInteractionConnectionChange(connection, item)
              }
              selected={
                item.connection?.mapping_id === undefined
                  ? item.connection?.virtual_entity_id?.toString() as unknown as number
                  : item.connection?.mapping_id as unknown as number // TODO: temp hack to by pass TS
              }
              virtualEntityKey={"virtualAction"}
            />

            {item.connection && (
              <InteractionComponentSelector
                virtualEntityComponentKey="VIRTUAL_ACTION_COMPONENT"
                connection={item.connection}
                onChange={(componentId: number) =>
                  handleInteractionComponentChange(componentId, item)
                }
                isVirtual={item.connection.virtual_entity_id !== undefined}
                selected={
                  item.connection.virtual_entity_id === undefined
                    ? item.connection.component_id
                    : item.connection.virtual_entity_component_id
                }
              />
            )}
          </div>
        ),
      },
      {
        title: () => ( <CustomTooltip title="Type" tooltipText={workspaceTooltip.actions.type} />),
        dataIndex: "type",
        render: (_: string, item: BeadlAction) => {
          return (
            <Input
              value={item.type}
              className="borderless_input"
              onChange={(e) =>
                dispatch(updateActionDefinition({ ...item, type: e.target.value }))
              }
            />
          );
        },
      },
      {
        title: () => ( <CustomTooltip title="Comment" tooltipText={workspaceTooltip.actions.comment} />),
        dataIndex: "comment",
        render: (_: string, item: BeadlAction) => {
          return (
            <Input
              value={item.comment}
              className="borderless_input"
              onChange={(e) =>
                dispatch(updateActionDefinition({ ...item, comment: e.target.value }))
              }
            />
          );
        },
      },
    ];
  }, [
    handleInteractionComponentChange,
    handleInteractionConnectionChange,
  ]);

  return (
    <Table
      columns={columns}
      data={actionDefinitions}
      actionConfig={{
        add: {
          enabled: true,
          action: handleItemCreate,
        },
        delete: {
          enabled: true,
          action: handleItemDelete,
        },
        duplicate: {
          enabled: true,
          action: handleItemDuplicate,
        },
      }}
    />
  );
};

export default ActionDefinitionTable;
