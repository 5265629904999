import { UpdateUserType, User } from '../../../types';
import { Button, Modal, Typography } from 'antd'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { CustomInput } from '@components/FormFields';
import * as Yup from 'yup';
import { api } from '../../../api';
import { sendError, sendMessage } from '@util/helpers/notifications.helper';
import { selectLoggedInUser, setUser } from '@store/auth';

const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required!'),
  lastName: Yup.string().required('Last name is required!'),
  email: Yup.string().email('Invalid email!').required('Email is required!'),
  organization: Yup.string().required('Organization is required!'),
});

interface Props {
  visible: boolean;
  onCancel: () => void;
}

type FormValues = Omit<UpdateUserType, 'isEmailVerified'>

export const UpdateUserForm = ({ visible, onCancel }: Props) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const user = useSelector(selectLoggedInUser);

  const onSubmit = async (input: FormValues) => {
    if (!user?.id) return;
    setLoading(true);

    try {
      const { data } = await api.put<User>(`/users/${user.id}`, input);
      if (!data) throw new Error('Something went wrong!');

      dispatch(setUser(data))
      formik.resetForm();

      sendMessage('User updated successfully!');
      onCancel();
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  };

  const initialValues: FormValues = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    organization: user?.organization?.title || '',
    email: user?.email || '',
  };

  const formik = useFormik<FormValues>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit
  });

  const cancelAndReset = () => {
    formik.resetForm();
    onCancel();
  };

  return (
    <Modal
      className='users-actions_modal'
      title={(
        <Typography.Text className='flex justify-center text-base font-bold'>
          Update profile
        </Typography.Text>
      )}
      centered
      visible={visible}
      onCancel={onCancel}
      footer={false}
      width={800}
    >
      <form onSubmit={formik.handleSubmit}>
      <div className='flex flex-wrap justify-between gap-4 px-2 pr-10'>
        <div className='flex flex-col gap-1.5 w-2/5'>
          <label className='text-xs font-light'>
            First name
          </label>
          <CustomInput formik={formik} name="firstName" placeholder="First name" />
        </div>

        <div className='flex flex-col gap-1.5 w-2/5'>
          <label className='text-xs font-light'>
            Last name
          </label>
          <CustomInput formik={formik} name="lastName" placeholder="Last name" />
        </div>

        <div className='flex flex-col gap-1.5 w-2/5'>
          <label className='text-xs font-light'>
            Email
          </label>
          <CustomInput formik={formik} name="email" placeholder="Email" />
        </div>

        <div className='flex flex-col gap-1.5 w-2/5'>
          <label className='text-xs font-light'>
            Organization
          </label>
          <CustomInput formik={formik} name="organization" placeholder="Organization" />
        </div>

        <div className='w-2/4' />

      </div>

      <div className='flex justify-between w-full px-2 pt-10'>
        <Button type="ghost" onClick={cancelAndReset} className="w-2/5 btn-br-black">
          Cancel
        </Button>
        <Button loading={loading} type="ghost" htmlType="submit" className="w-2/5 btn-br-black">
          Save changes
        </Button>
      </div>
    </form>
    </Modal>
  )
}