import { useCallback, useMemo } from "react";
import { SelectProps } from "@customTypes/components/core/Select";
import AntSelect from "antd/lib/select";

import styled from "styled-components";

const { Option } = AntSelect;

const Select = <T extends unknown, K extends unknown>(
  props: SelectProps<T, K>
) => {
  const {
    className,
    selected,
    options,
    onChange,
    placeholder = "Select please",
    labelRender = (item) => `${item}`,
    itemIdentifier,
    disabled = false,
    allowClear,
    onClear,
    size,
  } = props;

  const indexItemMap: Record<number, T> = useMemo(
    () =>
      options.reduce((acc, option, index) => ({ ...acc, [index]: option }), {}),
    [options]
  );

  const selectedIndex = useMemo(() => {
    const index = options.findIndex((option) =>
      selected !== undefined && itemIdentifier
        ? itemIdentifier(option) === selected
        : option === selected
    );
    return index < 0 ? undefined : index;
  }, [options, itemIdentifier, selected]);

  const handleChange = useCallback(
    (index: number) => {
      const value = indexItemMap[index];
      onChange &&
        onChange(
          itemIdentifier && value !== undefined
            ? itemIdentifier(value)
            : (value as K),
          value
        );
    },
    [indexItemMap, itemIdentifier, onChange]
  );

  return (
    <AntSelect
      className={className}
      disabled={disabled}
      placeholder={placeholder}
      value={selectedIndex}
      onChange={(value) => handleChange(value)}
      size={size}
      allowClear={allowClear}
      onClear={onClear}
    >
      {options.map((option, index) => (
        <Option key={`option-${index}`} value={index}>
          {labelRender(option)}
        </Option>
      ))}
    </AntSelect>
  );
};

const StyledSelect = styled(Select)`
  &.ant-select {
    font-size: 13px;
  }
` as typeof Select;

export default StyledSelect;
