export interface WorkspaceFile {
  id: string;
  workspace: Workspace;
  folder?: WorkspaceFolder;
  title: string;
  s3Key: string;
  size: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface WorkspaceAddFileForm {
  title: string;
  template_id?: string;
}
export interface WorkspaceFolder {
  id: string;
  parentFolder?: WorkspaceFolder;
  folders?: WorkspaceFolder[];
  files?: WorkspaceFile[];
  title: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface Workspace {
  id: string;
  folders: WorkspaceFolder[];
  files: WorkspaceFile[];
  title: string;
  createdAt: Date;
  updatedAt: Date;
}

export type WorkspaceItemType = WorkspaceFile & WorkspaceFolder;

export enum WorkspaceSupportedKeyboardKeys {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
};

export interface ContextMenuState {
  top?: number;
  left?: number;
  showMenu: boolean;
  item?: WorkspaceFile | WorkspaceFolder;
}
