import { adminAllUsersSelector } from '@store/user';
import { Modal, Typography } from 'antd'
import { useSelector } from 'react-redux';
import { UpdateForm } from './UpdateForm.component';
import { AddForm } from './AddForm.component';

interface Props {
  visible: boolean;
  onCancel: () => void;
}

export const AddUpdateForm = ({ visible, onCancel }: Props) => {
  const { selectedUser } = useSelector(adminAllUsersSelector);

  return (
    <Modal
      className='users-actions_modal'
      title={(
        <Typography.Text className='flex justify-center text-base font-bold'>
          {`${selectedUser ? `Edit - ${selectedUser.username}` : 'Add new user'}`}
        </Typography.Text>
      )}
      centered
      visible={visible}
      onCancel={onCancel}
      footer={false}
      width={800}
    >
      {selectedUser?.id
        ? <UpdateForm onCancel={onCancel} />
        : <AddForm onCancel={onCancel} />}
    </Modal>
  )
}