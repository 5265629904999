import {
  ReactElement, useRef, useState,
} from 'react';
import { get } from 'lodash';
import { Button, Divider, Input, InputRef, Select, Space } from 'antd';
import { CommonInputProps } from './types';
import { UserOrganization } from 'types';
import { PlusOutlined } from '@ant-design/icons';
import { useComponentDidMount } from '../../hooks';
import { addOrganization, fetchOrganizations, selectAllOrganizations } from '@store/organization';
import { PAGINATION_LIMIT } from '../../config';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../api';
import { sendError, sendMessage } from '@util/helpers/notifications.helper';

export interface CustomOrganizationsProps<T> extends CommonInputProps<T> {
  customError?: string;
}

export function CustomOrganizations<T>({
  formik, name,
  customError = '',
}: CustomOrganizationsProps<T>): ReactElement {
  const [loading, setLoading] = useState(false);

  const inputRef = useRef<InputRef>(null);

  const error = get(formik.errors, name, customError) as string;
  const invalid = get(formik.touched, name, false) as boolean && !!error;

  const dispatch = useDispatch();

  useComponentDidMount(() =>
    dispatch(
      fetchOrganizations({ payload: { limit: PAGINATION_LIMIT, offset: 0 } })
    )
  );
  const { organizations } = useSelector(selectAllOrganizations);

  const [title, setTitle] = useState('');

  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const addItem = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setLoading(true);

    try {

      const { data } = await api.post<UserOrganization>('/organizations', { title, description: '' });
      if (!data) throw new Error('');

      dispatch(addOrganization(data));

      sendMessage('Organization added successfully!');
      setTitle('');
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  };

  const onHandleSelect = (value: string) => {
    formik.setFieldValue(name, value)
  }

  const selectedOrg = get(formik.values, name) ? get(formik.values, name) : undefined;

  return (
    <>
    <Select
      style={{ width: 300 }}
      placeholder="Please select organization"
      onChange={onHandleSelect}
      loading={loading}
      value={selectedOrg}
      dropdownRender={menu => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Input
              placeholder="Please enter organization"
              ref={inputRef}
              value={title}
              onChange={onNameChange}
              onPressEnter={(e) => e.preventDefault()}
            />
            <Button loading={loading} type="text" icon={<PlusOutlined />} onClick={addItem}>
              Add
            </Button>
          </Space>
        </>
      )}
    >
      {(organizations || []).map(org => (
        <Select.Option key={org.id}>{org.title}</Select.Option>
      ))}
    </Select>

      {invalid && <div className="error-message">{error}</div>}
    </>
  );
}
