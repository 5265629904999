import { UpdateUserType, User } from '../../../../../types';
import { adminAllUsersSelector, updateUser } from '@store/user';
import { Button } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
  CustomCheckbox,
  CustomInput,
  CustomOrganizations,
} from '@components/FormFields';
import * as Yup from 'yup';
import { api } from '../../../../../api';
import { sendError, sendMessage } from '@util/helpers/notifications.helper';

const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required!'),
  lastName: Yup.string().required('Last name is required!'),
  email: Yup.string()
    .trim()
    .lowercase()
    .email('Invalid email!')
    .required('Email is required!'),
  organization: Yup.string().required('Organization is required!'),
});

interface Props {
  onCancel: () => void;
}

export const UpdateForm = ({ onCancel }: Props) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { selectedUser } = useSelector(adminAllUsersSelector);

  const onSubmit = async (input: UpdateUserType) => {
    if (!selectedUser?.id) return;
    setLoading(true);

    try {
      const { data } = await api.put<User>(`/users/${selectedUser.id}`, input);
      if (!data) throw new Error('Something went wrong!');

      dispatch(updateUser(data));
      formik.resetForm();

      sendMessage('User updated successfully!');
      onCancel();
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  };

  const initialValues: UpdateUserType = {
    firstName: selectedUser?.firstName || '',
    lastName: selectedUser?.lastName || '',
    organization: selectedUser?.organization?.title || '',
    email: selectedUser?.email || '',
    isEmailVerified: selectedUser?.isEmailVerified || false,
  };

  const formik = useFormik<UpdateUserType>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit,
  });

  const cancelAndReset = () => {
    formik.resetForm();
    onCancel();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex flex-wrap justify-between gap-4 px-2 pr-10">
        <div className="flex flex-col gap-1.5 w-2/5">
          <label className="text-xs font-light">First name</label>
          <CustomInput
            formik={formik}
            name="firstName"
            placeholder="First name"
          />
        </div>

        <div className="flex flex-col gap-1.5 w-2/5">
          <label className="text-xs font-light">Last name</label>
          <CustomInput
            formik={formik}
            name="lastName"
            placeholder="Last name"
          />
        </div>

        <div className="flex flex-col gap-1.5 w-2/5">
          <label className="text-xs font-light">Email</label>
          <CustomInput formik={formik} name="email" placeholder="Email" />
        </div>

        <div className="flex flex-col gap-1.5 w-2/5">
          <label className="text-xs font-light">Organization</label>
          <CustomOrganizations
            formik={formik}
            name="organization"
            placeholder="Organization"
          />
        </div>

        <div className="w-2/4" />

        <div className="w-2/4">
          <CustomCheckbox
            name="isEmailVerified"
            formik={formik}
            placeholder="Email verified"
          />
        </div>
      </div>

      <div className="flex justify-between w-full px-2 pt-10">
        <Button
          type="ghost"
          onClick={cancelAndReset}
          className="w-2/5 btn-br-black"
        >
          Cancel
        </Button>
        <Button
          loading={loading}
          type="ghost"
          htmlType="submit"
          className="w-2/5 btn-br-black"
        >
          Save changes
        </Button>
      </div>
    </form>
  );
};
