import { fetchMyWorkspace, myWorkspaceSelector, setOpenFolder } from '@store/workspace';
import { Tree } from 'antd';
import { DataNode } from 'antd/lib/tree';
import { api } from '../../../api';
import { useDispatch, useSelector } from 'react-redux';
import { WorkspaceFolder } from 'types';
import { sendError } from '@util/helpers/notifications.helper';
import './FolderTree.styles.css';

const { DirectoryTree } = Tree;

export const FolderTree = () => {
  const { workspace, currentOpenFolder } = useSelector(myWorkspaceSelector);

  const dispatch = useDispatch();

  if (!workspace?.id) return <div />;

  const workspaceTreeData: DataNode = {
    key: workspace.id,
    title: 'My Workspace',
    children: workspace?.folders.map((folder) => ({
      key: folder.id,
      title: folder.title,
    })),
  };

  const onSelect = async (selected: string | number) => {
    if (selected === currentOpenFolder?.id) return;

    if (selected === workspace.id) {
      dispatch(setOpenFolder(null));
      dispatch(fetchMyWorkspace())
      return;
    };

    try {
      const { data } = await api.get<WorkspaceFolder>(`/workspaces/folders/${selected}`);
      if (!data) throw new Error('Unable to open folder!');

      dispatch(setOpenFolder(data));
    } catch (err) {
      sendError(err);
    }
  };

  const selectedKeys = currentOpenFolder?.id ? [workspace.id, currentOpenFolder.id] : [workspace.id];

  return (
    <DirectoryTree
      rootClassName='workspace-tree'
      onSelect={([selected]) => onSelect(selected)}
      treeData={[workspaceTreeData]}
      switcherIcon
      className="cs_tree"
      selectedKeys={[currentOpenFolder?.id ? currentOpenFolder.id : workspace.id]}
      expandedKeys={selectedKeys}
    />
  );
};
