import { uniqBy } from 'lodash';
import { Template } from '../../types';
import { createReducer } from '@reduxjs/toolkit';
import { addTemplate, deleteTemplate, deselectTemplate, fetchAllTemplates, selectTemplate, updateTemplate } from './template.actions';

type AllTemplates = {
  pending: boolean;
  templates: Template[];
  selectedTemplate: Template | null;
}

const initialState : AllTemplates = {
  templates: [],
  selectedTemplate: null,
  pending: false,
};

export const templateReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAllTemplates.pending, (state) => {
      state.pending = true;
    })
    .addCase(fetchAllTemplates.fulfilled, (state, { payload }) => {
      state.templates = payload;
      state.pending = false;
    })
    .addCase(fetchAllTemplates.rejected, (state) => {
      state.pending = false;
    })
    .addCase(addTemplate, (state, { payload }) => {
      state.templates = uniqBy([payload, ...state.templates], 'id');
    })
    .addCase(updateTemplate, (state, { payload }) => {
      state.templates = state.templates.map((t) => (
        t.id === payload.id ? { ...payload } : t
      ));
    })
    .addCase(selectTemplate, (state, { payload }) => {
      state.selectedTemplate = payload;
    })
    .addCase(deselectTemplate, (state, { payload }) => {
      state.selectedTemplate = null;
    })
    .addCase(deleteTemplate, (state, { payload }) => {
      state.templates = state.templates.filter((u) => u.id !== payload);
    });
});
