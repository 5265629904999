import { AuthTypeState } from '../../types';
import { createReducer } from '@reduxjs/toolkit';
import { setUser, setFirstTimeUser, logoutUser } from './auth.actions';

const initialState: AuthTypeState = {
  user: null,
  firstTimeUser: null,
};

export const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setUser, (state, { payload }) => {
      state.user = payload;
      state.firstTimeUser = null;
    })
    .addCase(setFirstTimeUser, (state, { payload }) => {
      state.firstTimeUser = payload;
      state.user = null;
    })
    .addCase(logoutUser, (state) => {
      state.firstTimeUser = null;
      state.user = null;
    })
});
