import { withAuth } from '../../../hocs'
import { DashboardLayout } from '../../../layouts/Dashboard.layout'
import { SuperAdminActions } from '../components/SuperAdminActions.component';
import { UserRequestsTable } from './components/UserRequestsTable.component';
import "./AdminUserRequestActions.css";

const AdminUserRequests = () => {
  return (
    <DashboardLayout
      navbarTitle="User Requests"
      leftSidebar={<SuperAdminActions />}
      mainComponent={<UserRequestsTable />}
      hideRightSidebar
    />
  )
}

export default withAuth(AdminUserRequests, ['Admin']);
