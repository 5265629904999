import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

export const selectUserState = (state: RootState) => state.user;
export const selectAllUsersData = (state: RootState) => state.user;
export const selectTotalCount = (state: RootState) => state.user.totalCount;
export const selectUserProfile = (state: RootState) => state.user.userProfile;

export const userStateSelector = createSelector(selectUserState, (state) => state);

export const adminAllUsersSelector = createSelector(
  selectAllUsersData, (state) => state,
);
