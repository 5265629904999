import axios from 'axios';
import { api } from "../../api";
interface UploadUrlType {
  fields: {
    Policy: string;
    'X-Amz-Algorithm': string;
    'X-Amz-Credential': string;
    'X-Amz-Date': string;
    'X-Amz-Signature': string;
    bucket: string;
    key: string;
  };
  url: string;
}

export const onUpload = async (file: File, onProgress?: (e: { percent: number }) => void) => {
  const { name: fileName, size, type: mimetype } = file;

  const { data } = await api.post<UploadUrlType>('/uploads', { size, mimetype });
  const fileKey = data?.fields?.key;
  if (!data?.url || !fileKey) throw new Error('Unable to upload file!');

  const onUploadProgress = (e: { loaded: number; total: number }): void => {
    const percent = Math.floor((e.loaded / e.total) * 100);
    const event = { percent };

    if (onProgress) onProgress(event);
  };

  const formData = new FormData();
  formData.set('Policy', data.fields.Policy);
  formData.set('key', data.fields.key);
  formData.set('bucket', data.fields.bucket);
  formData.set('X-Amz-Algorithm', data.fields['X-Amz-Algorithm']);
  formData.set('X-Amz-Credential', data.fields['X-Amz-Credential']);
  formData.set('X-Amz-Date', data.fields['X-Amz-Date']);
  formData.set('X-Amz-Signature', data.fields['X-Amz-Signature']);
  formData.set('Content-Type', mimetype);
  formData.append('file', file);

  const res = await axios.post(data.url, formData, { onUploadProgress });

  return { res, fileName, fileKey };
}

export const onPublicUpload = async (file: File, onProgress?: (e: { percent: number }) => void) => {
  const { name: fileName, size, type: mimetype } = file;

  const { data } = await api.post<{ url: string; name: string }>('/uploads/public', { size, mimetype });
  const fileKey = data?.name
  if (!data?.url || !fileKey) throw new Error('Unable to upload file!');

  const onUploadProgress = (e: { loaded: number; total: number }): void => {
    const percent = Math.floor((e.loaded / e.total) * 100);
    const event = { percent };

    if (onProgress) onProgress(event);
  };

  const res = await axios.put(data.url, file, { onUploadProgress, headers: { 'Content-Type': mimetype } });

  return { res, fileName, fileKey };
}
