import { useCallback, MouseEvent } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import DurationText from "@components/BeadlEditor/BeadlNode/DurationText";
import StateName from "@components/BeadlEditor/BeadlNode/StateName";
import InputEvent from "@components/BeadlEditor/BeadlNode/InputEvent";
import { FlowElement } from "react-flow-renderer";

import {
  changeDrawerOpen,
  setBaseStateName,
  setFormData,
  updateFlowNodeData,
  updateNodeStateName,
} from "@store/beadlEditor";
import { RootState } from "@store/index";
import OutputAction from "./OutputAction";
import { BeadlState } from "@customTypes/model/beadlEditor/beadlState";
import { defaultText } from "@styles/global";
import { BeadlStateEvent } from "@customTypes/model/beadlEditor/beadlState/beadlStateEvent";
import { BeadlStateAction } from "@customTypes/model/beadlEditor/beadlState/beadlStateAction";

const StyledContainer = styled.div`
  display: grid;
  border: 1px solid black;
  min-width: 400px;
  max-width: 400px;
  grid-template-areas:
    "state-name output-action"
    "duration-text output-action"
    "input-event input-event";
  grid-template-columns: 1fr 1fr;
`;

const StyledActionContainer = styled.div`
  grid-area: output-action;
  background-color: #52b5dc;
  ${defaultText};
  font-size: 16px;
  word-break: break-all;
`;

const StyledInputContainer = styled.div`
  grid-area: input-event;
  background-color: #fcdd9c;
  word-break: break-all;
`;

/**
 * @category @components/BeadlEditor
 * @returns {JSX.Element}
 */
export const BeadlNode = (props: FlowElement<BeadlState>) => {
  const { data } = props;
  const dispatch = useDispatch();
  const { name, actions, events, duration } = data!;

  const isDrawerEdit = useSelector(
    (state: RootState) => state.beadlEditor.updateType === "drawer"
  );

  const handleStateChange = useCallback(
    (value: string) => {
      if (value === name) {
        return;
      }
      dispatch(
        updateNodeStateName({
          oldValue: name,
          newValue: value,
        })
      );
    },
    [dispatch, name]
  );

  const handleDurationChange = useCallback(
    (value: string) => {
      if (value === props.data!.duration.value) {
        return;
      }
      dispatch(
        updateFlowNodeData({
          name: props.data!.name,
          duration: {
            value,
          },
        })
      );
    },
    [dispatch, props]
  );

  const handleActionChange = useCallback(
    (action: BeadlStateAction, index: number) => {
      const data = props.data!;
      const newActions = [...(data.actions || [])];
      newActions.splice(index, 1, action);
      dispatch(updateFlowNodeData({ name: data.name, actions: newActions }));
    },
    [dispatch, props]
  );

  const handleEventChange = useCallback(
    (eventArg: BeadlStateEvent, index: number) => {
      const data = props.data!;

      const newEvents = [...(data.events || [])];
      newEvents?.splice(index, 1, eventArg);
      dispatch(updateFlowNodeData({ name: data.name, events: newEvents }));
    },
    [dispatch, props]
  );

  const handleNodeSelect = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (!isDrawerEdit) {
        return;
      }
      event.preventDefault();

      dispatch(setBaseStateName(data?.name));
      dispatch(setFormData(data));
      dispatch(changeDrawerOpen());
    },
    [data, dispatch, isDrawerEdit]
  );

  return (
    <StyledContainer onDoubleClick={handleNodeSelect}>
      <StateName
        disabled={isDrawerEdit}
        value={name}
        onValueChange={handleStateChange}
      />
      <DurationText
        disabled={isDrawerEdit}
        {...duration}
        onValueChange={handleDurationChange}
      />
      <StyledActionContainer className="h-full flex flex-col justify-center">
        {actions?.map((action, index) => (
          <OutputAction
            disabled={isDrawerEdit}
            key={`output-actionId-${index}`}
            action={action}
            onChange={(id) => handleActionChange(id, index)}
          />
        ))}
      </StyledActionContainer>
      <StyledInputContainer className="h-full flex flex-col justify-center">
        {events?.map((event, index) => (
          <InputEvent
            disabled={isDrawerEdit}
            key={`output-event-${index}`}
            beadlEvent={event}
            onChange={(event) => handleEventChange(event, index)}
            handleId={`${index}`}
          />
        ))}
      </StyledInputContainer>
    </StyledContainer>
  );
};

export default BeadlNode;
