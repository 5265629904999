export const workspaceTooltip = {
    argumentDefinition: {
      argumentName: 'Define the name of the trial-dependent BAABL argument to be referred to within the protocol.',
      expression: 'Specify the value for the argument. this value will be used in the code generation as well.',
      comment: 'Define a comment for this argument to better describe the prupose of it.',
      outputType: 'Select a type for the argument.',
      expressionType: 'Describes how the value entered for the attribute expression should be interpreted.',
    },
    connectionMappings: {
      connectionName	: 'Define the name of the connection mapping (abstracting the used hardware).',
      resourceType	: 'Select the type of resource for this connection.',
      resourceName : 'Select the istance of the resource to be used.',
      dependency : 'Specify a dependency under which circumstances this connection mapping should be defined.',
    },
    actions:{
      actionName: 'Define the name of the action to be used within the protocol.',
      connection: 'Select which hardware ressource it should refer to.',
      type  : 'To be used in future releases.',
      comment: 'Define a comment for this action to better describe the prupose of it.'
    },
    events:{
      eventName: 'Define the name of the event to be used within the protocol.',
      connection: 'Select which hardware ressource it should refer to.',
      type  : 'To be used in future releases.',
      comment: 'Define a comment for this event to better describe the prupose of it.'
    },
    protocolSettings: {
      protocolName: 'Specify the name of the protocol.',
      hardware :'Select the target hardware sude for this protocol.',
      startRate :'Select the state used to be as start for the protocol.',
      noTrails : 'Specify the number of trials for this protocol (positive integer value or INF for infinity).', //attribute should be startState and not startRat ;-)
    },
    saveProtocol: 'Save the changes of this behavioral task to the BAABL Workspace',
    exportProtocol: 'Export the current version of the behavioral task and save it to your local computer',
    exportToXML: 'Generate the BAABL-XML description of this behavioral task and save it to your local computer',
};

interface ImageType {
  id: number;
  src: string;
  alt: string;
}

export const helpSliderImages: ImageType[] = [
  // {
  //   id: 1,
  //   src: 'https://upload.wikimedia.org/wikipedia/commons/4/45/Different-scales-in-physics.svg',
  //   alt: 'physics'
  // },
]

export const defaultEdgeColor = '#717177'

export const defaultBeadlEditorColors = [defaultEdgeColor, '#CF3636', '#D19C2A', '#41A641', '#4184A6', '#4141A6', '#8D41A6'];
