import { v4 as uuid } from "uuid";
import Input from "@components/core/Input";
import Table from "@components/core/Table";
import DependencyTypeSelector from "@components/selectors/DependencyTypeSelector";
import ResourceSelector from "@components/selectors/ResourceSelector";
import ResourceTypeSelector from "@components/selectors/ResourceTypeSelector";
import { Column } from "@customTypes/components/core/Table";
import { ConnectionMapping } from "@customTypes/model/beadlConfiguration/connectionMapping";
import { beadlSelector } from "@store/beadl";
import {
  addNewConnectionMapping,
  deleteConnectionMapping,
  updateConnectionMapping,
} from "@store/beadlConfiguration";
import { RootState } from "@store/index";
import { message } from "antd";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomTooltip } from '@components/CustomTooltip';
import { workspaceTooltip } from "../../../tooltip.text";

const ConnectionMappingTable = () => {
  const dispatch = useDispatch();
  const {
    resources: { data: resources },
    resourceTypes: { data: resourceTypes },
  } = useSelector(beadlSelector);


  const connectionMappingRows = useSelector<RootState, ConnectionMapping[]>(
    (state) => state.beadlConfiguration.connectionMapping.data
  );

  const handleItemCreate = useCallback(() => {
    const item: ConnectionMapping = {
      id: uuid(),
      name: "New Item",
      dependency: {},
      resource_type_id: resourceTypes[0].id,
    };
    dispatch(addNewConnectionMapping(item));
  }, [dispatch, resourceTypes]);

  const handleItemDelete = useCallback(
    (_: number, item) => {
      try {
        dispatch(deleteConnectionMapping(item));
      } catch (e) {
        message.error((e as Error).message);
      }
    },
    [dispatch]
  );

  const handleItemDuplicate = useCallback(
    (_: number, item: ConnectionMapping) => {
      dispatch(addNewConnectionMapping({ ...item, id: uuid() }));
    },
    [dispatch]
  );

  const columns: Column<ConnectionMapping>[] = useMemo(() => {
    return [
      {
        title: () => ( <CustomTooltip title="Connection Name" tooltipText={workspaceTooltip.connectionMappings.connectionName} />),
        dataIndex: "name",
        width: '20%',
        render: (_: string, item: ConnectionMapping) => {
          return (
            <Input
              value={item.name}
              className="borderless_input"
              onChange={(e) =>
                dispatch(updateConnectionMapping({ ...item, name: e.target.value }))
              }
            />
          );
        },
      },
      {
        title: () => ( <CustomTooltip title="Resource Type" tooltipText={workspaceTooltip.connectionMappings.resourceType} />),
        dataIndex: "resource_type_id",
        width: '20%',
        render: (_: string, item: ConnectionMapping) => (
          <ResourceTypeSelector
            className="w-full"
            selected={item.resource_type_id}
            placeholder="Choose a resource type"
            onChange={(id) => dispatch(updateConnectionMapping({ ...item, resource_type_id: id }))}
          />
        ),
      },
      {
        title: () => ( <CustomTooltip title="Resource Name" tooltipText={workspaceTooltip.connectionMappings.resourceName} />),
        dataIndex: "resource",
        width: '20%',
        render: (_: string, item: ConnectionMapping) => (
          <ResourceSelector
            className="w-full"
            selected={item.resource_id}
            onChange={(id) => dispatch(updateConnectionMapping({ ...item, resource_id: id }))}
            options={
              (item.resource_type_id !== undefined ? resources.filter((r) => r.resourceType === item.resource_type_id) : undefined)
            }
            placeholder="Choose a resource"
          />
        ),
      },
      {
        title: () => ( <CustomTooltip title="Dependency" tooltipText={workspaceTooltip.connectionMappings.dependency} />),
        dataIndex: "dependency",
        width: '35%',
        render: (_: string, item: ConnectionMapping) => {
          return (
            <div className="grid grid-cols-2 space-x-2">
              <DependencyTypeSelector
                selected={item.dependency.type_id}
                onChange={(id) => dispatch(updateConnectionMapping({
                  ...item, dependency: { ...item.dependency, type_id: id },
                }))}
                allowClear
              />
              {item.dependency.type_id !== undefined && (
                <Input
                  value={item.dependency.expression}
                  onChange={(e) => dispatch(updateConnectionMapping({
                    ...item, dependency: { ...item.dependency, expression: e.target.value },
                  }))}
                />
              )}
            </div>
          );
        },
      },
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources]);

  return (
    <Table
      columns={columns}
      data={connectionMappingRows}
      actionConfig={{
        add: {
          enabled: true,
          action: handleItemCreate,
        },
        delete: {
          enabled: true,
          action: handleItemDelete,
        },
        duplicate: {
          enabled: true,
          action: handleItemDuplicate,
        },
      }}
    />
  );
};

export default ConnectionMappingTable;
