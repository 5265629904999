import { Amplify } from "aws-amplify";
import localizedFormat from "dayjs/plugin/localizedFormat";
import dayjs from "dayjs";
import Routes from "@pages/index";
import { useComponentDidMount, useSignIn } from "./hooks";
import { awsCognitoConfig } from "./config";
import "antd/dist/antd.min.css"; // or 'antd/dist/antd.less'
import "./App.css";

Amplify.configure(awsCognitoConfig);
dayjs.extend(localizedFormat);

function App() {
  const { waitAuthCheck, onAutoLogin } = useSignIn();

  useComponentDidMount(onAutoLogin);

  if (waitAuthCheck) return <div>loading...</div>;

  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
