import { UserOrganization } from "./organization.type";

export interface CognitoUser {
  username: string;
  challengeName: 'NEW_PASSWORD_REQUIRED' | '';
  requiredAttributes: string[];
  signInUserSession: {
    idToken: {
      payload: {
        sub: string;
        'cognito:groups': string[]; // Admin | User
      };
    };
  };
}

export interface UserWorkspace {
  id: string;
}

export type UserRole = 'Admin' | 'User';

export interface User {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  isEmailVerified: boolean;
  isActive: boolean;
  group: UserRole;
  status: 'UNCONFIRMED'|'CONFIRMED'|'ARCHIVED'|'COMPROMISED'|'UNKNOWN'|'RESET_REQUIRED'|'FORCE_CHANGE_PASSWORD';
  createdAt: Date;
  updatedAt: Date;
  organization: UserOrganization;
  lastLogin?: LogEvent | null;
}

export interface SearchUserResponse {
  limit: number;
  offset: number;
  totalCount: number;
  users: User[]
}
export interface UserProfile {
  filesCount: number;
  foldersCount: number;
  user: User;
}

export type AuthTypeState = {
  user: User | null;
  firstTimeUser: CognitoUser | null;
}

export interface IChangePass {
  password: string;
  rpassword?: string;
  isPermanentPassword: boolean;
}

export interface IForgotPasswordType {
  email: string;
}

export interface IResetPasswordType {
  password: string;
  rpassword?: string;
}

export interface IChangeUserPass {
  oldPassword: string;
  newPassword?: string;

}

export interface AddUserType {
  username: string;
  firstName: string;
  lastName: string;
  organization: string;
  email: string;
  isEmailVerified: boolean;
  isPermanentPassword: boolean;
  password: string;
  rpassword?: string;
}

export interface UpdateUserType {
  firstName: string;
  lastName: string;
  organization: string;
  email: string;
  isEmailVerified: boolean;
}


export enum UserLogType {
  ErrorEvent = 'ErrorEvent',
  LogEvent = 'LogEvent',
  LoginEvent = 'LoginEvent',
}

export interface LogEvent {
  id: string;
  type: UserLogType;
  user: User;
  createdAt: Date;
  data?: string;
}
