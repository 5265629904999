import { withAuth } from '../../../hocs'
import { DashboardLayout } from '../../../layouts/Dashboard.layout'
import { SuperAdminActions } from '../components/SuperAdminActions.component';
import { SingleUserProfile } from './components';

const SingleUserProfilePage = () => {
  return (
    <DashboardLayout
      navbarTitle="User Profile"
      leftSidebar={<SuperAdminActions />}
      mainComponent={<SingleUserProfile />}
      hideRightSidebar
    />
  )
}

export default withAuth(SingleUserProfilePage, ["Admin"]);
