import { deleteItem } from "@store/workspace";
import { sendError, sendMessage } from "@util/helpers/notifications.helper";
import { Button, Menu, Popconfirm } from "antd";
import { api } from "../../../api";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ContextMenuState, WorkspaceFile, WorkspaceItemType } from "types";
import { DuplicateFileModal } from "./DuplicateFileModal.component";
import axios from "axios";
import { CloseOutlined, CloudDownloadOutlined, CopyOutlined, DeleteOutlined, DragOutlined, EditOutlined } from "@ant-design/icons";
import { MoveToModal } from "./MoveToModal.component";
import './ContextMenu.styles.css';
import { RenameModal } from "./RenameModal.component";

interface Props {
  data: ContextMenuState;
  setShowMenu: (data: ContextMenuState) => void;
}

export const ContextMenu = ({ data: { top, left, showMenu, item }, setShowMenu }: Props) => {
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [moveModal, setMoveModal] = useState(false);
  const [renameModal, setRenameModal] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [downloadInProgress, setDownloadInProgress] = useState(false);

  const isFile = !!((item as WorkspaceFile)?.s3Key);

  const handleHideContextMenu = (e: KeyboardEvent) => {
    if (showMenu && e.key === "Escape") {
      setShowMenu({ showMenu: false })
    }
  };

  // const handleClickOutside = (e: Event) => {
  //   const el = e.target as HTMLElement;

  //   if (!Object.values(menuKeys).includes(el.innerText)) {
  //     setShowMenu({ showMenu: false });
  //   }
  // };

  useEffect(() => {
    document.addEventListener("keydown", handleHideContextMenu, true);
    // document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("keydown", handleHideContextMenu, true);
      // document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const dispatch = useDispatch();

  const exportBeadlFile = async () => {
    if (!item?.id) return;
    setDownloadInProgress(true);

    try {
      const { data } = await api.get<string>(`/uploads/${item.id}`);
      if (!data) throw new Error();

      const { data: jsonData } = await axios.get(data);
      if (!jsonData) throw new Error();

      const blob = new Blob([JSON.stringify(jsonData)], { type: 'application/json' });

      const a = document.createElement('a');

      a.download = `${item.title}.json`;
      a.href = window.URL.createObjectURL(blob);

      a.dispatchEvent(new MouseEvent('click', { view: window, bubbles: true, cancelable: true  }));
      a.remove();
    } catch (err) {
      sendError(err);
    } finally {
      setShowMenu({ showMenu: false });
      setDownloadInProgress(false);
    }
  };

  const onDelete = async (id: string, url: 'files' | 'folders') => {
    setDeleteInProgress(true);

    try {
      const { data } = await api.delete<WorkspaceItemType>(`/workspaces/${url}/${id}`)
      if (!data) throw new Error('Unable to create folder!');

      sendMessage(`${url === 'files' ? 'File' : 'Folder'} deleted successfully!`)

      dispatch(deleteItem(id));
    } catch (err) {
      sendError(err);
    } finally {
      setShowMenu({ showMenu: false });
      setDeleteInProgress(false);
    }
  };

  if (!showMenu) return <div />;

  return (
    <>
      <Menu
        style={{ position: 'absolute', top, left }}
        className="drop-menu"
        mode="vertical"
        items={[
          {
            key: '0',
            label: (
              <div className="item_with_close_btn">
                <Button className="move_to_btn" type="text" onClick={() => setMoveModal(true)} icon={<DragOutlined style={{ position: 'relative', top: -2 }} />}>
                  Move to...
                </Button>
                <Button className="close_icon" type="text" icon={<CloseOutlined />} onClick={() => setShowMenu({ showMenu: false })} />
              </div>
            ),
          },
          {
            key: '1',
            label: (
              <Button type="text" onClick={() => setRenameModal(true)} icon={<EditOutlined style={{ position: 'relative', top: -2 }} />}>
                Rename
              </Button>
            ),
          },
          ...(isFile ? [
            {
              key: '2',
              label: (
                <Button type="text" onClick={() => setDuplicateModal(true)} icon={<CopyOutlined style={{ position: 'relative', top: -2 }} />}>
                  Duplicate
                </Button>
              )
            },
            {
              key: '3',
              label: (
                <Button type="text" loading={downloadInProgress} onClick={exportBeadlFile} icon={<CloudDownloadOutlined style={{ position: 'relative', top: -2 }} />}>
                  Download
                </Button>
              )
            },
          ] : []),
          { key: '4',
            label: (
              <Popconfirm
                title={isFile
                  ? `Are you sure you want to delete "${item?.title}" editor file?`
                  : `Are you sure you want to delete "${item?.title}" folder and all of it's subfolders and files?`}
                onConfirm={() => onDelete(item?.id || '', isFile ? 'files' : 'folders')}
                okText="Yes"
                cancelText="No"
              >
                <Button type="text" loading={deleteInProgress} danger icon={<DeleteOutlined style={{ position: 'relative', top: -2 }} />}>
                  Delete
                </Button>
              </Popconfirm>
            )
          },
        ]}
      />

      <DuplicateFileModal
        visible={duplicateModal}
        item={item as WorkspaceFile}
        onCancel={() => {
          setDuplicateModal(false)
          setShowMenu({showMenu: false})
        }}
      />

      <MoveToModal
        visible={moveModal}
        item={item as WorkspaceFile}
        onCancel={() => {
          setMoveModal(false)
          setShowMenu({showMenu: false})
        }}
      />

      <RenameModal
        visible={renameModal}
        item={item as WorkspaceFile}
        onCancel={() => {
          setRenameModal(false)
          setShowMenu({showMenu: false})
        }}
      />
    </>
  )
};