import { useDispatch, useSelector } from 'react-redux';
import { Button, Pagination, Row, Table, Tooltip } from 'antd'
import { useComponentDidMount } from '../../../../hooks';
import { UserOrganization } from '../../../../types';
import { EditFilled } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { PAGINATION_LIMIT } from '../../../../config';
import { AddUpdateForm } from './OrganizationsActions/AddUpdateForm.component';
import { deselectOrganization, fetchOrganizations, selectAllOrganizations, selectOrganization } from '@store/organization';

interface Props {
  editOrg: (val: UserOrganization) => void;
}

const limit = PAGINATION_LIMIT;

const columns = ({
  editOrg,
}: Props): ColumnsType<UserOrganization> => [
    {
      title: 'Title',
      dataIndex: 'title',
      width: '15%'
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Actions',
      render: (org: UserOrganization) => (
        <div className='flex gap-2.5'>
          <Tooltip placement="topLeft" title="Edit user">
            <Button icon={<EditFilled />} onClick={() => editOrg(org)
            }
              className="relative btn-default_icons bottom-px" />
          </Tooltip>
        </div>
      )
    }
  ];

export const OrganizationsTable = () => {
  const dispatch = useDispatch();
  const [visibleAddEdit, setVisibleAddEdit] = useState(false);
  const [defaultPage, setDefaultPage] = useState(1);

  const { organizations, totalCount } = useSelector(selectAllOrganizations);

  useComponentDidMount(() => dispatch(fetchOrganizations({ payload: { limit, offset: 0 } })));


  const addNewOrg = () => {
    dispatch(deselectOrganization());
    setVisibleAddEdit(true);
  };

  const editOrg = (org: UserOrganization) => {
    dispatch(selectOrganization(org));
    setVisibleAddEdit(true);
  };

  const onCancel = (fn: (val: boolean) => void) => {
    dispatch(deselectOrganization());
    fn(false);
  };


  return (
    <>
      <div className='flex justify-start'>
        <Button type="primary" onClick={addNewOrg}>
          Add Organization
        </Button>
      </div>

      <Table
        rowKey="id"
        dataSource={organizations}
        columns={columns({ editOrg })}
        pagination={false}
      />

      {totalCount > (organizations && organizations.length) && (
        <Row className='mt-5' justify="end">
          <Pagination
            simple
            current={defaultPage}
            total={totalCount}
            pageSize={limit}
            onChange={async (page: number) => {
              setDefaultPage(page)
              dispatch(fetchOrganizations({
                payload: {
                  limit,
                  offset: limit * (page - 1)
                }
              }))
            }}
          />
        </Row>
      )}

      <AddUpdateForm
        visible={visibleAddEdit}
        onCancel={() => onCancel(setVisibleAddEdit)}
      />
    </>
  )
}
