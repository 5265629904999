import { useDispatch, useSelector } from 'react-redux';
import { Button, Pagination, Popconfirm, Row, Table, Tag, Tooltip, Typography } from 'antd'
import { useComponentDidMount } from '../../../../hooks';
import { Notification, NotificationType, notificationTypeColors } from '../../../../types';
import { DeleteOutlined, EditFilled } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { PAGINATION_LIMIT } from '../../../../config';
import { AddUpdateForm } from './NotificationsActions/AddUpdateForm.component';
import { deleteNotitication, deselectNotification, fetchNotifications, selectAllNotifications, selectNotification } from '@store/notification';
import { api } from '../../../../api';
import { sendError, sendMessage } from '@util/helpers/notifications.helper';

interface Props {
  editNotification: (val: Notification) => void;
  deleteAction: (notification: Notification) => void;
  loading: boolean;
}

const limit = PAGINATION_LIMIT;

const columns = ({
  editNotification,
  deleteAction,
  loading,
}: Props): ColumnsType<Notification> => [
    {
      title: 'User',
      dataIndex: 'user',
      width: '15%',
      render: ({username}) => (<Typography.Text>{username}</Typography.Text>)
    },
    {
      title: 'Notification types',
      dataIndex: 'notification_types',
      render: (notifications: NotificationType[]) => (
        <div className='flex gap-2.5'>
          {notifications.map((n, i) =>
            <Tag color={notificationTypeColors[i]} key={n}>{n.replaceAll('_', ' ')}</Tag>
          )}
        </div>
      )
    },
    {
      title: 'Actions',
      render: (notification: Notification) => (
        <div className="flex items-center gap-2.5">
          <Tooltip placement="topLeft" title="Edit notification">
            <Button icon={<EditFilled />} onClick={() => editNotification(notification)
            }
            className="mt-1 btn-default_icons"
            />
          </Tooltip>

          <Tooltip placement="topLeft" title="Delete template">
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() => deleteAction(notification)}
              okText="Yes"
              cancelText="No"
            >
              <Button loading={loading} className="btn-default_icons" danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Tooltip>

        </div>
      )
    }
  ];

export const NotificationsTable = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [visibleAddEdit, setVisibleAddEdit] = useState(false);
  const [defaultPage, setDefaultPage] = useState(1);

  const { notifications, totalCount } = useSelector(selectAllNotifications);

  useComponentDidMount(() => dispatch(fetchNotifications({ payload: { limit, offset: 0 } })));


  const addNotification = () => {
    dispatch(deselectNotification());
    setVisibleAddEdit(true);
  };

  const editNotification = (notification: Notification) => {
    dispatch(selectNotification(notification));
    setVisibleAddEdit(true);
  };

  const onCancel = (fn: (val: boolean) => void) => {
    dispatch(deselectNotification());
    fn(false);
  };


  const deleteAction = async (notification: Notification) => {
    setLoading(true);
    try {
      const { status } = await api.delete(`/notifications/${notification.id}`);
      if (status !== 200) throw new Error();

      sendMessage('Notification deleted successfully!');

      dispatch(deleteNotitication(notification?.id || ''));
      dispatch(deselectNotification());
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className='flex justify-start'>
        <Button type="primary" onClick={addNotification}>
          Add Notification
        </Button>
      </div>

      <Table
        rowKey="id"
        dataSource={notifications}
        columns={columns({ editNotification, deleteAction, loading })}
        pagination={false}
      />

      {totalCount > (notifications && notifications.length) && (
        <Row className='mt-5' justify="end">
          <Pagination
            simple
            current={defaultPage}
            total={totalCount}
            pageSize={limit}
            onChange={async (page: number) => {
              setDefaultPage(page)
              dispatch(fetchNotifications({
                payload: {
                  limit,
                  offset: limit * (page - 1)
                }
              }))
            }}
          />
        </Row>
      )}

      <AddUpdateForm
        visible={visibleAddEdit}
        onCancel={() => onCancel(setVisibleAddEdit)}
      />
    </>
  )
}
