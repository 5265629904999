import { UserOrganization } from '../../types';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';
import { sendError } from '@util/helpers/notifications.helper';
import { IPaginationVars } from 'types/pagination.types';

interface OrgsResponse {
  organizations: UserOrganization[];
  totalCount: number;
  limit: number;
  offset: number;
}

export const fetchOrganizations = createAsyncThunk<OrgsResponse, { payload: IPaginationVars }>('organizations/fetchAll', async () => {
  try {
    const { data } = await api.get<OrgsResponse>('/organizations');

    return data;
  } catch(err) {
    sendError(err);
    throw err;
  }
});


export const selectOrganization = createAction<UserOrganization | null>('Organization/selectOrganization');
export const deselectOrganization = createAction('Organization/deselectOrganization');
export const addOrganization = createAction<UserOrganization>('Organization/addOrganization');
export const updateOrganization = createAction<UserOrganization>('Organization/updateOrganization');
