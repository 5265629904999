import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  BeadlNodeModalChangeStatusPayload,
  BeadlNodeModalState,
} from "@customTypes/store/beadlNodeModal";

const beadlNodeModalSlice = createSlice({
  name: "beadlNodeModal",
  initialState: {
    status: false,
  } as BeadlNodeModalState,
  reducers: {
    changeStatus: (
      state: BeadlNodeModalState,
      action: PayloadAction<BeadlNodeModalChangeStatusPayload>
    ) => {
      const { status } = action.payload;
      return { ...state, status };
    },
  },
});

export const { changeStatus } = beadlNodeModalSlice.actions;

export default beadlNodeModalSlice.reducer;
