import { Route, Routes } from "react-router-dom";
import { Error404 } from "@components/index";

import { default as Main} from "./Main/Main.page";
import { default as Login } from "./Login/Login.page";
import { default as Logout } from "./Logout/Logout.page";
import { default as ForgotPassword } from "./ForgotPassword/ForgotPassword.page";
import { Workspace } from "./Workspace";
import { AdminTemplates, AdminUserRequests, AdminUsers } from "./Admin";
import { UserProfile } from "./Profile";
import { default as ResetPasswordPage } from "./ResetPassword/ResetPassword.page";
import SingleUserProfilePage from "./Admin/AdminUsers/SingleUserProfile.page";
import RegisterPage from "./Register/Register.page";
import AdminOrganizationsPage from "./Admin/AdminOrganizations/AdminOrganizations.page";
import AdminNotificationsPage from "./Admin/AdminNotifications/AdminNotifications.page";

const Router = () => {
  return (
    <Routes>
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/requests" element={<AdminUserRequests />} />
      <Route path="/admin" element={<AdminUsers />} />
      <Route path="/admin/users/:id" element={<SingleUserProfilePage />} />
      <Route path="/templates" element={<AdminTemplates />} />
      <Route path="/organizations" element={<AdminOrganizationsPage />} />
      <Route path="/notifications" element={<AdminNotificationsPage />} />
      <Route path="/" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
      <Route path="/profile" element={<UserProfile />} />
      <Route path="/workspace" element={<Workspace />} />
      <Route path="/app/:id" element={<Main />} />
      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default Router;
