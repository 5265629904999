import { QuestionCircleOutlined } from '@ant-design/icons';
import { Carousel, Modal } from 'antd';
import { useState } from 'react';
import { helpSliderImages } from '../../tooltip.text';
import prev from '../../assets/prev.jpg';
import next from '../../assets/next.png';

const CustomCarousal = () => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Modal
        title=""
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={false}
        width={1000}
        className="cs_carousel-modal"
      >
        <Carousel
          autoplay
          arrows
          dots={false}
          prevArrow={<img src={prev} alt="PreviousIcon" />}
          nextArrow={<img src={next} alt="NextIcon" />}
          pauseOnHover
        >
          {helpSliderImages.map((i) => (
            <img
              key={i.id}
              src={i.src}
              alt={i.alt}
              className="w-full"
            />
          ))}
        </Carousel>
      </Modal>

      <QuestionCircleOutlined className='fixed bg-white rounded-full bottom-24 right-77' style={{ fontSize: 40 }} onClick={() => setVisible(true)} />
    </>
  );
};

export default CustomCarousal;
