import { FC, ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { Error403 } from '@components/errors/Error403.component';
import { useSelector } from 'react-redux';
import { userSelector } from '@store/auth';
import { UserRole } from '../types';

export const withAuth = <T extends Record<string, unknown>>(
  WrappedComponent: FC<T>, roles?: UserRole[],
): FC<T> => (
    (props: T): ReactElement => {
      const { pathname } = useLocation();
      const user = useSelector(userSelector);

      if (
        !user?.id
        || !user.group
        || (roles && !roles.includes(user.group))
      ) {
        return <Navigate to="/" />;
      }

      if (pathname.toLowerCase().startsWith('/admin') && user.group !== 'Admin') {
        return <Error403 />;
      }

      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...props} />;
    }
  );
