import { useComponentDidMount, useSignIn } from "../../hooks";

const Logout = () => {
  const { onLogout } = useSignIn();

  useComponentDidMount(onLogout);

  return <div />;
};

export default Logout;
