import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@store/index";
import {
  addOrUpdateNode,
  changeDrawerOpen,
  removeFlowElements,
  setBaseStateName,
} from "@store/beadlEditor";
import Drawer from "@components/core/Drawer";
import BeadlEditorDrawerForm from "@components/BeadlEditor/Form";
import { BeadlState } from "@customTypes/model/beadlEditor/beadlState";
import useForm from "antd/lib/form/hooks/useForm";
import { CopyOutlined, DeleteOutlined } from "@ant-design/icons";
import { BeadlEditorData } from "@customTypes/store/beadlEditor";
import { createBeadlState } from "@util/constructor";

/**
 * # BeadlEditorDrawer
 *
 * `BeadlEditorDrawer` is a custom drawer component that is used to add / delete / duplicate `BeadlState`s. It is
 * controlled by the `drawerState` which is placed under `beadlEditor` attribute of the global redux `store`
 * (`store.beadlEditor.drawerState`). It has 5 callbacks to mutate global state:
 *
 * - `handleClose` -> used to close the drawer
 * - `handleSave` -> used to save current `formData` that is controlled by `BeadlEditorDrawerForm` component, which is
 *   rendered inside the `BeadlEditorDrawer`.
 * - `handleDrawerDelete` -> used to delete selected state shown in the `BeadlEditorDrawerForm`.
 * - `handleDrawerDuplicate` -> used to duplicate selected state shown in the `BeadlEditorDrawerForm`.
 * @category @components/BeadlEditor
 * @returns {JSX.Element}
 */
export const BeadlEditorDrawer = () => {
  const editorDrawerState = useSelector(
    (state: RootState) => state.beadlEditor.drawerState
  );
  const activeTabData = useSelector<RootState, BeadlEditorData>(
    (state) => state.beadlEditor.tabNameDataMap[state.beadlEditor.activeTab]
  );
  const { flowElements, stateNameIndexMap } = activeTabData;

  const { open, formData, baseStateName } = editorDrawerState;
  const dispatch = useDispatch();

  const [form] = useForm<typeof formData>();

  const handleClose = useCallback(() => {
    dispatch(setBaseStateName());
    dispatch(changeDrawerOpen());
  }, [dispatch]);

  const handleSave = useCallback(
    async (values: BeadlState, isDuplicate: boolean = false) => {
      try {
        await form.validateFields();
      } catch (e) {
        console.warn(e);
        return;
      }

      dispatch(
        addOrUpdateNode({
          updated: values,
          isDuplicate,
        })
      );
      handleClose();
    },
    [dispatch, form, handleClose]
  );

  const handleDrawerDelete = useCallback(async () => {
    if (!baseStateName) {
      return;
    }
    const flowElement = flowElements[stateNameIndexMap[baseStateName]];
    dispatch(removeFlowElements([flowElement]));
    dispatch(setBaseStateName());
    dispatch(changeDrawerOpen());
  }, [baseStateName, dispatch, flowElements, stateNameIndexMap]);

  const handleDrawerSave = useCallback(() => {
    handleSave(formData);
  }, [formData, handleSave]);

  const handleDrawerDuplicate = useCallback(() => {
    const newFormData: BeadlState = createBeadlState({
      id: formData.id,
      name: `${formData.name} (COPY - ${Math.floor(Math.random() * 1000)})`,
      duration: { ...formData.duration },
      actions: (formData.actions || []).map((action) => ({ ...action })),
      events: (formData.events || []).map((event) => ({ ...event })),
    });
    handleSave(newFormData, true);
  }, [formData, handleSave]);

  return (
    <Drawer
      title="BAABL Node"
      size="large"
      visible={open}
      onClose={handleClose}
      destroyOnClose
      additionalActions={[
        {
          label: "Duplicate",
          action: handleDrawerDuplicate,
          icon: <CopyOutlined />,
        },
      ]}
      successAction={{
        label: "Save",
        action: handleDrawerSave,
      }}
      failAction={
        baseStateName
          ? {
              icon: <DeleteOutlined />,
              danger: true,
              action: handleDrawerDelete,
            }
          : undefined
      }
    >
      {open && <BeadlEditorDrawerForm form={form} handleSave={handleSave} />}
    </Drawer>
  );
};

export default BeadlEditorDrawer;
