import { Notification, NotificationResponse } from '../../types';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';
import { sendError } from '@util/helpers/notifications.helper';
import { IPaginationVars } from 'types/pagination.types';

export const fetchNotifications = createAsyncThunk<NotificationResponse, { payload: IPaginationVars }>('organizations/fetchAll', async () => {
  try {
    const { data } = await api.get<NotificationResponse>('/notifications');

    return data;
  } catch(err) {
    sendError(err);
    throw err;
  }
});


export const selectNotification = createAction<Notification | null>('Notification/selectNotification');
export const deselectNotification = createAction('Notification/deselectNotification');
export const addNotification = createAction<Notification[]>('Notification/addNotification');
export const updateNotification = createAction<Notification>('Notification/updateNotification');
export const deleteNotitication = createAction<string>('Notification/deleteNotification');
