import { useCallback, useState } from "react";
import ReactFlow, { Connection, Edge, Elements } from "react-flow-renderer";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "@store/index";
import {
  addOrUpdateNode,
  removeFlowElements,
  setBaseStateName,
  updateFlowNode,
} from "@store/beadlEditor";
import BeadlNode from "@components/BeadlEditor/BeadlNode";
import BeadlEditorDrawer from "@components/BeadlEditor/BeadlEditorDrawer";
import { BeadlEditorData } from "@customTypes/store/beadlEditor";
import BeadlEdge from "./BeadlEdge";
import { BeadlState } from "@customTypes/model/beadlEditor/beadlState";
import { BeadlEdgeDrawer } from "./BeadlEdgeDrawer";
import { BeadlTransition } from "@customTypes/model/beadlEditor/beadlTransition";
import { uniq } from "lodash";
import { defaultEdgeColor } from "../../tooltip.text";

const nodeTypes = {
  beadl: BeadlNode,
};

const edgeTypes = {
  beadlEdge: BeadlEdge,
};

const ReactFlowContainer = styled.div`
  height: 70vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  .react-flow {
    background-color: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    &__node {
      border-radius: 0px;
      min-width: 300px;
      padding: 0px;
    }
    /* &__edges {
      z-index: 10;
    } */
    &__edge-path {
      stroke: ${defaultEdgeColor};
      stroke-width: 3px;
    }
  }
`;

/**
 * # BAABL EDITOR
 *
 * BAABL Graphical editor is used to draw protocols on an interactive environment. is developed using the
 * [react-flow-renderer] package.
 *
 * [react-flow-renderer] exports a `ReactFlow` component that renders a graphical flow diagram. This component can be used
 * to draw & control resources.
 *
 * `BeadlEditor` is a wrapper component around the `ReactFlow` component that is used to control the diagram. `BeadlEditor`
 * hosts 2 components: `ReactFlow` and `BeadlEditorDrawer`. Both components and configs for those components are explained
 * below.
 *
 * ## ReactFlow
 *
 * The `ReactFlow` is used to render & control a visual flow diagram. This component is controlled by passing props. The
 * main prop passed to this component is `elements` which is type of `Array<Element>`. `Element` object can be one of two
 * types: `Edge` or `Node`. `Node`s are used to draw components (like graph nodes) and `Edge`s are used to draw connections
 * between `Node`s. To sum up, `ReactFlow` component has a prop named as `elements` which is type of `Array<Node | Edge>`.
 *
 * Configs passed to the `ReactFlow` element is listed below. For more information, you can visit the
 * [official-docs-for-react-flow] component.
 *
 * ### Props used to customize `ReactFlow` component
 *
 * - **deleteKeyCode** = `"Delete"` key is used to remove some `Element`s from the `ReactFlow` component.
 *
 * - **onConnect** = `onConnect` method defined in the `BeadlEditor` in order to manage current state of the `ReactFlow`
 *   component when an edge is created. Whenever an edge is created, method passed as `onConnect` prop to the `ReactFlow`
 *   component is called.
 *
 * - **onElementsRemove** = `onElementsRemove` method defined in the `BeadlEditor` in order to manage current state of the
 *   `ReactFlow` component when an `Element` is removed. Whenever an `Element` (`Edge` or `Node`) is removed, method passed
 *   as `onElementsRemove` prop to the `ReactFlow` component is called.
 *
 * - **elements** = `flowElements` state is stored in the global `redux` store in order to access current editors state
 *   anywhere in the app. While rendering `ReactFlow` component, `flowElements` is passed as `elements` prop.
 *
 * - **nodeTypes** = `nodeTypes` is a map that is used to map custom node type components by a key. In the `BeadlEditor`,
 *   each node is created as `BeadlNode`. A custom [BAABL Node][beadl-node] component is created in the project to render &
 *   control the state of each node as wished.
 *
 * - **edgeTypes** = `edgeTypes` is a map that is used to map custom edge type components by a key. In the `BeadlEditor`,
 *   each edge is created as `BeadlEdge`. A custom [BAABL Edge][beadl-edge] component is created in the project to render a
 *   custom connection between two different states.
 *
 * - **onNodeDragStop** = `onNodeDragStop` methos is used to update repositioned `Node`s position in the global state.
 *
 * ## BeadlEditorDrawer
 *
 * [BeadlEditorDrawer][beadl-editor-drawer] component is created to create & edit `Node`s (or `BeadlState`s) in a drawer,
 * that enters the screen from right. It contains a form that has built in validations. For more information, please see
 * the components docs.
 *
 * <!-- REFERENCES -->
 *
 * [react-flow-renderer]: https://reactflow.dev/ "React Flow Renderer"
 * [official-docs-for-react-flow]: https://reactflow.dev/docs/api/component-props/ "ReactFlow"
 * [beadl-node]: /src/components/BeadlEditor/BeadlNode/ "BeadlNode Component"
 * [beadl-edge]: /src/components/BeadlEditor/BeadlEdge/ "BeadlEdge Component"
 * [beadl-editor-drawer]: /src/components/BeadlEditor/BeadlEditorDrawer/ "BeadlEditorDrawer Component"
 *
 * @category @components/BeadlEditor
 * @returns {JSX.Element}
 */
export const BeadlEditor = () => {
  const [drawerData, setDrawerData] = useState({ text: '', stroke: '', showLabel: true });
  const [selectedEdge, setSelectedEdge] = useState<Edge>();
  const activeTabData = useSelector<RootState, BeadlEditorData>(
    (state) => state.beadlEditor.tabNameDataMap[state.beadlEditor.activeTab]
  );
  const { flowElements } = activeTabData;
  const dispatch = useDispatch();

  const onConnect = useCallback(
    /**
     * Function that is called when a connection is built between two nodes
     * @param {Edge | Connection} edge - edge provided by react-flow-renderer
     */
    (edge: Edge | Connection) => {
      const { source, sourceHandle, target } = edge;
      dispatch(setBaseStateName(source!));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const indexStr = sourceHandle;
      const chosenState = activeTabData.flowElements[
        activeTabData.stateNameIndexMap[source!]
      ].data as BeadlState;

      const index = Number(indexStr);
      const events = [...chosenState.events!];
      const eventRow = { ...events![index] };
      eventRow.triggered_state_id = target!;
      events.splice(index, 1, {
        ...events[index],
        triggered_state_id: target!,
      });

      const updated: BeadlState = {
        ...chosenState,
        events,
      };
      dispatch(addOrUpdateNode({ updated }));
      dispatch(setBaseStateName());
    },
    [activeTabData.flowElements, activeTabData.stateNameIndexMap, dispatch]
  );

  const onElementsRemove = useCallback(
    (elements: Elements) => {
      dispatch(removeFlowElements(elements));
    },
    [dispatch]
  );

  const onNodeDragStop = useCallback(
    (_event, node) => {
      dispatch(updateFlowNode(node));
    },
    [dispatch]
  );

  const onEdgeDoubleClick = (_: unknown, edge: Edge) => {
    setSelectedEdge(edge);
    setDrawerData({
      text: edge.data?.text || '',
      stroke: edge.data?.style?.stroke || '',
      showLabel: typeof edge.data?.showLabel === 'boolean' ? edge.data.showLabel: true,
    });
  };

  const onDrawerClose = () => setSelectedEdge(undefined);

  return (
    <ReactFlowContainer className="h-full">
      <ReactFlow
        deleteKeyCode="Delete"
        onConnect={onConnect}
        onElementsRemove={onElementsRemove}
        elements={flowElements}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        onNodeDragStop={onNodeDragStop}
        onEdgeDoubleClick={onEdgeDoubleClick}
        arrowHeadColor={defaultEdgeColor}
      />
      <BeadlEditorDrawer />

      <BeadlEdgeDrawer
        visible={!!selectedEdge}
        onClose={onDrawerClose}
        data={selectedEdge}
        drawerData={drawerData}
        setDrawerData={setDrawerData}
        usedColors={
          uniq((flowElements as unknown as BeadlTransition[])
            .filter((el) => !!el.data?.style?.stroke)
            .map((el) => el?.data?.style?.stroke || ''))
        }
      />
    </ReactFlowContainer>
  );
};

export * from "./BeadlEdge";
export * from "./BeadlNode";
export * from "./BeadlEditorDrawer";
export * from "./Form";
export * from "./BeadlHandle";

export default BeadlEditor;
