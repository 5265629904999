import { Button, Form, Input, Typography } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import { RegisterPayload, UserRequest } from "types";
import { useNavigate } from "react-router-dom";
import { api } from "../../../api";
import { sendError, sendMessage } from "@util/helpers/notifications.helper";

export const Register = () => {
  const [ token, setToken ] = useState<string | null>(null);
  const [ loading, setLoading ] = useState(false);

  const navigate = useNavigate();

  const onRegister = async (payload: RegisterPayload) => {
    if(!token) return;
    setLoading(true);

    try {
      const { data: userRequest } = await api.post<UserRequest>('/user-requests', { ...payload, token });
      if (!userRequest ) throw new Error('Something went wrong!');

      navigate('/');
      sendMessage('Your registration request has been sent successfully!')
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  }

  const onChange = (token: string | null) => {
    setToken(token);
  }


  return (
    <div className="flex items-center justify-center h-screen bg-indigo-100">
      <div className="w-2/3 lg:w-2/5 md:w-1/2">
        <div className="min-w-full p-10 bg-white shadow-lg">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onRegister}
            autoComplete="off"
          >
            <h1 className="mb-6 text-2xl font-bold text-center text-gray-600">Register</h1>
            <div>
              <label className="block my-3 font-semibold text-left text-gray-800 text-md">First name</label>
              <Form.Item
                name="firstName"
                rules={[{ required: true, message: 'Please input your First name!' }]}
              >
                <Input
                  className="w-full bg-gray-100 cs_input focus:outline-none"
                  style={{ width: 'calc(100% + 50%)' }}
                />
              </Form.Item>
            </div>
            <div>
              <label className="block my-3 font-semibold text-left text-gray-800 text-md">Last name</label>
              <Form.Item
                name="lastName"
                rules={[{ required: true, message: 'Please input your Last name!' }]}
              >
                <Input
                  className="w-full bg-gray-100 cs_input focus:outline-none"
                  style={{ width: 'calc(100% + 50%)' }}
                />
              </Form.Item>
            </div>
            <div>
              <label className="block my-3 font-semibold text-left text-gray-800 text-md">Username</label>
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Input
                  className="w-full bg-gray-100 cs_input focus:outline-none"
                  style={{ width: 'calc(100% + 50%)' }}
                />
              </Form.Item>
            </div>
            <div>
              <label className="block my-3 font-semibold text-left text-gray-800 text-md">Email</label>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: 'Please input your Email!' },
                  { type: 'email', message: 'The input is not valid E-mail!'},
                ]}
              >
                <Input
                  className="w-full bg-gray-100 cs_input focus:outline-none"
                  style={{ width: 'calc(100% + 50%)' }}
                />
              </Form.Item>
            </div>
            <div>
              <label className="block my-3 font-semibold text-left text-gray-800 text-md">Organization</label>
              <Form.Item
                name="organization"
                rules={[{ required: true, message: 'Please input your Organization!' }]}
              >
                <Input
                  className="w-full bg-gray-100 cs_input focus:outline-none"
                  style={{ width: 'calc(100% + 50%)' }}
                />
              </Form.Item>
            </div>
            {process.env.REACT_APP_RECAPTCHA_SECRET ? (
              <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SECRET}
              onChange={onChange}
            />
            ) : (
              <div>
                <Typography.Text>Unable to load reCaptcha, please try again!!</Typography.Text>
              </div>
            )}


            <Button
              htmlType="submit"
              loading={loading}
              type="primary"
              disabled={!token}
              className="mt-6 font-semibold tracking-wide text-white cs_input"
            >
              Register
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};
