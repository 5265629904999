import { CustomInput } from '@components/FormFields';
import { CognitoUser, IChangeUserPass } from '../../../types';
import { Button, Modal, Typography } from 'antd'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { sendError, sendMessage } from '@util/helpers/notifications.helper';

interface Props {
  visible: boolean;
  onCancel: () => void;
}

const validationSchema = Yup.object({
  oldPassword: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      'Password must contain number, uppercase, lowercase, one special character. Password must be at least 8 characters long!',
    ).required('Old password is required'),
  newPassword: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      'Password must contain number, uppercase, lowercase, one special character. Password must be at least 8 characters long!',
    ).required('New password is required'),
  rpassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords do not match!')
    .required('Repeat password is required!'),
});

export const ChangePassword = ({ visible, onCancel }: Props) => {
  const [loading, setLoading] = useState(false);

  const cancelEvent = () => {
    formik.resetForm();
    onCancel();
  };


  const onSubmit = async ({ oldPassword, newPassword }: IChangeUserPass) => {

    setLoading(true);

    try {
      const res = await Auth.currentAuthenticatedUser() as CognitoUser;
      if (!res?.signInUserSession?.idToken?.payload?.sub) throw new Error('Something went wrong!');

      await Auth.changePassword(res, oldPassword, newPassword as string);
      sendMessage('Password updated successfully!');
      onCancel();
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  }

  const formik = useFormik<IChangeUserPass>({
    initialValues: { oldPassword: '', newPassword: '' },
    validationSchema,
    onSubmit,
  });

  return (
    <Modal
      className='users-actions_modal'
      title={(
        <Typography.Text className='flex justify-center text-base font-bold'>
          Change your password!
        </Typography.Text>
      )}
      centered
      visible={visible}
      onCancel={cancelEvent}
      footer={false}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className='flex flex-wrap w-full gap-4 px-2 pr-10'>
        <div className='flex flex-col gap-1.5 w-3/5'>
            <label className='text-xs font-light' htmlFor="password">Old Password</label>
            <CustomInput formik={formik} name='oldPassword' type="password" placeholder='Old Password' />
          </div>

          <div className='flex flex-col gap-1.5 w-3/5'>
            <label className='text-xs font-light' htmlFor="password">New Password</label>
            <CustomInput formik={formik} name='newPassword' type="password" placeholder='New Password' />
          </div>

          <div className='flex flex-col gap-1.5 w-3/5'>
            <label className='text-xs font-light' htmlFor="rpassword">Repeat Password</label>
            <CustomInput formik={formik} name='rpassword' type="password" placeholder='Repeat Password' />
          </div>

          <div className='w-2/4' />
          <div className='w-2/4' />
          <div className='w-2/4' />
          <div className='w-2/4' />
          <div className='w-2/4' />
        </div>

        <div className='flex justify-between w-full px-2 pt-10'>
          <Button type="ghost" className="w-2/5 btn-br-black" onClick={cancelEvent}>Cancel</Button>
          <Button type="ghost" className="w-2/5 btn-br-black" htmlType="submit" loading={loading}>
            Update Password
          </Button>
        </div>
      </form>
    </Modal>
  )
}