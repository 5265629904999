import { BeadlState } from "@customTypes/model/beadlEditor/beadlState";
import {
  BeadlEditorData,
  BeadlEditorState,
  BeadlEditorUpdateType,
  BeadlTask,
  SetTabDataPayload,
  StateNameUpdatePayload,
  UpdateFlowNodePayload,
  UpdateFlowNodeDataPayload,
} from "@customTypes/store/beadlEditor";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getBeadlEditorStateNameIndexMap,
  updateActiveTabData,
} from "@store/beadlEditor/util";
import {
  addEdge,
  Connection,
  Edge,
  Elements,
  FlowElement,
  removeElements,
} from "react-flow-renderer";
import {
  beadlEditorDrawerReducer,
  beadlEditorDrawerState,
} from "@store/beadlEditor/beadlEditorDrawer";
import { getEdgeId } from "@util/helpers";
import { BeadlTransition } from "@customTypes/model/beadlEditor/beadlTransition";
import { BEADL_EDGE } from "@util/constants";

const activeTab = "tab1";
const tabs = [activeTab];
const tabNameDataMap: Record<BeadlTask, BeadlEditorData> = {
  [activeTab]: {
    stateNameIndexMap: {},
    flowElements: [],
  },
};
const updateType: BeadlEditorUpdateType = "drawer";
const initialState: BeadlEditorState = {
  activeTab,
  tabs,
  tabNameDataMap,
  updateType,
  drawerState: beadlEditorDrawerState,
  showAllLabels: true,
};

const beadlEditorSlice = createSlice({
  name: "beadlEditor",
  initialState: initialState,
  reducers: {
    setTabData: (
      state: BeadlEditorState,
      action: PayloadAction<SetTabDataPayload>
    ) => {
      const { tab = state.activeTab, tabData } = action.payload;
      return {
        ...state,
        tabNameDataMap: { [tab]: tabData },
      };
    },
    changeUpdateType: (state: BeadlEditorState) => {
      return {
        ...state,
        updateType: (state.updateType === "node"
          ? "drawer"
          : "node") as BeadlEditorUpdateType,
      };
    },
    addNewEdge: (
      state: BeadlEditorState,
      action: PayloadAction<Edge | Connection>
    ) => {
      const nextFlowElements = addEdge(
        {
          ...action.payload,
          id: getEdgeId(action.payload as Edge),
        },
        state.tabNameDataMap[state.activeTab].flowElements
      );
      const nextStateNameIndexMap =
        getBeadlEditorStateNameIndexMap(nextFlowElements);
      // state.tabNameDataMap[state.activeTab].flowElements = nextFlowElements;
      return updateActiveTabData(
        state,
        nextFlowElements,
        nextStateNameIndexMap
      );
    },
    removeFlowElements: (
      state: BeadlEditorState,
      action: PayloadAction<Elements>
    ) => {
      const flowElements = removeElements(
        action.payload,
        state.tabNameDataMap[state.activeTab].flowElements
      );
      const maybeEdge = (action.payload as BeadlTransition[])[0];
      // if an edge is removed, apply the change in the `events` array
      if (maybeEdge.type === BEADL_EDGE) {
        const currentStateIndex =
          state.tabNameDataMap[state.activeTab].stateNameIndexMap[
            maybeEdge.source
          ];
        const currentState = flowElements[currentStateIndex];
        const currentStateEvents = [
          ...((currentState.data as BeadlState).events || []),
        ];
        const currentEventIndex = Number(maybeEdge.sourceHandle);
        const currentEvent = currentStateEvents[currentEventIndex];

        const nextEvent = {
          ...currentEvent,
          triggered_state_id: undefined,
        };
        currentStateEvents.splice(currentEventIndex, 1, nextEvent);
        const nextElement: FlowElement<BeadlState> = {
          ...currentState,
          data: {
            ...currentState.data,
            events: currentStateEvents,
          },
        };
        flowElements.splice(currentStateIndex, 1, nextElement);
      }

      const stateNameIndexMap = getBeadlEditorStateNameIndexMap(flowElements);
      return updateActiveTabData(state, flowElements, stateNameIndexMap);
    },
    updateNodeStateName: (
      state: BeadlEditorState,
      action: PayloadAction<StateNameUpdatePayload>
    ) => {
      const { oldValue, newValue } = action.payload;
      const activeTabState = state.tabNameDataMap[state.activeTab];
      const indexOfState = activeTabState.stateNameIndexMap[oldValue];

      const nextFlowElementData = {
        ...activeTabState.flowElements[indexOfState].data,
        name: newValue,
      } as BeadlState;

      const flowElements = [...activeTabState.flowElements];
      flowElements.splice(indexOfState, 1, {
        ...flowElements[indexOfState],
        id: nextFlowElementData.name,
        data: nextFlowElementData,
      });

      flowElements.forEach((elementBase, index, array) => {
        const flowElement = elementBase as Edge;

        if (flowElement.source && flowElement.target) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { source, target } = flowElement;
          if (source !== oldValue && target !== oldValue) {
            return;
          }
          const newTransitionElement = { ...flowElement } as Edge;
          if (source === oldValue) {
            newTransitionElement.id = getEdgeId({
              ...(flowElement as Edge),
              source: newValue,
            });
            newTransitionElement.source = newValue;
          } else {
            newTransitionElement.id = getEdgeId({
              ...(flowElement as Edge),
              target: newValue,
            });
            newTransitionElement.target = newValue;
          }
          array.splice(index, 1, newTransitionElement);
        }
        if (flowElement.type === "beadl") {
          const { data } = flowElement as FlowElement<BeadlState>;
          data?.events?.forEach((event) => {
            if (event.triggered_state_id === oldValue) {
              event.triggered_state_id = newValue;
            }
          });
        }
      });

      const stateNameIndexMap = { ...activeTabState.stateNameIndexMap };

      stateNameIndexMap[newValue] = indexOfState;
      delete stateNameIndexMap[oldValue];

      return {
        ...state,
        tabNameDataMap: {
          ...state.tabNameDataMap,
          [activeTab]: {
            stateNameIndexMap,
            flowElements,
          },
        },
      };
    },
    updateFlowNode: (
      state: BeadlEditorState,
      action: PayloadAction<UpdateFlowNodePayload>
    ) => {
      const payload = action.payload;

      const activeTabState = state.tabNameDataMap[state.activeTab];
      const flowElements = [...activeTabState.flowElements];

      const updatedNode = {
        ...flowElements[activeTabState.stateNameIndexMap[payload.data!.name]],
        ...payload,
      };

      flowElements.splice(
        activeTabState.stateNameIndexMap[payload.data!.name],
        1,
        updatedNode
      );

      return updateActiveTabData(state, flowElements);
    },
    updateFlowEdge: (
      state: BeadlEditorState,
      action: PayloadAction<Edge>
    ) => {
      const payload = action.payload;

      const activeTabState = state.tabNameDataMap[state.activeTab];
      const flowElements = [...activeTabState.flowElements];

      const index = flowElements.findIndex((el) => el.id === payload.id);
      if (index === -1) return;

      const updatedNode = { ...flowElements[index], ...payload };

      flowElements.splice(index, 1, updatedNode);

      state.tabNameDataMap[state.activeTab].flowElements = flowElements;
    },
    deleteFlowEdge: (
      state: BeadlEditorState,
      { payload }: PayloadAction<string>
    ) => {
      const activeTabState = state.tabNameDataMap[state.activeTab];
      const flowElements = [...activeTabState.flowElements];

      state.tabNameDataMap[state.activeTab].flowElements = flowElements.filter((el) => el.id !== payload);
    },
    updateFlowNodeData: (
      state: BeadlEditorState,
      action: PayloadAction<UpdateFlowNodeDataPayload>
    ) => {
      const payload = action.payload;

      const activeTabState = state.tabNameDataMap[state.activeTab];
      const flowElements = [...activeTabState.flowElements];

      const prevNode =
        flowElements[activeTabState.stateNameIndexMap[payload.name]];

      const updatedNode = {
        ...prevNode,
        data: {
          ...prevNode.data,
          ...payload,
        },
      } as FlowElement<BeadlState>;

      flowElements.splice(
        activeTabState.stateNameIndexMap[payload.name],
        1,
        updatedNode
      );

      return updateActiveTabData(state, flowElements);
    },
    setShowAllLabels: (state, action: PayloadAction<boolean>) => ({ ...state, showAllLabels: action.payload, }),
    ...beadlEditorDrawerReducer,
  },
});

export const {
  setTabData,
  changeUpdateType,
  addNewEdge,
  removeFlowElements,
  updateNodeStateName,
  changeDrawerOpen,
  updateFlowNode,
  updateFlowEdge,
  updateFlowNodeData,
  setBaseStateName,
  setFormData,
  addOrUpdateNode,
  setShowAllLabels,
  deleteFlowEdge,
} = beadlEditorSlice.actions;

export default beadlEditorSlice.reducer;
