import React from 'react'
import { Tooltip, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

interface TT {
  title: string,
  tooltipText: string
}

export const CustomTooltip = ({ title, tooltipText }: TT) => {
  return (
    <Typography className="flex items-center gap-1">
      {title}
      <Tooltip title={tooltipText}>
        <InfoCircleOutlined />
      </Tooltip>
    </Typography>
  )
}

// export default CustomTooltip