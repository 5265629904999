import Select from "@components/core/Select";
import { ResourceSelectorProps } from "@customTypes/components/selectors/ResourceSelector";

import { beadlSelector, Resource } from "@store/beadl";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { itemIdentifier, renderLabel } from "../util";

const ResourceSelector = (props: ResourceSelectorProps) => {
  const {
    selected,
    onChange,
    placeholder = "Input Event",
    options,
    className,
  } = props;

  const { resources: { data: resources } } = useSelector(beadlSelector);

  const handleChange = useCallback(
    (id: number, item?: Resource) => {
      onChange && onChange(id, item);
    },
    [onChange]
  );

  return (
    <Select
      className={className}
      options={options || resources}
      selected={selected}
      onChange={handleChange}
      placeholder={placeholder}
      labelRender={(item) => renderLabel(item, "name")}
      itemIdentifier={(item) => itemIdentifier(item, "id")}
    />
  );
};

export default ResourceSelector;
