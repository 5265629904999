import { adminAllUsersSelector, deleteUser } from '@store/user';
import { sendError, sendMessage } from '@util/helpers/notifications.helper';
import { Modal, Button, Typography } from 'antd'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../../../../api';

interface Props {
  visible: boolean;
  onCancel: () => void;
}

export const DeleteUserModal = ({ visible, onCancel }: Props) => {
  const [loading, setLoading] = useState(false);

  const { selectedUser } = useSelector(adminAllUsersSelector);

  const dispatch = useDispatch();

  const onDeleteUser = async () => {
    setLoading(true);
    try {
      const { status } = await api.delete(`/users/${selectedUser?.id || ''}`);
      if (status !== 200) throw new Error();

      sendMessage('User deleted successfully!');

      dispatch(deleteUser(selectedUser?.id || ''));
      onCancel();
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal
      className='users-actions_modal'
      title={(
        <Typography.Text className='flex justify-center text-base font-bold'>
          Confirm user deletion
        </Typography.Text>
      )}
      centered
      visible={visible}
      footer={[
        <div className='flex justify-between w-full px-2 pt-10 pb-2'>
          <Button key="cancel" type="ghost" className="w-2/5 btn-br-black" onClick={onCancel}>
            Cancel
          </Button>
          <Button key="submit" className="w-2/5" danger loading={loading} onClick={onDeleteUser}>
            Delete
          </Button>
        </div>
      ]}
    >
      <div className='flex flex-wrap w-full gap-1 px-2 pr-10'>
        <div className='w-full font-light'>{selectedUser?.firstName} {selectedUser?.lastName}</div>
        <div className='w-full font-light'>{selectedUser?.username}</div>
        <div className='w-full font-light'>{selectedUser?.email}</div>
        <div className='w-full font-light'>{selectedUser?.organization?.title}</div>
      </div>
    </Modal>
  )
}