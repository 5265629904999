import { BeadlAction } from "@customTypes/model/beadlConfiguration/actionDefinition";
import { BeadlConfigurationState } from "@customTypes/store/beadlConfiguration";
import { ActionDefinitionState, } from "@customTypes/store/beadlConfiguration";
import { PayloadAction } from "@reduxjs/toolkit";
import { getDataIndexMappedByField } from "@store/util";
import { stringToNumber } from "@util/helpers/stringToNumber.helper";
import { FlowElement } from "react-flow-renderer";
import { BeadlState } from "@customTypes/model/beadlEditor/beadlState";

export const actionDefinitionState: ActionDefinitionState = {
  data: [],
  dataIndexMappedById: {},
};

export const actionDefinitionReducers = {
  setActionDefinitions: (
    state: BeadlConfigurationState,
    action: PayloadAction<BeadlAction[]>
  ) => {
    const { payload } = action;
    return {
      ...state,
      actionDefinition: {
        data: payload.map((d) => ({
          ...d,
          connection: { ...d.connection || {}, component_id: stringToNumber(d?.connection?.component_id) },
        })),
        dataIndexMappedById: getDataIndexMappedByField(payload, "id"),
      },
    };
  },

  updateActionDefinition: (
    state: BeadlConfigurationState,
    { payload }: PayloadAction<BeadlAction>
  ) => {
    const data = state.actionDefinition.data.map((d) => (
      d.id !== payload.id ? d : payload
    ))

    return {
      ...state,
      actionDefinition: {
        ...state.actionDefinition,
        data,
      },
    };
  },

  addNewActionDefinition: (
    state: BeadlConfigurationState,
    { payload }: PayloadAction<BeadlAction>
  ) => {
    const data = [...state.actionDefinition.data, payload];

    const dataIndexMappedById = getDataIndexMappedByField(data, "id");

    return {
      ...state,
      actionDefinition: {
        data,
        dataIndexMappedById,
      },
    };
  },

  deleteActionDefinition: (
    state: BeadlConfigurationState,
    { payload }: PayloadAction<{ flowElements: FlowElement<BeadlState>[]; id: string }>
  ) => {
    const { id, flowElements } = payload;

    const argumentInUse = flowElements.some((fe) => fe?.data?.actions?.some((e) => e.action_id === id));
    if (argumentInUse) throw new Error('Action is in use and cannot be deleted. Please unlink to delete it!');

    const data = state.actionDefinition.data.filter((d) => d.id !== id);

    const dataIndexMappedById = getDataIndexMappedByField(data, "id");

    return {
      ...state,
      actionDefinition: {
        data,
        dataIndexMappedById,
      },
    };
  },
};
