import { useSelector } from "react-redux";
import { firstTimeUserSelector } from '@store/auth';
import { FirstTimeLogin, DefaultLogin } from './components';
import { withoutAuth } from "../../hocs";

const Login = () => {
  const firstTimeUser = useSelector(firstTimeUserSelector);

  if (firstTimeUser) {
    return <FirstTimeLogin />;
  }

  return <DefaultLogin />;
};

export default withoutAuth(Login);
