import Select from "@components/core/Select";
import { BeadlAction } from "@customTypes/model/beadlConfiguration/actionDefinition";
import { ActionSelectorProps } from "@customTypes/components/selectors/ActionSelector";
import { RootState } from "@store/index";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { itemIdentifier, renderLabel } from "../util";


const ActionSelector = (props: ActionSelectorProps) => {
  const {
    selected,
    onChange,
    placeholder = "Input BeadlAction",
    options,
  } = props;

  const actions = useSelector<RootState, BeadlAction[]>(
    (state: RootState) => state.beadlConfiguration.actionDefinition.data
  );

  const handleChange = useCallback(
    (id: number, item?: BeadlAction) => {
      onChange && onChange(id, item);
    },
    [onChange]
  );

  return (
    <Select
      options={options || actions}
      selected={selected}
      onChange={handleChange}
      placeholder={placeholder}
      labelRender={(item) => renderLabel(item, "name")}
      itemIdentifier={(item) => itemIdentifier(item, "id")}
    />
  );
};

export default ActionSelector;
