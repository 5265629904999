import { BeadlEditorState } from "@customTypes/store/beadlEditor";
import { RootState } from "@store/index";
import {
  changeDrawerOpen,
  changeUpdateType,
  setBaseStateName,
  setFormData,
  setShowAllLabels,
} from "@store/beadlEditor";
import Button from "antd/lib/button";
import Col from "antd/lib/grid/col";
import Row from "antd/lib/grid/row";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "antd";
import CustomCarousal from "@components/CustomCarousal";
import { helpSliderImages } from "../../../tooltip.text";

type RightSiderContentProps = {
  actionsDisabled?: boolean;
};

const RightSiderContent = ({ actionsDisabled }: RightSiderContentProps) => {
  const dispatch = useDispatch();
  const { updateType , showAllLabels } = useSelector<RootState>((state) => state.beadlEditor) as BeadlEditorState;

  const onAddElement = useCallback(() => {
    dispatch(setBaseStateName());
    dispatch(setFormData());
    dispatch(changeDrawerOpen());
  }, [dispatch]);

  const onChangeUpdateType = useCallback(() => {
    dispatch(changeUpdateType());
  }, [dispatch]);

  return (
    <Row className="px-4" gutter={[0, 16]}>
      <Col span={24}>
        <Button
          size="middle"
          disabled={actionsDisabled}
          onClick={onAddElement}
          className="w-full truncate"
        >
          Add New State
        </Button>
      </Col>
      <Col span={24}>
        <Switch
          disabled={actionsDisabled}
          onChange={onChangeUpdateType}
          checked={updateType === "node"}
          checkedChildren="Detailed view"
          unCheckedChildren="Standard view"
        />
      </Col>
      <Col span={24}>
        <Switch
          onChange={(status) => dispatch(setShowAllLabels(status))}
          checked={showAllLabels}
          checkedChildren="Show labels"
          unCheckedChildren="Hide labels"
        />
      </Col>
      {helpSliderImages.length > 0 && (
        <Col span={24}>
          <CustomCarousal />
        </Col>
      )}
    </Row>
  );
};

export default RightSiderContent;
