import { AddUserType, User } from '../../../../../types';
import { addUser } from '@store/user';
import { Button } from 'antd'
import { api } from '../../../../../api';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { CustomCheckbox, CustomInput, CustomOrganizations } from '@components/FormFields';
import * as Yup from 'yup';
import "./AddUpdateForm.styles.css"
import { sendError, sendMessage } from '@util/helpers/notifications.helper';

const validationSchema = Yup.object({
  firstName: Yup.string().required('First name is required!'),
  lastName: Yup.string().required('Last name is required!'),
  username: Yup.string().required('Last name is required!'),
  email: Yup.string().trim().lowercase().email('Invalid email!')
    .required('Email is required!'),
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      'Password must contain number, uppercase, lowercase, one special character. Password must be at least 8 characters long!',
    ).required('Password is required'),
  rpassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords do not match!')
    .required('Confirm password is required!'),
  organization: Yup.string().required('Organization is required!'),
});

interface Props {
  onCancel: () => void;
}

export const AddForm = ({ onCancel }: Props) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = async (input: AddUserType) => {
    setLoading(true);

    try {
      delete input.rpassword;

      const { data } = await api.post<User>('/users', input);
      if (!data) throw new Error('');

      dispatch(addUser(data));

      sendMessage('User added successfully!');

      formik.resetForm();
      onCancel();
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  };

  const initialValues: AddUserType = {
    username: '',
    firstName: '',
    lastName: '',
    organization: '',
    email: '',
    password: '',
    rpassword: '',
    isEmailVerified: false,
    isPermanentPassword: false,
  };

  const formik = useFormik<AddUserType>({
    initialValues: initialValues,
    validationSchema,
    onSubmit
  });

  const cancelAndReset = () => {
    formik.resetForm();
    onCancel();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='flex flex-wrap justify-between gap-4 px-2 pr-10'>
        <div className='flex flex-col gap-1.5 w-2/5'>
          <label className='text-xs font-light' htmlFor="firstName">First Name</label>
          <CustomInput formik={formik} name="firstName" placeholder="First name" />
        </div>

        <div className='flex flex-col w-2/5 gap-2'>
          <label className='text-xs font-light' htmlFor="lastName">Last Name</label>
          <CustomInput formik={formik} name="lastName" placeholder="Last name" />
        </div>

        <div className='flex flex-col w-2/5 gap-2'>
          <label className='text-xs font-light' htmlFor="username">Username</label>
          <CustomInput formik={formik} name="username" placeholder="Username" />
        </div>

        <div className='flex flex-col w-2/5 gap-2'>
          <label className='text-xs font-light' htmlFor="email">Email</label>
          <CustomInput formik={formik} name="email" placeholder="Email" />
        </div>

        <div className='flex flex-col w-2/5 gap-2'>
          <label className='text-xs font-light' htmlFor="password">Password</label>
          <CustomInput type='password' formik={formik} name="password" placeholder="Password" />
        </div>

        <div className='flex flex-col w-2/5 gap-2'>
          <label className='text-xs font-light' htmlFor="rpassword">Confirm Password</label>
          <CustomInput type='password' formik={formik} name="rpassword" placeholder="Confirm Password" />
        </div>

        <div className='flex flex-col w-2/5 gap-2'>
          <label className='text-xs font-light' htmlFor="organization">Organization</label>
          <CustomOrganizations
            formik={formik}
            name="organization"
            placeholder="Organization"
          />
        </div>

        <div className='w-2/4' />

        <div className='w-2/4'>
          <CustomCheckbox
            name="isEmailVerified"
            formik={formik}
            placeholder="Email verified"
          />

          <CustomCheckbox
            name="isPermanentPassword"
            formik={formik}
            placeholder="Permanent set password"
          />
        </div>
      </div>

      <div className='flex justify-between w-full px-2 pt-10'>
        <Button type="ghost" onClick={cancelAndReset} className="w-2/5 btn-br-black">
          Cancel
        </Button>
        <Button loading={loading} type="ghost" htmlType="submit" className='w-2/5 btn-br-black'>
          Add user
        </Button>
      </div>
    </form>
  )
}