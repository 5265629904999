import { BeadlConfigurationState } from "@customTypes/store/beadlConfiguration";
import {
  ProtocolSettingsState,
  SetProtocolSettingPayload,
  UpdateProtocolSettingPayload,
} from "@customTypes/store/beadlConfiguration";
import { PayloadAction } from "@reduxjs/toolkit";
import { stringToNumber } from "@util/helpers/stringToNumber.helper";

export const protocolSettingsState: ProtocolSettingsState = {
  hardwareId: undefined,
  initialStateId: "",
  numberOfTrials: "",
  name: "",
};

export const protocolSettingsReducers = {
  setProtocolSettingsValue: (
    state: BeadlConfigurationState,
    action: PayloadAction<UpdateProtocolSettingPayload>
  ) => {
    const { field, value } = action.payload;

    let payload: Partial<ProtocolSettingsState> = {};
    if (field === `hardwareId`) {
      payload[field] = value as number;
    } else {
      payload[field] = value as string;
    }
    return {
      ...state,
      protocolSettings: {
        ...state.protocolSettings,
        ...payload,
      },
    };
  },
  setProtocolSettings: (
    state: BeadlConfigurationState,
    { payload }: PayloadAction<SetProtocolSettingPayload>
  ) => {
    return {
      ...state,
      protocolSettings: {
        ...payload,
        hardwareId: stringToNumber(payload.hardwareId),
      },
    };
  },
};
