import { Button, Form, Input } from "antd";
import { IRoutes } from "../../../config";
import { Link } from "react-router-dom";
import { useSignIn } from '../../../hooks';

export const DefaultLogin = () => {
  const { loading, onLogin } = useSignIn();

  return (
    <div className="flex items-center justify-center h-screen bg-indigo-100">
      <div className="w-2/3 lg:w-2/5 md:w-1/2">
        <div className="min-w-full p-10 bg-white shadow-lg">
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onLogin}
            autoComplete="off"
          >
            <h1 className="mb-6 text-2xl font-bold text-center text-gray-600">Login</h1>
            <div>
              <label className="block my-3 font-semibold text-left text-gray-800 text-md">Username</label>
              <Form.Item
                name="username"
                rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Input
                  className="w-full bg-gray-100 cs_input focus:outline-none"
                  style={{ width: 'calc(100% + 50%)' }}
                />
              </Form.Item>
            </div>
            <div>
              <label className="block my-3 font-semibold text-left text-gray-800 text-md">Password</label>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                  () => ({
                    validator(_, value) {
                      let regExp = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,);
                      if (!value || regExp.test(value)) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Password must contain number, uppercase, lowercase, one special character. Password must be at least 8 characters long!'));
                    },
                  }),
                ]}
              >
                <Input.Password
                  className="w-full bg-gray-100 cs_input focus:outline-none"
                  style={{ width: 'calc(100% + 50%)' }}
                />
              </Form.Item>
              <div className="flex justify-end">
                <Button type="link" htmlType="button" className="px-0 mb-3 btn-text">
                  <Link
                    to={IRoutes.Forgot_Password}
                    className="text-base font-medium text-primary dark:text-secondary"
                  >
                    forgot password
                  </Link>
                </Button>
             </div>
            </div>
            <Button
              htmlType="submit"
              loading={loading}
              type="primary"
              className="mt-6 font-semibold tracking-wide text-white cs_input"
            >
              Login
            </Button>
          </Form>
        </div>
      </div>
    </div>
  );
};
