import { Col, Row } from "antd";
import { ReactNode } from "react";
import { Navbar } from './components/Navbar.component';

interface Props {
  navbarTitle: string;
  leftSidebar: ReactNode;
  mainComponent: ReactNode;
  rightSidebar?: ReactNode;
  hideRightSidebar?: boolean;
}

export const DashboardLayout = ({
  navbarTitle, leftSidebar, mainComponent, rightSidebar, hideRightSidebar = false,
}: Props) => (
  <Row>
    <Navbar title={navbarTitle} />

    <Row className="flex flex-row w-full" gutter={[20, 0]}>
      <Col span={4}>
        {leftSidebar}
      </Col>

      <Col span={hideRightSidebar ? 20 : 16} className={hideRightSidebar ? 'take-full-available-space' : 'take-middle-space-only'}>
        {mainComponent}
      </Col>

      {!hideRightSidebar && (
        <Col span={4}>
          {rightSidebar}
        </Col>
      )}
    </Row>
  </Row>
);
