import { deleteItem, myWorkspaceSelector } from "@store/workspace";
import { Modal } from "antd";
import { api } from "../../../api";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WorkspaceFile, WorkspaceFolder } from "../../../types";
import { sendError, sendMessage } from "@util/helpers/notifications.helper";
import Tree, { DataNode } from "antd/lib/tree";

const { DirectoryTree } = Tree;

interface Props {
  visible: boolean;
  item: WorkspaceFolder | WorkspaceFile;
  onCancel: () => void;
}

export const MoveToModal = ({ visible, item, onCancel }: Props) => {
  const { workspace } = useSelector(myWorkspaceSelector);
  const [loading, setLoading] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState('');

  const dispatch = useDispatch();

  if (!workspace?.id) return <div />;

  const workspaceTreeData: DataNode = {
    key: workspace.id,
    title: 'My Workspace',
    children: workspace?.folders.map((folder) => ({
      key: folder.id,
      title: folder.title,
    })),
  };

  const onClick = async () => {
    setLoading(true);

    try {
      if(!item.id || !selectedFolder) return;

      const isFile = !!(item as WorkspaceFile)?.s3Key;
      const isWorkspace = selectedFolder === workspace.id;
      const payload = {
        folder: !isWorkspace ? selectedFolder : undefined,
        workspace: isWorkspace ? selectedFolder : undefined,
        isFile,
      };

      const { data } = await api.patch(`/workspaces/moveItem/${item.id}`, payload);
      if (!data) throw new Error(`Unable to move ${isFile ? 'file' : 'folder'}!`);

      onCancel();
      dispatch(deleteItem(item.id));
      sendMessage('Successfully moved item!');
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Move to..."
      visible={visible}
      onCancel={onCancel}
      okButtonProps={{ loading, onClick }}
    >
      <DirectoryTree
        rootClassName='workspace-tree'
        onSelect={([selected]) => setSelectedFolder(selected as string)}
        treeData={[workspaceTreeData]}
        switcherIcon
        className="cs_tree"
        expandedKeys={[workspace.id]}
      />
    </Modal>
  );
};
