import { BeadlState } from "@customTypes/model/beadlEditor/beadlState";

export const createBeadlState = (baseState?: BeadlState): BeadlState => {
  const randomId = Math.floor(Math.random() * 1000);
  return {
    id: baseState?.id || `-1`,
    name: baseState?.name || `New State - ${randomId}`,
    duration: baseState?.duration
      ? {
          ...baseState.duration,
        }
      : {
          value: "inf",
        },
    events: baseState?.events ? [...baseState.events] : [],
    actions: baseState?.actions ? [...baseState.actions] : [],
  };
};
