import { useDispatch, useSelector } from 'react-redux';
import { Button, Pagination, Popconfirm, Row, Table, Tooltip } from 'antd'
import dayjs from 'dayjs';
import { useComponentDidMount } from '../../../../hooks';
import { UserRequest } from '../../../../types';
import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import { api } from '../../../../api';
import { useState } from 'react';
import { sendError, sendMessage } from '@util/helpers/notifications.helper';
import { PAGINATION_LIMIT } from '../../../../config';
import { deletedUserRequests, fetchAllUserRequests, updateUserRequest, userRequestStateSelector } from '@store/user-request';
import { ApproveRequest } from './UserRequestActions';

interface Props {
  approveRequest: (userRequest: UserRequest) => void;
  rejectRequest: (id: string) => void;
}

const limit = PAGINATION_LIMIT;

const columns = ({ approveRequest, rejectRequest }: Props): ColumnsType<UserRequest> => [
    {
      title: 'First Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      sorter: (a, b) => a.username.localeCompare(b.username),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email)
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
    },
    {
      title: 'Create date',
      dataIndex: 'createdAt',
      render: (createdAt: string) => dayjs(createdAt).format('ll'),
    },
    {
      title: 'Actions',
      render: (userRequest: UserRequest) => (
        <div className='flex gap-2.5'>
          {userRequest.status === 'PENDING' ? (
            <>
              <Tooltip placement="topLeft" title="Approve">
                <Button
                  icon={<CheckOutlined />}
                  className="btn-default_icons"
                  onClick={() => approveRequest(userRequest)}
                >
                </Button>
              </Tooltip>
              <Tooltip placement="topLeft" title="Reject">
                <Popconfirm
                  title={`Are you sure you want to reject this request?`}
                  onConfirm={() => rejectRequest(userRequest.id)}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button
                    icon={<CloseOutlined />}
                    className="btn-default_icons"
                    danger
                  >
                  </Button>
                </Popconfirm>
              </Tooltip>
            </>
          ) : userRequest.status}
        </div>
      )
    }
  ];

export const UserRequestsTable = () => {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [userRequest, setUserRequest] = useState<UserRequest | undefined>(undefined);
  const dispatch = useDispatch();


  const [defaultPage, setDefaultPage] = useState(1);

  const { userRequests, totalCount } = useSelector(userRequestStateSelector);

  useComponentDidMount(() => dispatch(fetchAllUserRequests({ payload: { limit, offset: 0 } })));

  const rejectRequest = async (id: string) => {
    try {
      const { data } = await api.patch<UserRequest>(`/user-requests/${id}/reject`);
      if (!data) throw new Error();

      sendMessage('User request rejected successfully!');

      dispatch(updateUserRequest({ ...data }));
    } catch (err) {
      sendError(err);
    }
  };

  const deleteUserRequests = async (ids: string[]) => {
    if(ids.length < 1) return;

    try {
      const {data} = await api.post<boolean>(`/user-requests/delete`, { ids });
      if (!data) throw new Error('Something went wrong!');

      sendMessage('User requests deleted successfully!');

      dispatch(deletedUserRequests(ids));
      setSelectedRows([]);
    } catch (err) {
      sendError(err);
    }
  }

  return (
    <>
     {selectedRows.length > 0 && (
        <div className="flex justify-start">
          <Popconfirm
            title={`Are you sure you want to delete selected requests?`}
            onConfirm={() => deleteUserRequests(selectedRows)}
            okText="Yes"
            cancelText="No"
          >
          <Button danger>Delete selected</Button>
          </Popconfirm>
        </div>
      )}
      <Table
        rowSelection={{
          type: "checkbox",
          onChange: (ids) => setSelectedRows(ids as string[]),
        }}
        rowKey="id"
        dataSource={userRequests}
        columns={columns({
          approveRequest: setUserRequest,
          rejectRequest,
        })}
        pagination={false}
      />

      {totalCount > userRequests.length && (
        <Row className='mt-5' justify="end">
          <Pagination
            simple
            current={defaultPage}
            total={totalCount}
            pageSize={limit}
            onChange={async (page: number) => {
              setDefaultPage(page)
              dispatch(fetchAllUserRequests({
                payload: {
                  limit,
                  offset: limit * (page - 1)
                }
              }))
            }}
          />
        </Row>
      )}

      {userRequest && (
        <ApproveRequest
          visible
          onCancel={() => setUserRequest(undefined)}
          userRequest={userRequest}
        />
      )}
    </>
  )
}
