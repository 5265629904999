import { Template } from '../../types';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';
import { sendError } from '@util/helpers/notifications.helper';

export const fetchAllTemplates = createAsyncThunk<Template[]>('template/allTemplates', async () => {
  try {
    const { data } = await api.get<Template[]>('/templates');

    return data;
  } catch (err) {
    sendError(err);
    throw err;
  }
});

export const selectTemplate = createAction<Template | null>('user/selectTemplate');
export const deselectTemplate = createAction('user/deselectUser');
export const addTemplate = createAction<Template>('template/addTemplate');
export const updateTemplate = createAction<Template>('template/updateTemplate');
export const deleteTemplate = createAction<string>('template/deleteTemplate');
