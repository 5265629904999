import { FileTextOutlined, FolderOutlined } from '@ant-design/icons';
import { deleteItem, myWorkspaceSelector, selectWorkspaceItem, setOpenFolder } from '@store/workspace';
import { Divider, List } from 'antd'
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { uniqBy } from 'lodash';
import { ContextMenuState, WorkspaceFolder, WorkspaceItemType } from '../../../types';
import { api } from '../../../api';
import { sendError, sendMessage } from '@util/helpers/notifications.helper';
import { useRef, useState } from 'react';
import { WorkspaceFile } from '@store/workspace/workspace.model';
import { ContextMenu } from './ContextMenu.component';

interface Props {
  data: WorkspaceItemType;
}

const WorkspaceItem = ({ data }: Props) => {
  return (
    <div className="container flex flex-wrap items-center justify-between max-w-full text-sm text-left cursor-pointer">
      <div className="flex items-center w-2/4">
        {data?.s3Key ? <FileTextOutlined /> : <FolderOutlined />}

        <span className="ml-3 text-sm truncate">
          {data.title}
        </span>
      </div>

      <div className='flex justify-between gap-4 w-40p'>
        <div className="truncate w-35p">
          me
        </div>

        <div className="truncate w-75p">
          {dayjs(data.updatedAt).format('ll')}
        </div>
      </div>
    </div>
  );
};

export const WorkspaceList = () => {
  const dispatch = useDispatch();
  const draggedItem = useRef<number>();
  const dragOverFolder = useRef<number>();
  const [showMenu, setShowMenu] = useState<ContextMenuState>({ showMenu: false });

  const { workspace, currentOpenFolder, subfolders, subfiles, selectedItem } = useSelector(myWorkspaceSelector);

  const onClick = (item: WorkspaceItemType) => {
    if (showMenu.showMenu) setShowMenu({ ...showMenu, showMenu: false });
    dispatch(selectWorkspaceItem(selectedItem?.id === item.id ? null : item));
  };

  const onDoubleClick = async (item: WorkspaceItemType) => {
    if (showMenu.showMenu) setShowMenu({ ...showMenu, showMenu: false });
    if (item?.s3Key) {
      window.open(`/app/${item.id}`, '_blank');
      return;
    }

    try {
      const { data } = await api.get<WorkspaceFolder>(`/workspaces/folders/${item.id}`);
      if (!data) throw new Error('Unable to open folder!');

      dispatch(setOpenFolder(data));
    } catch (err) {
      sendError(err);
    }
  };

  const dataSource = uniqBy((currentOpenFolder
    ? [
      ...currentOpenFolder?.folders || [],
      ...subfolders.filter((folder) => folder.parentFolder?.id === currentOpenFolder.id),
      ...currentOpenFolder?.files || [],
      ...subfiles.filter((file) => file.folder?.id === currentOpenFolder.id),
    ]
    : [...workspace?.folders || [], ...workspace?.files || []]), 'id');

  const dropIt = async () => {
    if (showMenu.showMenu) setShowMenu({ ...showMenu, showMenu: false });
    try {
      if(
        typeof dragOverFolder.current === 'undefined'
        || typeof draggedItem.current === 'undefined'
        || dragOverFolder.current < 0
        || draggedItem.current < 0
      ) return;

      const copyListItems = [...dataSource];

      const dragItemContent = copyListItems[draggedItem.current] as unknown as WorkspaceFile;
      const dragOverFolderContent = copyListItems[dragOverFolder.current]  as unknown as WorkspaceFile;

      if (
        !dragOverFolderContent
        || !dragItemContent
        || dragOverFolderContent.s3Key
      ) return;

      const isFile = !!dragItemContent?.s3Key;
      const payload = { folder: dragOverFolderContent.id, isFile };

      const { data } = await api.patch(`/workspaces/moveItem/${dragItemContent.id}`, payload);
      if (!data) throw new Error(`Unable to move ${isFile ? 'file' : 'folder'}!`);

      dispatch(deleteItem(dragItemContent.id));
      sendMessage('Successfully moved item!');
    } catch (err) {
      sendError(err);
    }
  };

  return (
    <section>
      <div
        onDragEnter={() => { dragOverFolder.current = -1 }}
        className="container flex flex-wrap items-center justify-between max-w-full px-4 pt-2 text-sm font-bold text-left border border-b-0 border-black rounded-t-md bg-table_head hover:table_head2">
        <div className="flex items-center w-2/4">
          Name
        </div>

        <div className='flex justify-between gap-4 w-40p'>
          <div className="w-35p">
            Owner
          </div>

          <div className="w-75p">
            Date modified
          </div>
        </div>

        <Divider className='cs_divider' />
      </div>

      <List
        size="small"
        bordered
        rowKey="id"
        className='workspace'
        dataSource={dataSource}
        renderItem={(item, index) => (
          <List.Item
            className={selectedItem?.id === item.id ? 'active' : ''}
            onDoubleClick={() => onDoubleClick(item as WorkspaceItemType)}
            onClick={() => onClick(item as WorkspaceItemType)}
            draggable
            onDragStart={() => { draggedItem.current = index; }}
            onDragEnter={() => { dragOverFolder.current = index }}
            onDragEnd={dropIt}
            onContextMenu={(e) => {
              e.preventDefault();

              const left = e.clientX - 295;
              const top = e.clientY - 120;

              setShowMenu({ left, top, showMenu: true, item });
              return false;
            }}
          >
            <WorkspaceItem data={item as WorkspaceItemType} />
          </List.Item>
        )}
      />

      <div onDragEnter={() => { dragOverFolder.current = -1 }} />

      <ContextMenu data={showMenu} setShowMenu={setShowMenu} />
    </section>
  )
}
