import { CustomInput } from "@components/FormFields";
import { addWorkspaceFolder, myWorkspaceSelector } from "@store/workspace";
import { Modal } from "antd";
import { api } from "../../../api";
import { FormikHelpers, useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WorkspaceFolder } from "../../../types";
import * as Yup from 'yup';
import { sendError, sendMessage } from "@util/helpers/notifications.helper";

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Folder name is required!'),
});

interface FormType {
  title: string;
}

interface Props {
  visible: boolean;
  onCancel: () => void;
}

export const AddFolderModal = ({ visible, onCancel }: Props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { currentOpenFolder } = useSelector(myWorkspaceSelector);

  const onSubmit = async ({ title }: FormType, { resetForm }: FormikHelpers<FormType>) => {
    setLoading(true);
    try {
      const parentFolder = currentOpenFolder?.id;

      const { data } = await api.post<WorkspaceFolder>('/workspaces/folders', { parentFolder, title })
      if (!data) throw new Error('Unable to create folder!');

      sendMessage('Folder created successfully!')

      dispatch(addWorkspaceFolder(data));
      resetForm();
      onCancel();
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik<FormType>({
    initialValues: { title: '' },
    onSubmit,
    validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add New Folder"
        visible={visible}
        onCancel={onCancel}
        okButtonProps={{ loading, onClick: () => formik.handleSubmit() }}
      >
        <div>
          <CustomInput
            name="title"
            formik={formik}
            placeholder="Folder name"
            onPressEnter={formik.submitForm}
          />
        </div>
      </Modal>
    </form>
  );
};
