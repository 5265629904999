import { configureStore } from "@reduxjs/toolkit";
import { authReducer as auth } from "@store/auth";
import { templateReducer as template } from "@store/template";
import { userReducer as user } from "@store/user";
import { userRequestReducer as userRequest } from "@store/user-request";
import { organizationsReducer as organization } from "@store/organization";
import { notificationsReducer as notification } from "@store/notification";
import { workspaceReducer as workspace } from "@store/workspace";
import { userLogReducer as log } from "@store/log-event";
import { beadlReducer as beadl } from "@store/beadl";
import beadlEditor from "@store/beadlEditor";
import beadlNodeModal from "@store/beadlNodeModal";
import beadlConfiguration from "@store/beadlConfiguration";

const store = configureStore({
  reducer: {
    auth,
    beadl,
    template,
    user,
    userRequest,
    notification,
    organization,
    workspace,
    log,
    beadlEditor,
    beadlNodeModal,
    beadlConfiguration,

  },
});

export type RootState = ReturnType<typeof store.getState>;
export default store;
