import { ReactElement } from 'react';
import { get } from 'lodash';
import { Checkbox } from 'antd';
import { CommonInputProps } from './types';

export function CustomCheckbox<T>({
  formik, name, placeholder, customCheckboxStyle,
}: CommonInputProps<T>): ReactElement {
  const value = get(formik.values, name) as boolean;

  return (
    <Checkbox
      style={customCheckboxStyle}
      checked={value}
      onChange={(): void => formik.setFieldValue(name, !value)}
    >
      <span style={{ fontSize: '14px' }}>
        {placeholder}
      </span>
    </Checkbox>
  );
}
