import { OrganizationForm, UserOrganization } from '../../../../../types';
import { Button } from 'antd'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { CustomInput, CustomTextarea } from '@components/FormFields';
import * as Yup from 'yup';
import { api } from '../../../../../api';
import { sendError, sendMessage } from '@util/helpers/notifications.helper';
import { selectAllOrganizations, updateOrganization } from '@store/organization';

const validationSchema = Yup.object({
  title: Yup.string().required('Title is required!'),
});

interface Props {
  onCancel: () => void;
}

export const UpdateForm = ({ onCancel }: Props) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { selectedOrganization } = useSelector(selectAllOrganizations);

  const onSubmit = async (input: OrganizationForm) => {
    if (!selectedOrganization?.id) return;
    setLoading(true);

    try {
      const { data } = await api.put<UserOrganization>(`/organizations/${selectedOrganization.id}`, input);
      if (!data) throw new Error('Something went wrong!');

      dispatch(updateOrganization(data))
      formik.resetForm();

      sendMessage('Organization updated successfully!');
      onCancel();
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  };

  const initialValues: OrganizationForm = {
    title: selectedOrganization?.title || '',
    description: selectedOrganization?.description || '',
  };

  const formik = useFormik<OrganizationForm>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit
  });

  const cancelAndReset = () => {
    formik.resetForm();
    onCancel();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='flex flex-wrap justify-between gap-4 px-2 pr-10'>
      <div className='flex flex-col gap-1.5 w-full'>
          <label className='text-xs font-light' htmlFor="title">Title</label>
          <CustomInput formik={formik} name="title" placeholder="Title" />
        </div>
        <div className='w-2/4' />

        <div className='flex flex-col w-full gap-2'>
          <label className='text-xs font-light' htmlFor="description">Description</label>
          <CustomTextarea formik={formik} name="description" placeholder="Description" />
        </div>

        <div className='w-2/4' />
      </div>

      <div className='flex justify-between w-full px-2 pt-10'>
        <Button type="ghost" onClick={cancelAndReset} className="w-2/5 btn-br-black">
          Cancel
        </Button>
        <Button loading={loading} type="ghost" htmlType="submit" className="w-2/5 btn-br-black">
          Save changes
        </Button>
      </div>
    </form>
  )
}