import { ReactElement } from 'react';
import { notification } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import { get } from 'lodash';

export const logger = (err: unknown): void => {
  if (process.env.NODE_ENV !== 'production') console.log(err);
};

export const sendError = (err: string | unknown): void => {
  if (typeof err === 'string') return notification.error({ message: err });

  const networkMessage = get(err, 'response.data.message') as unknown as string;

  const message = networkMessage
    ? networkMessage
    : get(err, 'message', 'Something went wrong!') as string;

  logger(err);

  return notification.error({ message });
};

type Msg = string | ReactElement;

export const sendMessage = (message: Msg, description?: Msg, duration?: number | null): void => {
  const args: ArgsProps = { message, duration };
  if (description) args.description = description;

  notification.success(args);
};
