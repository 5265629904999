import { Notification } from '../../types';
import { createReducer } from '@reduxjs/toolkit';
import { addNotification, deleteNotitication, deselectNotification, fetchNotifications, selectNotification, updateNotification } from './notification.actions';
import { uniqBy } from 'lodash';

type NotificationState = {
  pending: boolean;
  notifications: Notification[];
  selectedNotification: Notification | null;
  totalCount: number;
}

const initialState : NotificationState = {
  notifications: [],
  selectedNotification: null,
  pending: false,
  totalCount: 0
};

export const notificationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchNotifications.pending, (state) => {
      state.pending = true;
    })
    .addCase(fetchNotifications.fulfilled, (state, { payload }) => {
      state.notifications = payload.notifications;
      state.totalCount = payload.totalCount;
      state.pending = false;
    })
    .addCase(addNotification, (state, { payload }) => {
      state.notifications = uniqBy([...state.notifications, ...payload], 'id');
    })
    .addCase(updateNotification, (state, { payload }) => {
      state.notifications = state.notifications.map((t) => (
        t.id === payload.id ? { ...payload } : t
      ));
    })
    .addCase(selectNotification, (state, { payload }) => {
      state.selectedNotification = payload;
    })
    .addCase(deselectNotification, (state, { payload }) => {
      state.selectedNotification = null;
    })
    .addCase(deleteNotitication, (state, { payload }) => {
      state.notifications = state.notifications.filter((u) => u.id !== payload);
    });;
});
