import { CustomInput } from "@components/FormFields";
import { addWorkspaceFile, myWorkspaceSelector } from "@store/workspace";
import { Checkbox, Modal } from "antd";
import { api } from "../../../api";
import { FormikHelpers, useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { WorkspaceAddFileForm, WorkspaceFile } from "../../../types";
import * as Yup from 'yup';
import { sendError, sendMessage } from "@util/helpers/notifications.helper";
import { TemplatesTable } from "@components/index";

const validationSchema = Yup.object().shape({
  title: Yup.string().required('File name is required!'),
});

interface Props {
  visible: boolean;
  onCancel: () => void;
}

export const AddFileModal = ({ visible, onCancel }: Props) => {
  const [showTemplates, setShowTemplates] = useState(false);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { currentOpenFolder } = useSelector(myWorkspaceSelector);

  const onClose = (cb?: () => void) => {
    onCancel();
    setShowTemplates(false);
    if (cb) cb();
  };

  const onSubmit = async ({ title, template_id }: WorkspaceAddFileForm, { resetForm }: FormikHelpers<WorkspaceAddFileForm>) => {
    setLoading(true);
    try {
      const folder = currentOpenFolder?.id;

      const { data } = await api.post<WorkspaceFile>('/workspaces/files', { folder, title, template_id })
      if (!data) throw new Error('Unable to create folder!');

      sendMessage('File created successfully!');

      dispatch(addWorkspaceFile(data));
      onClose(resetForm);
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik<WorkspaceAddFileForm>({
    initialValues: { title: '', template_id: '' },
    onSubmit,
    validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Add New File"
        className="cs_add-file-modal"
        visible={visible}
        onCancel={() => onClose()}
        okButtonProps={{ loading, onClick: () => formik.handleSubmit() }}
        width="60vw"
      >
        <div className="flex flex-col">
          <CustomInput
            name="title"
            formik={formik}
            placeholder="File name"
            onPressEnter={formik.submitForm}
          />

          <Checkbox checked={showTemplates} onChange={() => setShowTemplates(!showTemplates)}>
            Use template
          </Checkbox>

          {showTemplates && (
            <div className="flex flex-wrap gap-5 mt-2.5 justify-center cs_add-file-modal--table">
              <TemplatesTable
                setSelectedTemplate={(id) => formik.setFieldValue('template_id', id)}
                selectedTemplate={formik.values.template_id}
              />
            </div>
          )}
        </div>
      </Modal>
    </form>
  );
};
