import { useState } from "react";
import { Button } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { IResetPasswordType } from "types";
import { sendError, sendMessage } from "@util/helpers/notifications.helper";
import { api } from "../../api";
import { withoutAuth } from "../../hocs";
import { CustomInput } from "@components/FormFields";
import { IRoutes } from "../../config";

const validationSchema = Yup.object({
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      'Password must contain number, uppercase, lowercase, one special character. Password must be at least 8 characters long!',
    ).required('Password is required'),
  rpassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords do not match!')
    .required('Confirm password is required!'),
});

const ResetPasswordPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [ loading, setLoading ] = useState(false);

  const onSubmit = async ({ password }: IResetPasswordType) => {
    setLoading(true);

    try {
      const { data } = await api.post('/auth/reset-password', { password, token });
      if (!data ) throw new Error('Something went wrong!');

      formik.resetForm();
      sendMessage('Password reset successfully!')
      navigate(IRoutes.Login);
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  }

  const formik = useFormik<IResetPasswordType>({
    initialValues: { password: '', rpassword: '' },
    validationSchema,
    onSubmit
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex items-center justify-center h-screen bg-indigo-100">
        <div className="w-2/3 lg:w-2/5 md:w-1/2">
          <div className="min-w-full p-10 bg-white shadow-lg">
              <h1 className="mb-6 text-2xl font-bold text-center text-gray-600">Reset Password</h1>
              <div>
                <label className="block my-3 font-semibold text-left text-gray-800 text-md">Password</label>
                <CustomInput type='password' formik={formik} name="password" placeholder="Password" />
              </div>
              <div>
                <label className="block my-3 font-semibold text-left text-gray-800 text-md">Repeat Password</label>
                <CustomInput type='password' formik={formik} name="rpassword" placeholder="Confirm Password" />
              </div>
              <Button
                htmlType="submit"
                loading={loading}
                type="primary"
                className="mt-6 font-semibold tracking-wide text-white cs_input"
              >
                Submit
              </Button>
          </div>
        </div>
      </div>
    </form>
  );
};


export default withoutAuth(ResetPasswordPage);
