import { FlowElement, XYPosition } from "react-flow-renderer";
import { createBeadlState } from "@util/constructor/beadlState";
import { BeadlState } from "@customTypes/model/beadlEditor/beadlState";

export const createFlowNode = (
  argBeadlState?: BeadlState,
  argPosition?: XYPosition
): FlowElement<BeadlState> => {
  const beadlState: BeadlState = argBeadlState || createBeadlState();
  const position: XYPosition = argPosition || {
    x: 10,
    y: 10,
  };
  return {
    id: beadlState.name,
    type: "beadl",
    data: beadlState,
    position,
  };
};
