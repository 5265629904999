import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StateNameProps } from "@customTypes/components/BeadlEditor/BeadlNode/StateName";
import {
  bottomSolidBlackBorder,
  defaultText,
  rightSolidBlackBorder,
} from "@styles/global";
import Focusable from "@components/core/Focusable";
import Input from "@components/core/Input";
import BeadlHandle from "@components/BeadlEditor/BeadlHandle";
import { Position } from "react-flow-renderer";

const StyledFocusable = styled(Focusable)`
  grid-area: state-name;
  ${defaultText};
  ${rightSolidBlackBorder};
  ${bottomSolidBlackBorder};
  border-bottom-style: dashed;
  font-size: 18px;
`;

const StateName = ({ disabled, value = "", onValueChange }: StateNameProps) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <StyledFocusable
      className={`bg-white text-center align-middle font-bold relative`}
      disabled={disabled}
      whenActive={
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          label="State Name: "
        />
      }
      whenInactive={value}
      onDeactivated={() => onValueChange && onValueChange(inputValue)}
      onActivated={() => setInputValue(value)}
    >
      <BeadlHandle type="target" position={Position.Left} id="a" />
    </StyledFocusable>
  );
};

export default StateName;
