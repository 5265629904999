import { Notification, NotificationUpdateForm, NotificationType } from '../../../../../types';
import { Button, Checkbox } from 'antd'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { api } from '../../../../../api';
import { sendError, sendMessage } from '@util/helpers/notifications.helper';
import { selectAllNotifications, updateNotification } from '@store/notification';

const validationSchema = Yup.object().shape({
  notification_types: Yup.array(Yup.string()).min(1, 'Atleast 1 notification type required!'),
});
interface Props {
  onCancel: () => void;
}

export const UpdateForm = ({ onCancel }: Props) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { selectedNotification } = useSelector(selectAllNotifications);

  const onSubmit = async (input: NotificationUpdateForm) => {
    if (!selectedNotification?.id || !selectedNotification.user.id) return;
    setLoading(true);

    try {
      const { data } = await api.put<Notification>(`/notifications/${selectedNotification?.id}`, input);
      if (!data) throw new Error('Something went wrong!');

      dispatch(updateNotification(data))
      formik.resetForm();

      sendMessage('Notification updated successfully!');
      onCancel();
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  };

  const initialValues: NotificationUpdateForm = {
    notification_types: selectedNotification?.notification_types || [],
    user: selectedNotification?.user.id || ''
  };

  const formik = useFormik<NotificationUpdateForm>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit
  });

  const cancelAndReset = () => {
    formik.resetForm();
    onCancel();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='flex flex-wrap justify-between gap-4 px-2 pr-10'>

      <div className='flex flex-col w-full gap-2'>
          <label className='text-xs font-light' htmlFor="description">Notification type</label>
            <Checkbox.Group
              options={Object.keys(NotificationType).map((n) => ({
                label: n.replaceAll('_', ' '),
                  value: n,
                })
              )
              }
              value={formik.values.notification_types}
              onChange={(newValues) => {
                formik.setFieldValue('notification_types', newValues);
                }
              }
            />
            {formik.errors.notification_types && <div className="error-message">{formik.errors.notification_types}</div>}
        </div>


        <div className='w-2/4' />
      </div>

      <div className='flex justify-between w-full px-2 pt-10'>
        <Button type="ghost" onClick={cancelAndReset} className="w-2/5 btn-br-black">
          Cancel
        </Button>
        <Button loading={loading} type="ghost" htmlType="submit" className="w-2/5 btn-br-black">
          Save changes
        </Button>
      </div>
    </form>
  )
}