import React from 'react';
import { Link, useLocation } from 'react-router-dom'
import { AppstoreOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';

type MenuItem = Required<MenuProps>['items'][number];

const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const menuItems = [
  {
    label: 'Users',
    route: '/admin',
    icon: <UserOutlined />
  },
  {
    label: 'Organizations',
    route: '/organizations',
    icon: <AppstoreOutlined />
  },
  {
    label: 'Templates',
    route: '/templates',
    icon: <AppstoreOutlined />
  },
  {
    label: 'Notifications',
    route: '/notifications',
    icon: <NotificationOutlined />
  },
  {
    label: 'User Requests',
    route: '/requests',
    icon: <UserOutlined />
  },
]

export const SuperAdminActions = () => {
  const location = useLocation();


  const items = menuItems.map((m) => (
    getItem(<Link to={m.route} className='text-black'>{m.label}</Link>, m.route, m.icon)
  ));

  const selectedItem = menuItems.find((i) => i.route === (location.pathname as string));

  return (
    <>
      <div className="px-5 py-0 mt-8">
        <Menu
          mode="inline"
          defaultSelectedKeys={[selectedItem ? selectedItem.route : '']}
          items={items}
          className="cs_menu"
        />
      </div>
    </>
  )
};
