import Select from "@components/core/Select";
import {
  InteractionComponentOption,
  InteractionComponentSelectorProps,
} from "@customTypes/components/selectors/InteractionComponentSelector";
import { ConnectionMappingState } from "@customTypes/store/beadlConfiguration";
import { beadlSelector } from "@store/beadl";
import { RootState } from "@store/index";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { itemIdentifier } from "../util";

const InteractionComponentSelector = (
  props: InteractionComponentSelectorProps
) => {
  const {
    selected,
    onChange,
    placeholder = "Input Event",
    options,
    className,
    virtualEntityComponentKey,
    isVirtual = false,
    connection,
  } = props;

  const connectionMappingState = useSelector<RootState, ConnectionMappingState>(
    (store) => store.beadlConfiguration.connectionMapping
  );

  const {
    virtualActions: { data: virtualActions },
    virtualActionComponents: { data: virtualActionComponents },
    virtualEvents: { data: virtualEvents },
    virtualEventComponents: { data: virtualEventComponents },
    resourceComponents: { data: resourceComponents },
  } = useSelector(beadlSelector);

  const handleChange = useCallback(
    (id: number, item?: InteractionComponentOption) => {
      onChange && onChange(id, item);
    },
    [onChange]
  );

  const labelRender = useCallback((item: InteractionComponentOption) => {
    return item?.name || ''
  }, []);

  const collection = useMemo(() => {
    if (connection === undefined) {
      return [] as InteractionComponentOption[];
    }
    if (!isVirtual) {
      if (connection.mapping_id === undefined) {
        return [] as InteractionComponentOption[];
      }

      return resourceComponents.filter((rc) => {
        const id = connectionMappingState.data[
          connectionMappingState.dataIndexMappedById![
            connection.mapping_id!
          ]
        ].resource_type_id
        if (!id) return false

        return rc.resourceTypes.includes(id)
      })
    } else {
      if (connection.virtual_entity_id === undefined) {
        return [] as InteractionComponentOption[];
      }
      if (virtualEntityComponentKey === "VIRTUAL_EVENT_COMPONENT") {
        return virtualEventComponents.filter((rc) => {
          const ids = virtualEvents.find((v) => v.id === connection.virtual_entity_id)?.virtualEventComponents || [];
          return ids.includes(rc.id)
        });
      } else if (virtualEntityComponentKey === "VIRTUAL_ACTION_COMPONENT") {
        return virtualActionComponents.filter((rc) => {
          const ids = virtualActions.find((v) => v.id === connection.virtual_entity_id)?.virtualActionComponents || [];
          return ids.includes(rc.id)
        });
      }
      return [] as InteractionComponentOption[];
    }
  }, [
    connection,
    connectionMappingState,
    isVirtual,
    resourceComponents,
    virtualEntityComponentKey,
    virtualActions,
    virtualEvents,
    virtualActionComponents,
    virtualEventComponents,
  ]);

  return (
    <Select
      className={className}
      options={options || collection}
      selected={selected}
      onChange={handleChange}
      placeholder={placeholder}
      labelRender={labelRender}
      itemIdentifier={(item) => itemIdentifier(item, "id")}
    />
  );
};

export default InteractionComponentSelector;
