import dayjs from 'dayjs';
import { useState } from "react";
import { Auth } from 'aws-amplify';
import { CognitoUser, User } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { firstTimeUserSelector, logoutUser, setFirstTimeUser, setUser } from "@store/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../api";
import { sendError } from "@util/helpers/notifications.helper";
import { IRoutes } from '../config';

interface LoginPayload {
  username: string;
  password: string;
}

interface FirstTimeLoginPayload {
  firstName: string;
  lastName: string;
  password: string;
  rPassword: string;
}

const lastLoginEventStorageKey = 'lastLogEventSent';

export const useSignIn = () => {
  const [waitAuthCheck, setWaitAuthCheck] = useState(true);
  const [loading, setLoading] = useState(false);

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const firstTimeUser = useSelector(firstTimeUserSelector);

  const setAndRedirectUser = async (res: CognitoUser) => {
    try {
      const lastLogEventSentString = localStorage.getItem('lastLogEventSent');

      const lastLogEventSent = lastLogEventSentString ? dayjs(lastLogEventSentString) : null;

      const today = dayjs().startOf('day');
      const logEvent = !lastLogEventSent || lastLogEventSent?.isBefore(today);

      if (logEvent) {
        localStorage.setItem(lastLoginEventStorageKey, dayjs().toISOString());
      }

      const { payload } = res.signInUserSession.idToken;
      const { data: user } = await api.get<User>('/auth', { params: { logEvent } });
      if (!user.id || payload.sub !== user.id) throw new Error('Something went wrong!');

      dispatch(setUser(user));

      const isRootUrl = pathname === '/';

      if (user.group === 'Admin') {
        navigate(isRootUrl ? '/admin' : pathname);
      } else {
        navigate(isRootUrl ? '/workspace': pathname);
      }
    } catch(err) {
      onLogout();
    }
  };

  const onLogout = async () => {
    setLoading(true);
    localStorage.removeItem(lastLoginEventStorageKey);

    try {
      await Auth.signOut();
    } catch(err) {
      sendError(err);
    } finally {
      dispatch(logoutUser());
      setLoading(false);
      const isLogoutUrl = pathname.toLowerCase() === IRoutes.Logout;
      navigate(isLogoutUrl ? IRoutes.Home : pathname);
    }
  };

  const onLogin = async (values: LoginPayload) => {
    setLoading(true);

    try {
      const res = await Auth.signIn(values) as CognitoUser;

      if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch(setFirstTimeUser(res));
        return;
      }

      if (!res?.signInUserSession?.idToken?.payload?.sub) throw new Error('Something went wrong!');

      setAndRedirectUser(res);
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  };

  const onFirstTimeLogin = async (values: FirstTimeLoginPayload) => {
    setLoading(true);

    try {
      if (!firstTimeUser) throw new Error();

      const res = await Auth.completeNewPassword(
        firstTimeUser, values.password, { family_name: values.lastName, given_name: values.firstName }
      ) as CognitoUser;

      if (!res?.signInUserSession?.idToken?.payload?.sub) throw new Error('Something went wrong!');

      setAndRedirectUser(res);
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  };

  const onAutoLogin = async () => {
    try {
      const res = await Auth.currentAuthenticatedUser() as CognitoUser;

      if (res?.signInUserSession?.idToken?.payload?.sub) {
        await setAndRedirectUser(res);
      }
    } catch (err) {
      await onLogout();
    } finally {
      setWaitAuthCheck(false);
    }
  };

  return { loading, waitAuthCheck, onLogin, onFirstTimeLogin, onAutoLogin, onLogout };
};
