import { useEffect, useState } from "react";
import styled from "styled-components";
import { DurationTextProps } from "@customTypes/components/BeadlEditor/BeadlNode/DurationText";
import {
  bottomSolidBlackBorder,
  defaultText,
  rightSolidBlackBorder,
} from "@styles/global";
import Focusable from "@components/core/Focusable";
import Input from "@components/core/Input";

const StyledFocusable = styled(Focusable)`
  grid-area: duration-text;
  ${defaultText};
  font-size: 14px;
  ${rightSolidBlackBorder};
  ${bottomSolidBlackBorder};
`;

const DurationText = ({
  value = "",
  onValueChange,
  disabled,
}: DurationTextProps) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <StyledFocusable
      className="bg-white text-center align-middle pl-1"
      disabled={disabled}
      whenActive={
        <Input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          label="max_duration"
        />
      }
      whenInactive={`max_duration: ${value}`}
      onDeactivated={() => onValueChange && onValueChange(inputValue)}
      onActivated={() => setInputValue(value)}
    ></StyledFocusable>
  );
};

export default DurationText;
