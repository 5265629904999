import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { OutputActionProps } from "@customTypes/components/BeadlEditor/BeadlNode/OutputAction";
import { RootState } from "@store/index";
import ActionSelector from "@components/selectors/ActionSelector";
import { BeadlAction } from "@customTypes/model/beadlConfiguration/actionDefinition";
import Focusable from "@components/core/Focusable";
import styled from "styled-components";
import { FocusableProps } from "@customTypes/components/core/Focusable";
import ResourceComponentValue from "@components/ResourceComponentValue";

const StyledFocusable = styled(Focusable)<FocusableProps>`
  &:last-child {
    border-bottom: none;
  }
`;

const OutputAction = (props: OutputActionProps = {}) => {
  const actions = useSelector<RootState, BeadlAction[]>(
    (state) => state.beadlConfiguration.actionDefinition.data
  );
  const { disabled, action, onChange } = props;
  const selectedAction = useMemo(
    () => actions.find((item) => item.id === action?.action_id),
    [actions, action]
  );

  return (
    <StyledFocusable
      disabled={disabled}
      className="text-center align-middle border-b"
      whenActive={
        <div className="flex flex-col flex-nowrap">
          <ActionSelector
            label="Action"
            selected={action?.action_id as unknown as number}
            onChange={(id) =>
              onChange &&
              onChange({
                ...action,
                action_id: id as unknown as string,
              })
            }
          />
          <ResourceComponentValue
            componentId={selectedAction?.connection?.component_id}
            value={action?.value}
            onChange={(value) =>
              onChange &&
              onChange({
                ...action!,
                value,
              })
            }
            virtualComponentId={
              selectedAction?.connection?.virtual_entity_component_id
            }
          />
        </div>
      }
      whenInactive={
        <div className="w-full">
          {selectedAction
            ? `${selectedAction?.name} ${action?.value || ""}`
            : "- -"}
        </div>
      }
    />
  );
};

export default OutputAction;
