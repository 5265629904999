import Select from "@components/core/Select";
import { ResourceTypeSelectorProps } from "@customTypes/components/selectors/ResourceTypeSelector";
import { RootState } from "@store/index";

import { beadlSelector, ResourceType } from "@store/beadl";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { itemIdentifier, renderLabel } from "../util";

const ResourceTypeSelector = (props: ResourceTypeSelectorProps) => {
  const {
    selected,
    onChange,
    placeholder = "Input Event",
    options,
    className,
  } = props;

  const { resourceTypes: { data } } = useSelector(beadlSelector);
  const hardwareId = useSelector<RootState>(
    (state) => state.beadlConfiguration.protocolSettings.hardwareId,
  );

  const handleChange = useCallback(
    (id: number, item?: ResourceType) => {
      onChange && onChange(id, item);
    },
    [onChange]
  );

  const resourceTypes = data.filter((rt) => rt.hardware === hardwareId);

  return (
    <Select
      className={className}
      options={options || resourceTypes}
      selected={selected}
      onChange={handleChange}
      placeholder={placeholder}
      labelRender={(item) => renderLabel(item, "name")}
      itemIdentifier={(item) => itemIdentifier(item, "id")}
    />
  );
};

export default ResourceTypeSelector;
