import Select from "@components/core/Select";
import {
  InteractionConnectionOption,
  InteractionConnectionSelectorProps,
} from "@customTypes/components/selectors/InteractionConnectionSelector";
import { ConnectionMappingState } from "@customTypes/store/beadlConfiguration";
import { beadlSelector, VirtualEntity } from "@store/beadl";
import { RootState } from "@store/index";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { itemIdentifier } from "../util";

const InteractionConnectionSelector = (
  props: InteractionConnectionSelectorProps
) => {
  const {
    selected,
    onChange,
    placeholder = "Input Event",
    options,
    className,
    virtualEntityKey,
  } = props;

  const connectionMappingState = useSelector<RootState, ConnectionMappingState>(
    (store) => store.beadlConfiguration.connectionMapping
  );
  const { nonRepeatingData: nonRepeatingConnectionMappings } = connectionMappingState;

  const {
    virtualActions: { data: virtualActions },
    virtualEvents: { data: virtualEvents },
  } = useSelector(beadlSelector);

  const virtualEntityData: VirtualEntity[] = virtualEntityKey === 'virtualEvent' ? virtualEvents : virtualActions;

  const collection = useMemo(() => {
    let array: InteractionConnectionOption[] = [];
    array = array
      .concat(nonRepeatingConnectionMappings)
      .concat(virtualEntityData);

    return (options || array).map((item) => ({ ...item, id: (item as VirtualEntity)?.xmlNodeName ? item.id.toString() as unknown as number : item.id }));
  }, [nonRepeatingConnectionMappings, options, virtualEntityData]);

  const handleChange = useCallback(
    (id, item) => {
      onChange && onChange(id, item);
    },
    [onChange]
  );

  const labelRender = useCallback((item: InteractionConnectionOption) => {
    return item?.name || ''
  }, []);

  return (
    <Select
      className={className}
      options={collection}
      selected={selected}
      onChange={handleChange}
      placeholder={placeholder}
      // @ts-ignore
      labelRender={labelRender}
      itemIdentifier={(item) => itemIdentifier(item, "id")}
    />
  );
};

export default InteractionConnectionSelector;
