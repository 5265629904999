import { UserRequest } from '../../types';
import { createReducer } from '@reduxjs/toolkit';
import { deletedUserRequests, fetchAllUserRequests, updateUserRequest } from './user-request.actions';

type UserRequestsState = {
  pending: boolean;
  userRequests: UserRequest[];
  totalCount: number;
}

const initialState : UserRequestsState = {
  userRequests: [],
  totalCount: 0,
  pending: false,
};

export const userRequestReducer = createReducer(initialState, (builder) => {
  builder
  .addCase(fetchAllUserRequests.pending, (state) => {
    state.pending = true;
  })
  .addCase(fetchAllUserRequests.fulfilled, (state, { payload }) => {
    state.userRequests = payload.userRequests;
    state.totalCount = payload.totalCount || 0;
    state.pending = false;
  })
  .addCase(fetchAllUserRequests.rejected, (state) => {
    state.pending = false;
  })
  .addCase(updateUserRequest, (state, { payload }) => {
    state.userRequests = state.userRequests.map((r) => (
      r.id === payload.id ? { ...payload } : r
    ));
  })
  .addCase(deletedUserRequests, (state, { payload }) => {
    state.userRequests = state.userRequests.filter(r => !payload.includes(r.id))
  });
});
