import { withAuth } from '../../hocs'
import { DashboardLayout } from '../../layouts/Dashboard.layout'
import { MyProfile, ProfileActions,  } from './components';

const UserProfile = () => {
  return (
    <DashboardLayout
      navbarTitle="My Profile"
      leftSidebar={<ProfileActions />}
      mainComponent={<MyProfile />}
      hideRightSidebar
    />
  )
}

export default withAuth(UserProfile);
