import { ReactElement, KeyboardEvent } from 'react';
import { get } from 'lodash';
import { Input } from 'antd';
import { TextAreaRef } from 'antd/lib/input/TextArea';
import { CommonInputProps } from './types';

interface P<T> extends CommonInputProps<T> {
  maxLength?: number;
  narrow?: boolean;
  description?: boolean;
  autoFocus?: boolean;
  onPressEnter?: (e: KeyboardEvent<HTMLTextAreaElement>) => void;
  ref?: React.Ref<TextAreaRef>;
  rows?: number;
}

export function CustomTextarea<T>({
  formik,
  name,
  placeholder,
  maxLength,
  narrow,
  label,
  disabled,
  onPressEnter,
  className = '',
  autoFocus,
  ref,
  rows,
}: P<T>): ReactElement {
  const error = get(formik.errors, name) as string;
  const invalid = (get(formik.touched, name, false) as boolean) && !!error;

  return (
      <Input.Group>
        <div className="flex flex-wrap items-center gap-1 cs_text-label">
          {label ? (
            <label className="text-base" htmlFor={name}>
              {label}
            </label>
          ) : null}

          <div className="flex-auto">
            <Input.TextArea
              rows={rows ? rows : 5}
              ref={ref}
              className={className}
              name={name}
              placeholder={placeholder}
              value={get(formik.values, name) as string}
              onChange={(e) => {
                const ev = {
                  target: { id: name, name, value: e.target.value },
                };
                formik.handleChange(ev);
              }}
              onBlur={formik.handleBlur}
              showCount={!!maxLength}
              maxLength={maxLength}
              onPressEnter={onPressEnter}
              disabled={disabled}
              autoFocus={autoFocus}
            />

            {invalid && <div className="error-message">{error}</div>}
          </div>
        </div>
      </Input.Group>
  );
}
