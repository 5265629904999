import { DrawerProps } from "@customTypes/components/core/Drawer";
import HeaderActions from "@components/core/Drawer/HeaderActions";
import AntDrawer from "antd/lib/drawer";

/**
 * Drawer component is used to show content in a different layer than the main app layer. This component accepts props
 * with the type `DrawerProps`.
 * @category @components/core
 * @param props - props to customize Drawer component
 * @returns {JSX.Element}
 */
export const Drawer = ({
  title = "Header",
  children,
  onClose,
  successAction,
  failAction,
  additionalActions,
  ...props
}: DrawerProps) => {
  return (
    <AntDrawer
      onClose={() => onClose && onClose()}
      extra={
        <HeaderActions
          successAction={successAction}
          failAction={failAction}
          additionalActions={additionalActions}
        />
      }
      title={title}
      {...props}
    >
      {children}
    </AntDrawer>
  );
};

export default Drawer;
