import AntFormItem from "antd/lib/form/FormItem";
import styled from "styled-components";

const FormItem = styled(AntFormItem)`
  .ant-form-item {
    /* &-explain {
      font-size: 10px;
    } */
  }
` as typeof AntFormItem;
export default FormItem;
