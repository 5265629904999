import { UserOutlined } from "@ant-design/icons";
import { userSelector } from "@store/auth";
import { Avatar, Dropdown, Menu, Typography } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { UserRole } from "../../types"

interface LinkType {
  id: number;
  label: string;
  link: string;
  roles: UserRole[];
}

const links: LinkType[] = [
  { id: 1, label: 'My Workspace', link: '/workspace', roles: ['Admin', 'User'] },
  { id: 2, label: 'My Profile', link: '/profile', roles: ['Admin', 'User'] },
  { id: 3, label: 'Admin', link: '/admin', roles: ['Admin'] },
  { id: 4, label: 'Logout', link: '/logout', roles: ['Admin', 'User'] },
];

interface Props {
  title: string;
}

export const Navbar = ({ title }: Props) => {
  const user = useSelector(userSelector);

  const items = links
    .filter((l) => user?.group && l.roles.includes(user.group))
    .map((l) => ({
      key: l.id, label: <Link to={l.link}>{l.label}</Link>
    }))

  return (
    <div className="flex justify-between w-full pt-5 pb-5 pl-20 pr-20">
      <p className='flex justify-center w-full text-3xl font-semibold text-center text-black opacity-100 mb-30'>
        {title}
      </p>

      <div className='flex flex-col items-end w-20'>
        <Dropdown overlay={<Menu items={items} />}>
          <div className="flex flex-col items-center">
            <Avatar icon={<UserOutlined />} />
            <Typography.Text>
              {user?.username}
            </Typography.Text>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};
