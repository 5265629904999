import { css } from "styled-components";

export const fullWidth = css`
  width: 100%;
`;

export const fullHeight = css`
  height: 100%;
`;

export const defaultText = css`
  padding: 4px;
`;

export const topSolidBlackBorder = css`
  border-top: 1px solid black;
`;

export const rightSolidBlackBorder = css`
  border-right: 1px solid black;
`;

export const bottomSolidBlackBorder = css`
  border-bottom: 1px solid black;
`;

export const leftSolidBlackBorder = css`
  border-left: 1px solid black;
`;
