import {
  ChangeEvent, FocusEvent, ReactElement,
} from 'react';
import { get } from 'lodash';
import { Input } from 'antd';
import { CommonInputProps } from './types';

export interface CustomInputProps<T> extends CommonInputProps<T> {
  customOnBlur?: (value: string) => void;
  customError?: string;
  narrow?: boolean;
  label?: ReactElement;
  className?: string | '';
  noBorderInput?: boolean;
  borderNone?: boolean;
  isPhoneParser?: boolean;
  onPressEnter?: () => void;
}

export function CustomInput<T>({
  formik, name, placeholder, customOnBlur, label,
  disabled, isPhoneParser, onPressEnter,
  customError = '',
  type = 'text',
  className = '',
}: CustomInputProps<T>): ReactElement {
  const error = get(formik.errors, name, customError) as string;
  const invalid = get(formik.touched, name, false) as boolean && !!error;
  const value = get(formik.values, name) as string;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isPhoneParser) {
      formik.handleChange(e);
      return;
    }

    const newValue = e.target.value.split('')
      .map((x) => parseInt(x, 10))
      .filter((x) => x >= 0)
      .map((x, i) => {
        if (i === 2 || i === 5) return `${x}-`;
        if (i < 10) return x;
        return null;
      })
      .filter((x) => ['string', 'number'].includes(typeof x))
      .join('');

    formik.handleChange({ target: { id: name, name, value: newValue } });
  };

  const onFocusChange = (e: FocusEvent<HTMLInputElement>) => {
    formik.handleBlur(e);
    if (customOnBlur) customOnBlur(e.target.value);
  };


  return (
    <>
      <Input.Group>
        <div className='items-center'>
          {label ? (
            <div>
              <label htmlFor={name}>{label}</label>
            </div>
          ) : null}
          <div className='flex-auto'>
            <Input
              id={name}
              className={`
                ${className}
                custom-input
              `}
              disabled={disabled}
              name={name}
              placeholder={placeholder}
              type={type}
              value={get(formik.values, name) as string}
              onChange={onChange}
              onBlur={onFocusChange}
              onPressEnter={onPressEnter}
              onKeyDown={(e) => {
                if (e.key === 'Backspace' && isPhoneParser) {
                  const lastChar = value.toString().slice(-1);

                  if (lastChar === '-') {
                    e.preventDefault();
                    formik.setFieldValue(name, value.slice(0, -1));
                  }
                }
              }}
            />
          </div>
        </div>
      </Input.Group>
      {invalid && <div className="error-message">{error}</div>}
    </>
  );
}
