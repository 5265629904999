import { FocusableProps } from "@customTypes/components/core/Focusable";
import { MouseEvent, useCallback, useState } from "react";
import styled from "styled-components";

const StyledContainer = styled.div`
  min-height: 32px;
`;

const Focusable = ({
  disabled = false,
  initialActive = false,
  whenActive,
  whenInactive,
  onDeactivated,
  onActivated,
  className = "",
  children,
}: FocusableProps) => {
  const [active, setActive] = useState<boolean>(initialActive);
  const handleActiveChange = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (disabled) {
        return;
      }
      event.stopPropagation();
      event.preventDefault();
      if (active) {
        onDeactivated && onDeactivated();
      } else {
        onActivated && onActivated();
      }
      setActive(!active);
    },
    [active, disabled, onActivated, onDeactivated]
  );
  return (
    <StyledContainer
      className={`relative ${
        typeof className === "function" ? className(active) : className
      }`}
      onDoubleClick={handleActiveChange}
    >
      {active ? whenActive : whenInactive}
      {children}
    </StyledContainer>
  );
};

export default Focusable;
