import { v4 as uuid } from 'uuid';
import Input from "@components/core/Input";
import Table from "@components/core/Table";
import InteractionComponentSelector from "@components/selectors/InteractionComponentSelector";
import InteractionConnectionSelector from "@components/selectors/InteractionConnectionSelector";
import { Column } from "@customTypes/components/core/Table";
import { BeadlEvent } from "@customTypes/model/beadlConfiguration/eventDefinition";

import { VirtualEntity } from "@store/beadl";
import {
  addNewEventDefinition,
  deleteEventDefinition,
  updateEventDefinition,
} from "@store/beadlConfiguration";
import { RootState } from "@store/index";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomTooltip } from '@components/CustomTooltip';
import { workspaceTooltip } from "../../../tooltip.text";
import { FlowElement } from 'react-flow-renderer';
import { BeadlState } from "@customTypes/model/beadlEditor/beadlState";
import { message } from 'antd';

const EventDefinitionTable = () => {
  const dispatch = useDispatch();
  const eventDefinitions = useSelector<RootState, BeadlEvent[]>(
    (state) => state.beadlConfiguration.eventDefinition.data
  );

  const flowElements = useSelector<RootState>(
    (state) => state.beadlEditor.tabNameDataMap[state.beadlEditor.activeTab].flowElements
  ) as FlowElement<BeadlState>[];

  const handleInteractionConnectionChange = useCallback(
    (connection, item: BeadlEvent) => {
      const payload = { ...item };

      payload.connection = {
        component_id: undefined,
        mapping_id: undefined,
        virtual_entity_id: undefined,
        virtual_entity_component_id: undefined,
      };

      if ((connection as VirtualEntity).xmlNodeName) {
        payload.connection.virtual_entity_id = +connection.id;
      } else {
        payload.connection.mapping_id = connection.id;
      }

      dispatch(updateEventDefinition(payload));
    },
    [dispatch]
  );

  const handleInteractionComponentChange = useCallback(
    (component_id: number, item: BeadlEvent) => {
      const isVirtual = item.connection?.virtual_entity_id !== undefined

      const payload = {
        ...item,
        connection: {
          ...item.connection,
          component_id: isVirtual ? undefined : component_id,
          virtual_entity_component_id: isVirtual ? component_id : undefined,
        },
      };
      dispatch(updateEventDefinition(payload));
    },
    [dispatch]
  );

  const handleItemCreate = useCallback(() => {
    const item: BeadlEvent = {
      id: uuid(),
      name: "New Item",
      comment: "",
      type: "",
      connection: {},
    };
    dispatch(addNewEventDefinition(item));
  }, [dispatch]);

  const handleItemDelete = useCallback(
    (_, { id }) => {
      try {
        dispatch(deleteEventDefinition({ flowElements, id }));
      } catch (err) {
        message.error((err as Error)?.message);
      }
    },
    [dispatch, flowElements]
  );

  const handleItemDuplicate = useCallback(
    (_: number, item: BeadlEvent) => {
      dispatch(addNewEventDefinition({ ...item, id: uuid() }));
    },
    [dispatch]
  );

  const columns: Column<BeadlEvent>[] = useMemo(() => {
    return [
      {
        title: () => ( <CustomTooltip title="Event Name" tooltipText={workspaceTooltip.events.eventName} />),
        dataIndex: "name",
        render: (columnName: string, item: BeadlEvent) => {
          return (
            <Input
              value={item.name}
              className="borderless_input"
              onChange={(e) => dispatch(updateEventDefinition({ ...item, name: e.target.value }))}
            />
          );
        },
      },
      {
        title: () => ( <CustomTooltip title="Connection" tooltipText={workspaceTooltip.events.connection} />),
        dataIndex: "connection",
        render: (_: string, item: BeadlEvent) => (
          <div className="grid grid-cols-2 space-x-2">
            <InteractionConnectionSelector
              onChange={(_, connection) => handleInteractionConnectionChange(connection, item)}
              selected={
                item.connection?.mapping_id === undefined
                  ? item.connection?.virtual_entity_id?.toString() as unknown as number
                  : item.connection?.mapping_id as unknown as number
              }
              virtualEntityKey={"virtualEvent"}
            />
            {item.connection && (
              <InteractionComponentSelector
                virtualEntityComponentKey={"VIRTUAL_EVENT_COMPONENT"}
                connection={item.connection}
                onChange={(componentId: number) => handleInteractionComponentChange(componentId, item)}
                isVirtual={item.connection.virtual_entity_id !== undefined}
                selected={
                  item.connection.virtual_entity_id === undefined
                    ? item.connection.component_id
                    : item.connection.virtual_entity_component_id
                }
              />
            )}
          </div>
        ),
      },
      {
        title: () => ( <CustomTooltip title="Type" tooltipText={workspaceTooltip.events.type} />),
        dataIndex: "connection",
        render: (columnName: string, item: BeadlEvent) => {
          return (
            <Input
              value={item.type}
              className="borderless_input"
              onChange={(e) => dispatch(updateEventDefinition({ ...item, type: e.target.value }))}
            />
          );
        },
      },
      {
        title: () => ( <CustomTooltip title="Comment" tooltipText={workspaceTooltip.events.comment} />),
        dataIndex: "comment",
        render: (columnName: string, item: BeadlEvent) => {
          return (
            <Input
              value={item.comment}
              className="borderless_input"
              onChange={(e) => dispatch(updateEventDefinition({ ...item, comment: e.target.value }))}
            />
          );
        },
      },
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleInteractionComponentChange,
    handleInteractionConnectionChange,
  ]);
  return (
    <Table
      columns={columns}
      data={eventDefinitions}
      actionConfig={{
        add: {
          enabled: true,
          action: handleItemCreate,
        },
        delete: {
          enabled: true,
          action: handleItemDelete,
        },
        duplicate: {
          enabled: true,
          action: handleItemDuplicate,
        },
      }}
    />
  );
};

export default EventDefinitionTable;
