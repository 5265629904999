import { updateUserRequest } from '@store/user-request';
import { sendError, sendMessage } from '@util/helpers/notifications.helper';
import { Button, Modal, Typography } from 'antd'
import { api } from '../../../../../api';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApproveUserRequest, UserRequest } from 'types';
import { CustomInput, CustomOrganizations } from '@components/FormFields';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  organization: Yup.string().required('Organization is required!'),
});

interface Props {
  visible: boolean;
  onCancel: () => void;
  userRequest: UserRequest;
}

export const ApproveRequest = ({ visible, onCancel, userRequest }: Props) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = async (input: ApproveUserRequest) => {
    if(!userRequest) return;
    setLoading(true);

    try {
      const { data } = await api.post<UserRequest>(`/user-requests/${userRequest.id}/approve`, input);
      if (!data) throw new Error();

      sendMessage('User request approved successfully!');

      dispatch(updateUserRequest({ ...data }));
      onCancel();
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  }

  const formik = useFormik<ApproveUserRequest>({
    initialValues: {
      username: userRequest.username,
      firstName: userRequest.firstName,
      lastName: userRequest.lastName,
      email: userRequest.email,
      organization: '',
      password: '',
    },
    validationSchema,
    onSubmit
  });

  const cancelAndReset = () => {
    formik.resetForm();
    onCancel();
  };

  if (!userRequest) return <div />;

  return (
    <Modal
      className='users-actions_modal'
      title={(
        <Typography.Text className='flex justify-center text-base font-bold'>
          Approve user request
        </Typography.Text>
      )}
      centered
      visible={visible}
      onCancel={cancelAndReset}
      footer={false}
      width={800}
    >
      <form onSubmit={formik.handleSubmit}>

        <div className="flex flex-wrap justify-between gap-4 px-2 pr-10">
        <div className="flex flex-col gap-1.5 w-2/5">
          <label className="text-xs font-light">First name</label>
          <CustomInput formik={formik} name='firstName' />
        </div>

        <div className="flex flex-col gap-1.5 w-2/5">
          <label className="text-xs font-light">Last name</label>
          <CustomInput formik={formik} name='lastName' />
        </div>

        <div className="flex flex-col gap-1.5 w-2/5">
          <label className="text-xs font-light">Username</label>
          <CustomInput formik={formik} name='username' />
        </div>

        <div className="flex flex-col gap-1.5 w-2/5">
          <label className="text-xs font-light">Password</label>
          <CustomInput formik={formik} name='password' />
        </div>

        <div className="flex flex-col gap-1.5 w-2/5">
          <label className="text-xs font-light">Email</label>
          <CustomInput formik={formik} name='email' />
        </div>

        <div className="flex flex-col gap-1.5 w-2/5">
          <label className="text-xs font-light">Select organization</label>
          {`User's Choice: ${userRequest.organization}`}
          <CustomOrganizations
            formik={formik}
            name="organization"
            placeholder="Organization"
          />
        </div>

        <div className="w-full" />
      </div>

      <div className="flex justify-between w-full px-2 pt-10">
        <Button
          type="ghost"
          onClick={cancelAndReset}
          className="w-2/5 btn-br-black"
        >
          Cancel
        </Button>
        <Button
          loading={loading}
          htmlType="submit"
          type="primary"
          className="w-2/5 btn-br-black"
        >
          Approve
        </Button>
      </div>
      </form>
    </Modal>
  )
}