import { Auth } from 'aws-amplify';
import axios, { AxiosRequestConfig } from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || '',
});

const reqHandling = async (config: AxiosRequestConfig) => {
  try {
    const obj = { ...config };

    const session = await Auth.currentSession();
    if (!session.isValid()) return obj;

    const accessToken = session.getAccessToken();
    const authToken = accessToken.getJwtToken();
    if (!authToken) return obj;

    const headers = { ...config.headers || {} } as Record<string, string>;

    obj.headers = { ...headers, Authorization: `Bearer ${authToken}` };

    return obj;
  } catch (err) { return config; }
};

api.interceptors.request.use(reqHandling);
