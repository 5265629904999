import { useDispatch, useSelector } from 'react-redux';
import { Button, Image, Popconfirm, Radio, Switch, Table, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { useComponentDidMount } from '../../hooks';
import { noImageData, Template } from '../../types';
import { ColumnsType } from 'antd/lib/table';
import { api } from '../../api';
import { useState } from 'react';
import { sendError, sendMessage } from '@util/helpers/notifications.helper';
import {
  deleteTemplate,
  deselectTemplate,
  fetchAllTemplates,
  selectAllTemplatesData,
  selectTemplate,
  updateTemplate,
} from '@store/template';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { AddUpdateForm } from '@pages/Admin/AdminTemplates/components/TemplateActions/AddUpdateForm.component';

interface AdminColumnProps {
  updatingTemplateId: string;
  updateTemplateStatus: (template: Template) => void;
  handleEditTemplate: (template: Template) => void;
  deleteAction: (template: Template) => void;
  loading: boolean;
}

interface CommonColumnProps {
  setSelectedTemplate?: (id: string) => void;
  selectedTemplate: string;
}

interface ColumnProps extends AdminColumnProps, CommonColumnProps {}

const commonColumns = [
  {
    title: 'Title',
    dataIndex: 'title',
    width: '15%'
  },
  {
    title: 'Image',
    dataIndex: 'image',
    render: (image: string) => (
      <Image
        src={image}
        width={200}
        height={100}
        alt="default-image"
        rootClassName="cs_preview-img"
        fallback={noImageData}
      />
    ),
  },
  {
    title: 'Description',
    dataIndex: 'description',
  },
];

const userSelectionColumn = ({
  selectedTemplate,
  setSelectedTemplate,
}: CommonColumnProps) => {
  if (!setSelectedTemplate) return [];

  return [
    {
      title: 'Select',
      dataIndex: 'id',
      render: (id: string) => (
        <Radio
          checked={selectedTemplate === id}
          onChange={() => setSelectedTemplate(id)}
        />
      ),
    },
  ];
};

const adminColumns = ({
  updatingTemplateId,
  updateTemplateStatus,
  handleEditTemplate,
  deleteAction,
  loading,
}: AdminColumnProps) => {
  return [
    {
      title: 'Create date',
      dataIndex: 'createdAt',
      render: (createdAt: string) => dayjs(createdAt).format('ll'),
    },
    {
      title: 'Update date',
      dataIndex: 'updatedAt',
      render: (udpatedAt: string) => dayjs(udpatedAt).format('ll'),
    },
    {
      title: 'Action',
      render: (template: Template) => (
        <div className="flex items-center gap-2.5">
          <Switch
            checked={template.isActive}
            loading={updatingTemplateId === template.id}
            onChange={() => updateTemplateStatus(template)}
          />

          <Tooltip placement="topLeft" title="Edit template">
            <Button
              icon={<EditOutlined />}
              onClick={() => handleEditTemplate(template)}
              className="mt-1 btn-default_icons"
            />
          </Tooltip>

          <Tooltip placement="topLeft" title="Delete template">
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() => deleteAction(template)}
              okText="Yes"
              cancelText="No"
            >
              <Button loading={loading} className="btn-default_icons">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];
};

const columns = ({
  updatingTemplateId,
  updateTemplateStatus,
  handleEditTemplate,
  deleteAction,
  loading,
  setSelectedTemplate,
  selectedTemplate,
}: ColumnProps): ColumnsType<Template> => [
  ...userSelectionColumn({ selectedTemplate, setSelectedTemplate }),
  ...commonColumns,
  ...(!setSelectedTemplate
    ? adminColumns({
      updatingTemplateId,
      updateTemplateStatus,
      handleEditTemplate,
      deleteAction,
      loading,
    })
    : []),
];

interface TTProps {
  setSelectedTemplate?: (id: string) => void;
  selectedTemplate?: string;
}

export const TemplatesTable = ({
  setSelectedTemplate,
  selectedTemplate = '',
}: TTProps) => {
  const dispatch = useDispatch();
  const [updatingTemplateId, setUpdatingTemplateId] = useState('');
  const [visibleAddEdit, setVisibleAddEdit] = useState(false);
  const [loading, setLoading] = useState(false);

  const { templates } = useSelector(selectAllTemplatesData);

  useComponentDidMount(() => dispatch(fetchAllTemplates()));

  const updateTemplateStatus = async (template: Template) => {
    setUpdatingTemplateId(template.id);

    try {
      const status = !template.isActive;
      const { data } = await api.patch<Template>(
        `/templates/${template.id}/status`,
        { status }
      );
      if (!data) throw new Error();

      sendMessage('Status updated successfully!');

      dispatch(updateTemplate(data));
    } catch (err) {
      sendError(err);
    } finally {
      setUpdatingTemplateId('');
    }
  };

  const handleAddTemplate = () => {
    dispatch(deselectTemplate());
    setVisibleAddEdit(true);
  };

  const handleEditTemplate = (template: Template) => {
    dispatch(selectTemplate(template));
    setVisibleAddEdit(true);
  };

  const onCancel = (fn: (val: boolean) => void) => {
    dispatch(deselectTemplate());
    fn(false);
  };

  const deleteAction = async (template: Template) => {
    setLoading(true);
    try {
      const { status } = await api.delete(`/templates/${template.id}`);
      if (status !== 200) throw new Error();

      sendMessage('Template deleted successfully!');

      dispatch(deleteTemplate(template?.id || ''));
      dispatch(deselectTemplate());
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  };

  const activeTemplates = setSelectedTemplate ? templates.filter((t) => t.isActive) : templates;

  return (
    <>
      {!setSelectedTemplate && (
        <div className="flex justify-start">
          <Button onClick={handleAddTemplate}>Add template</Button>
        </div>
      )}

      <Table
        rowKey="id"
        dataSource={activeTemplates}
        columns={columns({
          updatingTemplateId,
          updateTemplateStatus,
          deleteAction,
          handleEditTemplate,
          loading,
          selectedTemplate,
          setSelectedTemplate,
        })}
        pagination={false}
      />

      {!setSelectedTemplate && (
        <AddUpdateForm
          visible={visibleAddEdit}
          onCancel={() => onCancel(setVisibleAddEdit)}
        />
      )}
    </>
  );
};
