import { BeadlConfigurationState } from "@customTypes/store/beadlConfiguration";
import { BeadlEditorData } from "@customTypes/store/beadlEditor";
import { CombinedExportState } from "@customTypes/util";
import { setActionDefinitions, setArgumentDefinitions, setConnectionMappings, setEventDefinitions, setProtocolSettings } from "@store/beadlConfiguration";
import { setTabData } from "@store/beadlEditor";
import { BEADL_STATE, COMBINED, CONFIGURATIONS } from "../config";
import { useDispatch } from "react-redux";
import { sendError } from "@util/helpers/notifications.helper";

export const useJsonDataToStore = (ioFile = COMBINED) => {
  const dispatch = useDispatch();

  const setJsonDataIntoStore = async (json: unknown) => {
    try {
      let setConfigurations = false;
      let setEditorState = false;
      let beadlConfiguration: BeadlConfigurationState;
      let beadlEditorData: BeadlEditorData;
      if (ioFile === COMBINED) {
        beadlConfiguration = (json as CombinedExportState).beadlConfiguration;
        beadlEditorData = (json as CombinedExportState).beadlEditorData;
        setConfigurations = true;
        setEditorState = true;
      } else if (ioFile === CONFIGURATIONS) {
        setConfigurations = true;
        beadlConfiguration = json as unknown as BeadlConfigurationState;
      } else if (ioFile === BEADL_STATE) {
        setEditorState = true;
        beadlEditorData = json as unknown as BeadlEditorData;
      }
      if (setConfigurations) {
        if (
          !(
            beadlConfiguration!.connectionMapping.data &&
            beadlConfiguration!.eventDefinition.data &&
            beadlConfiguration!.actionDefinition.data &&
            beadlConfiguration!.argumentDefinition.data &&
            beadlConfiguration!.protocolSettings
          )
        ) {
          throw new Error("Malformed editor data!");
        }
        dispatch(
          setConnectionMappings(beadlConfiguration!.connectionMapping.data)
        );
        dispatch(
          setEventDefinitions(beadlConfiguration!.eventDefinition.data)
        );
        dispatch(
          setActionDefinitions(beadlConfiguration!.actionDefinition.data)
        );
        dispatch(
          setArgumentDefinitions(beadlConfiguration!.argumentDefinition.data)
        );
        dispatch(setProtocolSettings(beadlConfiguration!.protocolSettings));
      }

      if (setEditorState) {
        if (
          !(
            beadlEditorData!.stateNameIndexMap &&
            beadlEditorData!.flowElements
          )
        ) {
          throw new Error("Malformed editor data!");
        }
        dispatch(setTabData({ tabData: beadlEditorData! }));
      }
    } catch (error) {
      sendError(error);
    }
  };

  return { setJsonDataIntoStore };
};
