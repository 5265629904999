import { useEffect, useMemo, useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ActionDefinitionTable from "@components/BeadlConfiguration/ActionDefinitionTable";
import BeadlArgumentTable from "@components/BeadlConfiguration/ArgumentDefinitionTable";
import ConnectionMappingTable from "@components/BeadlConfiguration/ConnectionMappingTable";
import EventDefinitionTable from "@components/BeadlConfiguration/EventDefinitionTable";
import Tabs from "@components/core/Tabs";
import { TabConfig } from "@customTypes/components/core/Tabs";
import { BeadlConfigurationState } from "@customTypes/store/beadlConfiguration";
import BeadlEditor from "@components/BeadlEditor";
import { RootState } from "@store/index";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import LeftSiderContent from "@components/Main/LeftSiderContent";
import RightSiderContent from "@components/Main/RightSiderContent";
import Title from "antd/lib/typography/Title";
import { withAuth } from "../../hocs";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../api";
import axios from "axios";
import { useJsonDataToStore, usePrompt } from "../../hooks";
import { sendError } from "@util/helpers/notifications.helper";
import { beadlSelector, fetchAllBeadlResources } from "@store/beadl";
import { DashboardLayout } from "../../layouts/Dashboard.layout";

const tabs: TabConfig[] = [
  { key: "Argument Definition" },
  { key: "Connection Mappings" },
  { key: "Actions" },
  { key: "Events" },
  { key: "BAABL Editor" },
];

const TabsWrapper = styled.div`
  .ant-tabs {
    flex: 1;
    flex-shrink: 0;
    &-content {
      height: 100%;
    }
  }
`;

const antIcon = <LoadingOutlined spin />;

const Main = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setJsonDataIntoStore } = useJsonDataToStore();

  const beadlConfiguration = useSelector<RootState, BeadlConfigurationState>(
    (state) => state.beadlConfiguration
  );

  const beadl = useSelector(beadlSelector);

  const selectedHardwareId = beadlConfiguration.protocolSettings.hardwareId;
  const [loadingFile, setLoadingFile] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs[0].key);

  const tabConfigs = useMemo(() => {
    const configs = tabs;
    configs[configs.length - 2].disabled = configs[
      configs.length - 3
    ].disabled = beadlConfiguration.connectionMapping.data.length === 0;
    configs[configs.length - 1].disabled =
      beadlConfiguration.actionDefinition.data.length === 0 ||
      beadlConfiguration.eventDefinition.data.length === 0 ||
      beadlConfiguration.connectionMapping.data.length === 0;
    return configs;
  }, [beadlConfiguration]);

  const editorActionsDisabled =
    activeTab !== tabConfigs[tabConfigs.length - 1].key;

  useEffect(() => {
    const fetchMyWorkspaceFile = async () => {
      setLoadingFile(true);
      try {
        const { data } = await api.get<string>(`/uploads/${id}`);
        if (!data) throw new Error();

        const { data: jsonData } = await axios.get(data);
        if (!jsonData) throw new Error();

        setJsonDataIntoStore(jsonData);

        dispatch(fetchAllBeadlResources());
      } catch (err) {
        sendError(err);
        navigate('/workspace');
      } finally {
        setLoadingFile(false);
      }
    }

    if (id) fetchMyWorkspaceFile();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const isReady = !(beadl.loading || selectedHardwareId === undefined || loadingFile);

  // usePrompt('Please make sure you save your changes!', true);

  return (
    <DashboardLayout
      navbarTitle="BAABL Designer"
      leftSidebar={<LeftSiderContent />}
      rightSidebar={<RightSiderContent actionsDisabled={editorActionsDisabled} />}
      mainComponent={(
        <TabsWrapper
          className={`h-full w-full flex flex-col ${
            !isReady
              ? "justify-center"
              : "px-2 py-0 sm:px-0 flex-1 flex-shrink-0"
          }`}
        >
          {isReady ? (
            <div className="cs_tabs">
              <Tabs
                activeKey={activeTab}
                onChange={setActiveTab}
                tabConfigs={tabConfigs}
                components={[
                  <BeadlArgumentTable />,
                  <ConnectionMappingTable />,
                  <ActionDefinitionTable />,
                  <EventDefinitionTable />,
                  <BeadlEditor />,
                ]}
              />
              {/* <Prompt /> */}
            </div>
          ) : beadl.loading ? (
            <Spin size="large" indicator={antIcon} />
          ) : (
            <Title>Choose a hardware first!</Title>
          )}
        </TabsWrapper>
      )}
    />
  )
};

export default withAuth(Main);
