/* eslint-disable @typescript-eslint/ban-ts-comment */
import { ReactElement, useState } from 'react';
import ImgCrop from 'antd-img-crop';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Button, Col, Image } from 'antd';
import { CommonInputProps } from './types';
import { get } from 'lodash';
import { onPublicUpload, onUpload } from '@util/helpers/onUpload.helper';
import { acceptImageExt, acceptJSONExt } from '../../types';

interface UploadFileMetaData {
  name: string;
  fileKey: string;
}

interface CustomUploadProps<T> extends CommonInputProps<T> {
  accept?: string;
  btnClassName?: string;
  setCustomLoading?: (loadingStatus: boolean) => void;
  customButton?: ReactElement;
  doNotShowUploadList?: boolean;
  customOnSuccess?: (data: UploadFileMetaData) => void;
  acceptRatio?: [number, number];
  isPublic?: boolean;
  existingImagePath?: string
}

export function CustomUploader<T>({
  placeholder, setCustomLoading, customOnSuccess, btnClassName,
  formik, name, customButton,
  accept = acceptJSONExt,
  doNotShowUploadList = false,
  acceptRatio = [1, 1],
  isPublic = false,
  existingImagePath,
}: CustomUploadProps<T>): ReactElement {
  const [localFile, setLocalFile] = useState(false)
  const error = get(formik.errors, name) as string;
  const invalid = get(formik.touched, name, false) as boolean && !!error;

  const renderImage = () => {
    if (existingImagePath && !localFile) {
      return (
        <div className='p-2 border h-1.04xl'>
          <Image className="" src={existingImagePath} width={86} height={86} placeholder rootClassName="cs_preview-img" />
        </div>
      )
    }

    return <></>
  }

  const onCustomRequest = async (opt: any): Promise<void> => {
    if (setCustomLoading) setCustomLoading(true);
    setLocalFile(true)

    try {
      const { res, fileName, fileKey } = isPublic
        ? await onPublicUpload(opt.file as File, opt.onProgress)
        : await onUpload(opt.file as File, opt.onProgress);

      // @ts-ignore
      if (opt.onSuccess) opt.onSuccess(null, res);
      if (setCustomLoading) setCustomLoading(false);

      const metaData: UploadFileMetaData = { name: fileName, fileKey };

      if (customOnSuccess) customOnSuccess(metaData);
      else formik.setFieldValue(name, fileKey);
    } catch (err) {
      // @ts-ignore
      if (opt.onError) opt.onError(err);
      setLocalFile(false)
    } finally {
      if (setCustomLoading) setCustomLoading(false);
    }
  }

  return (
    <div className='flex gap-2.5 cs_uploader-main'>
        {renderImage()}

        {accept === acceptImageExt ? (
          <ImgCrop aspect={acceptRatio[0] / acceptRatio[1]}>
            <Upload
              accept={accept}
              showUploadList={!doNotShowUploadList}
              onRemove={() => formik.setFieldValue(name, '')}
              customRequest={onCustomRequest}
              listType="picture-card"
              maxCount={1}
              // defaultFileList={existingImagePath}
            >
              {customButton || (
                <Button htmlType="button" className={btnClassName}>
                  <UploadOutlined />

                  {placeholder}
                </Button>
              )}
            </Upload>
        </ImgCrop>
        ) : (
          <Upload
              accept={accept}
              showUploadList={!doNotShowUploadList}
              onRemove={() => formik.setFieldValue(name, '')}
              customRequest={onCustomRequest}
            >
              {customButton || (
                <Button htmlType="button" className={btnClassName}>
                  <UploadOutlined />

                  {placeholder}
                </Button>
              )}
            </Upload>
        )}

      {invalid && <Col className="error-message">{error}</Col>}
    </div>
  );
}
