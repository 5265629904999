import { User, UserRequest } from '../../types';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';
import { sendError } from '@util/helpers/notifications.helper';
import { IPaginationVars } from 'types/pagination.types';

interface Response {
  userRequests: UserRequest[];
  totalCount: number;
  limit: number;
  offset: number;
}

export const fetchAllUserRequests = createAsyncThunk<Response, { payload: IPaginationVars }>(
  'userRequest/allRequests',
  async ({ payload }) => {
  try {
    const { data } = await api.get<Response>('/user-requests', { params: payload });

    return data;
  } catch (err) {
    sendError(err);
    throw err;
  }
});

export const approveUserRequest = createAction<User | null>('userRequest/approve');
export const rejectUserRequest = createAction<User | null>('userRequest/reject');
export const updateUserRequest = createAction<UserRequest>('userRequest/update');
export const deletedUserRequests = createAction<string[]>('userRequests/deleted');
