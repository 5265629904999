import { fetchMyWorkspace, updateWorkspaceItemByKeyboard } from '@store/workspace';
import { useComponentDidMount, useComponentDidUnmount } from '../../hooks';
import { useDispatch } from 'react-redux';
import { DashboardLayout } from '../../layouts/Dashboard.layout';
import { withAuth } from '../../hocs';
import { ActionSidebar, FileDetail, WorkspaceList } from './components';
import { WorkspaceSupportedKeyboardKeys } from '../../types';
import "./Workspace.styles.css"

const Workspace = () => {
  const dispatch = useDispatch();

  useComponentDidMount(() => dispatch(fetchMyWorkspace()));

  const onKeyPress = (e: KeyboardEvent) => {
    const pressedKey = e.key as WorkspaceSupportedKeyboardKeys;
    if (!Object.values(WorkspaceSupportedKeyboardKeys).includes(pressedKey)) return;

    dispatch(updateWorkspaceItemByKeyboard(pressedKey));
  };

  useComponentDidMount(() => document.addEventListener('keyup', onKeyPress));
  useComponentDidUnmount(() => document.removeEventListener('keyup', onKeyPress));

  return (
    <DashboardLayout
      navbarTitle='BAABL User Workspace'
      leftSidebar={<ActionSidebar />}
      mainComponent={<WorkspaceList />}
      rightSidebar={<FileDetail />}
    />
  )
}

export default withAuth(Workspace);
