import { LogEvent, UserLogType } from '../../types';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';
import { sendError } from '@util/helpers/notifications.helper';
import { IPaginationVars } from '../../types/pagination.types';

interface Response {
  logs: LogEvent[];
  totalCount: number;
  limit: number;
  offset: number;
}

export const fetchAllUserLogs = createAsyncThunk<Response, { user_id: string, params: IPaginationVars }>(
  'log-events/allUserLogs',
  async ({ user_id, params }) => {
  try {
    const { data } = await api.get<Response>(
      `/log-events/user/${user_id}`,
      { params : { ...params, type: UserLogType.LoginEvent } },
    );

    return data;
  } catch (err) {
    sendError(err);
    throw err;
  }
});

export const clearAllUserLogs = createAction('log-events/clearAllUserLogs');
