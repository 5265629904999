import { withAuth } from '../../../hocs'
import { DashboardLayout } from '../../../layouts/Dashboard.layout'
import { SuperAdminActions } from '../components/SuperAdminActions.component'
import { OrganizationsTable } from './components/OrganizationsTable.component'

const AdminOrganizations = () => {
  return (
    <DashboardLayout
      navbarTitle="Organizations"
      leftSidebar={<SuperAdminActions />}
      mainComponent={<OrganizationsTable />}
      hideRightSidebar
    />
  )
}

export default withAuth(AdminOrganizations, ['Admin']);
