import { v4 as uuid } from "uuid";
import Input from "@components/core/Input";
import Table from "@components/core/Table";
import ArgumentTypeSelector from "@components/selectors/ArgumentTypeSelector";
import { Column } from "@customTypes/components/core/Table";
import { BeadlArgument } from "@customTypes/model/beadlConfiguration/argumentDefinition";
import {
  addNewArgumentDefinition,
  deleteArgumentDefinition,
  updateArgumentDefinitionById,
} from "@store/beadlConfiguration";
import { RootState } from "@store/index";
import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlowElement } from "react-flow-renderer";
import { BeadlState } from "@customTypes/model/beadlEditor/beadlState";
import { message } from "antd";
import { CustomTooltip } from '@components/CustomTooltip';
import { workspaceTooltip } from "../../../tooltip.text";
import ExpressionTypeSelector from "@components/selectors/ExpressionTypeSelector";

const BeadlArgumentTable = () => {
  const dispatch = useDispatch();

  const beadlArgumentRows = useSelector<RootState, BeadlArgument[]>(
    (state) => state.beadlConfiguration.argumentDefinition.data
  );
  const flowElements = useSelector<RootState>(
    (state) => state.beadlEditor.tabNameDataMap[state.beadlEditor.activeTab].flowElements
  ) as FlowElement<BeadlState>[];

  const handleItemCreate = useCallback(() => {
    const item: BeadlArgument = {
      id: uuid(),
      name: "New Item",
      comment: "New Comment",
      value: "",
      type: 4, // for other
    };
    dispatch(addNewArgumentDefinition(item));
  }, [dispatch]);

  const handleItemDelete = useCallback((_: number, item: BeadlArgument) => {
    try {
      dispatch(deleteArgumentDefinition({ item, flowElements }));
    } catch (err) {
      message.error((err as Error)?.message);
    }
  }, [dispatch, flowElements]);

  const handleItemDuplicate = useCallback(
    (_: number, itemWithId: BeadlArgument) => {
      const { id, ...item } = itemWithId;
      dispatch(addNewArgumentDefinition({ ...item, id: uuid() }));
    },
    [dispatch]
  );

  const columns: Column<BeadlArgument>[] = useMemo(() => {
    return [
      {
        title: () => ( <CustomTooltip title="Argument Name" tooltipText={workspaceTooltip.argumentDefinition.argumentName} />),
        dataIndex: "name",
        render: (_: string, item: BeadlArgument) => {
          return (
            <Input
              value={item.name}
              className="borderless_input"
              onChange={(e) =>
                dispatch(updateArgumentDefinitionById({ ...item, name: e.target.value }))
              }
            />
          );
        },
      },
      {
        title: () => ( <CustomTooltip title="Expression" tooltipText={workspaceTooltip.argumentDefinition.expression} />),
        dataIndex: "value",
        render: (_: string, item: BeadlArgument) => (
          <Input
            value={item.value}
            className="borderless_input"
            onChange={(e) =>
              dispatch(updateArgumentDefinitionById({ ...item, value: e.target.value }))
            }
          />
        ),
      },
      {
        title: () => ( <CustomTooltip title="Expression Type" tooltipText={workspaceTooltip.argumentDefinition.expressionType} />),
        dataIndex: "expressionType",
        render: (_: string, item: BeadlArgument) => {
          return (
            <ExpressionTypeSelector
              className="w-full"
              selected={item.expressionType}
              onChange={(expressionType: number) => dispatch(updateArgumentDefinitionById({ ...item, expressionType }))}
              allowClear
              disabled={!item.value}
            />
          );
        },
      },
      {
        title: () => ( <CustomTooltip title="Comment" tooltipText={workspaceTooltip.argumentDefinition.comment} />),
        dataIndex: "comment",
        render: (_: string, item: BeadlArgument) => {
          return (
            <Input
              value={item.comment}
              className="borderless_input"
              onChange={(e) =>
                dispatch(updateArgumentDefinitionById({ ...item, comment: e.target.value }))
              }
            />
          );
        },
      },
      {
        title: () => ( <CustomTooltip title="Output Type" tooltipText={workspaceTooltip.argumentDefinition.outputType} />),
        dataIndex: "type",
        render: (_: string, item: BeadlArgument) => {
          return (
            <ArgumentTypeSelector
              className="w-full"
              selected={item.type}
              onChange={(type: number) => dispatch(updateArgumentDefinitionById({ ...item, type }))}
              allowClear
            />
          );
        },
      },
    ];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Table
      columns={columns}
      data={beadlArgumentRows}
      actionConfig={{
        add: {
          enabled: true,
          action: handleItemCreate,
        },
        delete: {
          enabled: true,
          action: handleItemDelete,
        },
        duplicate: {
          enabled: true,
          action: handleItemDuplicate,
        },
      }}
      scroll={{ y: 450 }}
    />
  );
};

export default BeadlArgumentTable;
