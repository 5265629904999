
import { ResourceComponent } from "@store/beadl";

export const getResourceComponent = (
  resourceComponents: ResourceComponent[] = [],
  resourceComponentIndexMappedById: Record<string, number> = {},
  resourceComponentId?: number
) => {
  if (
    resourceComponentId === undefined ||
    resourceComponentIndexMappedById[resourceComponentId] === undefined
  ) {
    return undefined;
  }
  return resourceComponents[
    resourceComponentIndexMappedById[resourceComponentId]
  ];
};
