import { FC, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { userSelector } from '@store/auth';

export const withoutAuth = <T extends Record<string, unknown>>(
  WrappedComponent: FC<T>,
): FC<T> => (
    (props: T): ReactElement => {
      const user = useSelector(userSelector);

      if (user?.id) {
        return <Navigate to={user.group === 'Admin' ? '/admin' : '/workspace'} />;
      }

      // eslint-disable-next-line react/jsx-props-no-spreading
      return <WrappedComponent {...props} />;
    }
  );
