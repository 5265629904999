import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NotificationType, NotificationForm, Notification } from '../../../../../types';
import { Button, Checkbox } from 'antd'
import { api } from '../../../../../api';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { sendError, sendMessage } from '@util/helpers/notifications.helper';
import "./AddUpdateForm.styles.css"
import { UsersSearchWithSelect } from '@components/FormFields';
import { fetchNotifications } from '@store/notification';
import { PAGINATION_LIMIT } from '../../../../../config';

const validationSchema = Yup.object().shape({
  users: Yup.array(Yup.string()).min(1, 'Atleast 1 user required!'),
  notification_types: Yup.array(Yup.string()).min(1, 'Atleast 1 notification type required!'),
});

interface Props {
  onCancel: () => void;
}

export const AddForm = ({ onCancel }: Props) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onSubmit = async (input: NotificationForm) => {
    setLoading(true);

    try {

      const { data } = await api.post<Notification[]>('/notifications', input);
      if (!data) throw new Error('');

      dispatch(fetchNotifications({ payload: { limit: PAGINATION_LIMIT, offset: 0 } }));

      formik.resetForm();
      sendMessage('Notification added successfully!');

      onCancel();
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  };

  const initialValues: NotificationForm = {
    users: [],
    notification_types: [],
  };

  const formik = useFormik<NotificationForm>({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit
  });

  const cancelAndReset = () => {
    formik.resetForm();
    onCancel();
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='flex flex-wrap justify-between gap-4 px-2 pr-10'>
        <div className='flex flex-col gap-1.5 w-full'>
          <label className='text-xs font-light' htmlFor="title">Users</label>
          <UsersSearchWithSelect formik={formik} name="users" />
        </div>
        <div className='w-2/4' />

        <div className='flex flex-col w-full gap-2'>
          <label className='text-xs font-light' htmlFor="description">Notification types</label>
          <Checkbox.Group
            options={Object.keys(NotificationType).map((n) => ({
              label: n.replaceAll('_', ' '),
                value: n,
              })
            )}
            value={formik.values.notification_types}
            onChange={(newValues) => {
              formik.setFieldValue('notification_types', newValues);
            }}
          />
        </div>

      <div className='w-2/4' />
      </div>

      <div className='flex justify-between w-full px-2 pt-10'>
        <Button type="ghost" onClick={cancelAndReset} className="w-2/5 btn-br-black">
          Cancel
        </Button>
        <Button loading={loading} type="ghost" htmlType="submit" className='w-2/5 btn-br-black'>
          Add Notification
        </Button>
      </div>
    </form>
  )
}