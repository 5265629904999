import { withAuth } from '../../../hocs'
import { DashboardLayout } from '../../../layouts/Dashboard.layout'
import { SuperAdminActions } from '../components/SuperAdminActions.component'
import { UsersTable } from './components'

const AdminUsers = () => {
  return (
    <DashboardLayout
      navbarTitle="Users"
      leftSidebar={<SuperAdminActions />}
      mainComponent={<UsersTable />}
      hideRightSidebar
    />
  )
}

export default withAuth(AdminUsers, ['Admin']);
