import { InputEventProps } from "@customTypes/components/BeadlEditor/BeadlNode/InputEvent";
import Focusable from "@components/core/Focusable";
import { FocusableProps } from "@customTypes/components/core/Focusable";
import styled from "styled-components";
import Input from "@components/core/Input";
import { useMemo } from "react";
import EventSelector from "@components/selectors/EventSelector";
import { BeadlEvent } from "@customTypes/model/beadlConfiguration/eventDefinition";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import BeadlHandle from "@components/BeadlEditor/BeadlHandle";
import { Position } from "react-flow-renderer";
import { BeadlStateEvent } from "@customTypes/model/beadlEditor/beadlState/beadlStateEvent";
import {
  STATE_EXPIRED_EVENT_ID,
  STATE_EXPIRED_EVENT_NAME,
} from "@util/constants";
import ResourceComponentValue from "@components/ResourceComponentValue";
import { Col, Row } from "antd";

const StyledFocusable = styled(Focusable)<FocusableProps>`
  &:last-child {
    border-bottom: none;
  }
  & > .styled-focusable__inactive-event {
    min-height: 2rem;
  }
`;

const InputEvent = (props: InputEventProps) => {
  const events = useSelector<RootState, BeadlEvent[]>(
    (state) => state.beadlConfiguration.eventDefinition.data
  );
  const { disabled, beadlEvent, onChange, handleId } = props;

  const handleChange = (argumentEvent: BeadlStateEvent) => {
    onChange && onChange(argumentEvent);
  };

  const selectedEvent = useMemo(
    () => events.find((item) => item.id === beadlEvent?.event_id),
    [events, beadlEvent]
  );

  const isStateExpiredEvent = useMemo(
    () => (beadlEvent.event_id as unknown as number) === STATE_EXPIRED_EVENT_ID,
    [beadlEvent]
  );

  return (
    <StyledFocusable
      disabled={disabled}
      className="relative p-1 border-b last:border-none"
      whenActive={
        <>
          <Row className="mb-1" gutter={4} justify="start">
            <Col span={isStateExpiredEvent ? 24 : 16}>
              {isStateExpiredEvent ? (
                `${`${STATE_EXPIRED_EVENT_NAME}`} expire expiredd`
              ) : (
                <EventSelector
                  className="w-full"
                  label="Event"
                  selected={beadlEvent.event_id as unknown as number}
                  onChange={(event_id) =>
                    handleChange({ ...beadlEvent, event_id: event_id as unknown as string })
                  }
                />
              )}
            </Col>
            {!isStateExpiredEvent && (
              <Col span={8}>
                <ResourceComponentValue
                  className="w-full"
                  componentId={selectedEvent?.connection?.component_id}
                  value={beadlEvent?.value}
                  onChange={(value) =>
                    onChange &&
                    onChange({
                      ...beadlEvent!,
                      value,
                    })
                  }
                  virtualComponentId={
                    selectedEvent?.connection?.virtual_entity_component_id
                  }
                />
              </Col>
            )}
          </Row>
          <Row>
            <Col span={24}>
              <Input
                value={beadlEvent.grace_period}
                onChange={(eventArg) =>
                  handleChange({
                    ...beadlEvent,
                    grace_period: eventArg.target.value,
                  })
                }
                type="text"
                label="grace_period"
                defaultValue={beadlEvent.grace_period}
              />
            </Col>
          </Row>
        </>
      }
      whenInactive={
        <div className="styled-focusable__inactive-event">
          <span className="text-base">
            {selectedEvent
              ? `${selectedEvent.name} ${beadlEvent.value || ""}`
              : `${STATE_EXPIRED_EVENT_NAME} expired`}
          </span>
          <div className="absolute text-xs bottom-1 right-2">
            {beadlEvent.grace_period
              ? `grace_period: ${beadlEvent.grace_period}s`
              : ""}
          </div>
        </div>
      }
      // onDeactivated={onDeactivated}
    >
      <BeadlHandle type="source" position={Position.Left} id={handleId} />
    </StyledFocusable>
  );
};

export default InputEvent;
