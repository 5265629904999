import { TabsProps } from "@customTypes/components/core/Tabs";

import AntTabs from "antd/lib/tabs";

const { TabPane } = AntTabs;

const Tabs = (props: TabsProps) => {
  const { tabConfigs, components, defaultActiveKey, onChange } = props;
  return (
    <AntTabs defaultActiveKey={defaultActiveKey} onChange={onChange}>
      {tabConfigs.map(({ key, ...tabProps }, index) => (
        <TabPane tab={key} key={key} {...tabProps}>
          {components[index]}
        </TabPane>
      ))}
    </AntTabs>
  );
};

export default Tabs;
