import React, { ReactElement, useMemo, useRef, useState } from 'react';
import { sendError } from '@util/helpers/notifications.helper';
import { Select, Spin } from 'antd';
import type { SelectProps } from 'antd/es/select';
import { api } from '../../api';
import debounce from 'lodash/debounce';
import { SearchUserResponse } from 'types';
import { CommonInputProps } from './types';
import { get } from 'lodash';

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

function DebounceSelect<
  ValueType extends { label: React.ReactNode; value: string | number } = any,
>({ fetchOptions, debounceTimeout = 800, ...props }: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then(newOptions => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
    />
  );
}

// Usage of DebounceSelect
interface UserValue {
  label: string;
  value: string;
}
async function fetchUserList(search: string): Promise<UserValue[]> {
    try {
      const { data } = await api.get<SearchUserResponse>(`/notifications/users?search=${search}`);
      if (!data) throw new Error('');
      if(data.users.length > 0) {
        return data.users.map(
          (user) => ({
            label: `${user.username} - ${user.email}`,
            value: user.id,
          }),
        )
      }
      else return [];

    } catch (err) {
      sendError(err);
      return [];
    } finally { }

}

export interface CustomNotificationsProps<T> extends CommonInputProps<T> {
  customError?: string;
}

export function UsersSearchWithSelect<T>({
    formik, name,
    customError = '',
  }: CustomNotificationsProps<T>): ReactElement {

    const error = get(formik.errors, name, customError) as string;
    const invalid = get(formik.touched, name, false) as boolean && !!error;
    const value = get(formik.values, name);

  return (
    <>
      <DebounceSelect
        mode="multiple"
        value={value}
        placeholder="Search & select users"
        fetchOptions={fetchUserList}
        onChange={newValue => {
          formik.setFieldValue(name, (newValue as UserValue[]).map(v => v.value));
        }}
        style={{ width: '100%' }}
      />
      {invalid && <div className="error-message">{error}</div>}
    </>
  );
};
