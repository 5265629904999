import { selectLoggedInUser } from '@store/auth';
import { useSelector } from 'react-redux';

export const MyProfile = () => {
  const user = useSelector(selectLoggedInUser);

  if (!user) return <div />;

  return (

    <div className='flex flex-col w-full text-left'>
      <div className='flex flex-row p-2'>
        <div className='p-2 w-28'>First name :</div>
        <div className='p-2 text-base italic font-bold w-60'>{user.firstName}</div>
      </div>

      <div className='flex flex-row p-2'>
        <div className='p-2 w-28'>Last name :</div>
        <div className='p-2 text-base italic font-bold w-60'>{user.lastName}</div>
      </div>

      <div className='flex flex-row p-2'>
        <div className='p-2 w-28'>Username :</div>
        <div className='p-2 text-base italic font-bold w-60'>{user.username}</div>
      </div>

      <div className='flex flex-row p-2'>
        <div className='p-2 w-28'>Email :</div>
        <div className='p-2 text-base italic font-bold w-60'>{user.email}</div>
      </div>

      <div className='flex flex-row p-2'>
        <div className='p-2 w-28'>Organization :</div>
        <div className='p-2 text-base italic font-bold w-60'>{user?.organization?.title}</div>
      </div>
    </div>
  )
}
