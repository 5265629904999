import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../api';
import { sendError } from '@util/helpers/notifications.helper';
import { BeadlAPIResponse } from './beadl.types';

export const fetchAllBeadlResources = createAsyncThunk<BeadlAPIResponse>('beadl/allUsers', async () => {
  try {
    const { data } = await api.get<BeadlAPIResponse>('/beadl');
    if (!data) throw new Error('Something went wrong!');

    return data;
  } catch (err) {
    sendError(err);
    throw err;
  }
});
