import { BeadlEvent } from "@customTypes/model/beadlConfiguration/eventDefinition";
import { BeadlConfigurationState } from "@customTypes/store/beadlConfiguration";
import { EventDefinitionState } from "@customTypes/store/beadlConfiguration";
import { PayloadAction } from "@reduxjs/toolkit";
import { getDataIndexMappedByField } from "@store/util";
import { stringToNumber } from "@util/helpers/stringToNumber.helper";
import { FlowElement } from "react-flow-renderer";
import { BeadlState } from "@customTypes/model/beadlEditor/beadlState";

export const eventDefinitionState: EventDefinitionState = {
  data: [],
  dataIndexMappedById: {},
};

export const eventDefinitionReducers = {
  setEventDefinitions: (
    state: BeadlConfigurationState,
    action: PayloadAction<BeadlEvent[]>
  ) => {
    const { payload } = action;
    const dataIndexMappedById = getDataIndexMappedByField(payload, "id");

    return {
      ...state,
      eventDefinition: {
        data: payload.map((d) => ({
          ...d,
          connection: { ...d.connection || {}, component_id: stringToNumber(d?.connection?.component_id) },
        })),
        dataIndexMappedById,
      },
    };
  },

  updateEventDefinition: (
    state: BeadlConfigurationState,
    { payload }: PayloadAction<BeadlEvent>
  ) => {
    const data = state.eventDefinition.data.map((d) => (d.id !== payload.id ? d : payload));

    return {
      ...state,
      eventDefinition: {
        ...state.eventDefinition,
        data,
      },
    };
  },

  addNewEventDefinition: (
    state: BeadlConfigurationState,
    { payload }: PayloadAction<BeadlEvent>
  ) => {
    const data = [...state.eventDefinition.data, payload];

    const dataIndexMappedById = getDataIndexMappedByField(data, "id");

    return {
      ...state,
      eventDefinition: {
        data,
        dataIndexMappedById,
      },
    };
  },

  deleteEventDefinition: (
    state: BeadlConfigurationState,
    { payload }: PayloadAction<{ flowElements: FlowElement<BeadlState>[]; id: string }>
  ) => {
    const { id, flowElements } = payload;

    const argumentInUse = flowElements.some((fe) => fe?.data?.events?.some((e) => e.event_id === id));
    if (argumentInUse) throw new Error('Event is in use and cannot be deleted. Please unlink to delete it!');

    const data = state.eventDefinition.data.filter((d) => d.id !== id);

    const dataIndexMappedById = getDataIndexMappedByField(data, "id");

    return {
      ...state,
      eventDefinition: {
        data,
        dataIndexMappedById,
      },
    };
  },
};
