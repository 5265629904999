import { CustomInput } from "@components/FormFields";
import { addWorkspaceFile } from "@store/workspace";
import { Modal } from "antd";
import { api } from "../../../api";
import { FormikHelpers, useFormik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { WorkspaceFile } from "../../../types";
import * as Yup from 'yup';
import { sendError, sendMessage } from "@util/helpers/notifications.helper";

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Folder name is required!'),
});

interface FormType {
  title: string;
}

interface Props {
  visible: boolean;
  item: WorkspaceFile;
  onCancel: () => void;
}

export const DuplicateFileModal = ({ visible, item, onCancel }: Props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const onSubmit = async ({ title }: FormType, { resetForm }: FormikHelpers<FormType>) => {
    if (!item?.id || !item?.s3Key) return;

    setLoading(true);

    try {
      const { data } = await api.post<WorkspaceFile>(`/workspaces/duplicate/file/${item.id}`, { title })
      if (!data) throw new Error('Unable to duplicate file!');

      dispatch(addWorkspaceFile(data));
      sendMessage('File duplicated successfully!');

      resetForm();
      onCancel();
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik<FormType>({
    initialValues: { title: '' },
    onSubmit,
    validationSchema,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Modal
        title="Duplicate File"
        visible={visible}
        onCancel={onCancel}
        okButtonProps={{ loading, onClick: () => formik.handleSubmit() }}
      >
        <div>
          <CustomInput
            name="title"
            formik={formik}
            placeholder="New file name"
            onPressEnter={formik.submitForm}
          />
        </div>
      </Modal>
    </form>
  );
};
