import { withAuth } from '../../../hocs'
import { DashboardLayout } from '../../../layouts/Dashboard.layout'
import "./AdminTemplateActions.css";
import { SuperAdminActions } from '../components/SuperAdminActions.component';
import { TemplatesTable } from '@components/index';

const AdminTemplates = () => {
  return (
    <DashboardLayout
      navbarTitle="Templates"
      leftSidebar={<SuperAdminActions />}
      mainComponent={<TemplatesTable />}
      hideRightSidebar
    />
  )
}

export default withAuth(AdminTemplates, ['Admin']);
