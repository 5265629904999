import { LogEvent } from '../../types';
import { createReducer } from '@reduxjs/toolkit';
import { clearAllUserLogs, fetchAllUserLogs } from './log-event.actions';

type AllUserLogs = {
  pending: boolean;
  userLoginEvents: LogEvent[];
  totalCount: number;
}

const initialState : AllUserLogs = {
  userLoginEvents: [],
  totalCount: 0,
  pending: false,
};

export const userLogReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAllUserLogs.pending, (state) => {
      state.pending = true;
    })
    .addCase(fetchAllUserLogs.fulfilled, (state, { payload }) => ({
      ...state,
      userLoginEvents: payload.logs,
      totalCount: payload.totalCount,
      pending: false,
    }))
    .addCase(fetchAllUserLogs.rejected, (state) => {
      state.pending = false;
    })
    .addCase(clearAllUserLogs, (state) => {
      state.userLoginEvents = [];
      state.totalCount = 0;
    })
});
