import Input from "@components/core/Input";
import Select from "@components/core/Select";
import AutoComplete from "antd/lib/auto-complete";
import {
  ChildComponentType,
  ResourceComponentValueProps,
} from "@customTypes/components/ResourceComponentValue";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { beadlSelector } from "@store/beadl";
import { RootState } from "@store/index";
import { ArgumentDefinitionState } from "@customTypes/store/beadlConfiguration";

/**
 * Renders;
 * - `Dropdown` if all possible values' `isDataType` field is `false`
 * - `Input` if all possible values' `isDataType` field is `true`
 * - `AutoComplete` there are some possible values with `isDataType`
 *    field is `false` and it's `true` for some others
 */
const ResourceComponentValue = ({
  componentId,
  value,
  onChange,
  className,
  virtualComponentId,
}: ResourceComponentValueProps) => {
  const {
    resourceComponentValues: { data: resourceComponentValues },
    resourceComponents: { data: resourceComponents },
  } = useSelector(beadlSelector);

  const { data: argumentDefinitions } = useSelector<RootState, ArgumentDefinitionState>(
    (state) => state.beadlConfiguration.argumentDefinition
  );

  const { childComponentType, options } = useMemo(() => {
    if (virtualComponentId !== undefined) {
      return {
        childComponentType: "autoComplete",
        options: [],
      };
    }
    let result: { childComponentType: ChildComponentType; options: string[] } =
      {
        childComponentType: "input",
        options: [],
      };
    let trueDataTypeCount = 0;

    const junctionRowIds = resourceComponents.find(
      (rc) => rc.id === componentId,
    )?.resourceComponentValues || []

    const junctionRows = resourceComponentValues.filter((rcv) => junctionRowIds.includes(rcv.id))

    junctionRows.forEach((row) => {
      if (row.isDataType) {
        trueDataTypeCount++;
      } else {
        result.options.push(row.value);
      }
    });
    if (trueDataTypeCount === junctionRows.length) {
      result.childComponentType = "input";
    } else if (trueDataTypeCount === 0) {
      result.childComponentType = "select";
    } else {
      result.childComponentType = "autoComplete";
    }
    return result;
  }, [
    componentId,
    resourceComponents,
    resourceComponentValues,
    virtualComponentId,
  ]);

  if (childComponentType === "input") {
    return (
      <Input
        className={className}
        value={value}
        onChange={(event) => onChange && onChange(event.target.value)}
      />
    );
  }
  if (childComponentType === "select") {
    return (
      <Select
        className={className}
        selected={value}
        options={options}
        onChange={onChange}
      />
    );
  }
  return (
    <AutoComplete
      className={className}
      value={value}
      options={
        options
          .map((o) => ({ value: o, label: o }))
          .concat(argumentDefinitions.map((a) => ({ value: a.name, label: a.name })))
        }
      onSearch={onChange}
      onChange={onChange}
    />
  );
};

export default ResourceComponentValue;
