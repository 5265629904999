import { useCallback } from "react";
import Select from "@components/core/Select";
import { HardwareSelectorProps } from "@customTypes/components/selectors/HardwareSelector";
import { useSelector } from "react-redux";
import { itemIdentifier } from "../util";

import { beadlSelector } from "@store/beadl";

const HardwareSelector = (props: HardwareSelectorProps) => {
  const {
    selected,
    onChange,
    placeholder = "Select Hardware",
    options,
    size,
    className,
    disabled,
  } = props;

  const { hardwares: { data: hardwares } } = useSelector(beadlSelector);

  const handleChange = useCallback(
    (id: number) => {
      onChange && onChange(id);
    },
    [onChange]
  );

  return (
    <Select
      size={size}
      className={className}
      disabled={disabled}
      options={options || hardwares}
      selected={selected}
      onChange={handleChange}
      placeholder={placeholder}
      labelRender={(item) =>
        `${item.name}${item.version ? " - " + item.version : ""}`
      }
      itemIdentifier={(item) => itemIdentifier(item, "id")}
    />
  );
};

export default HardwareSelector;
