export const exportToFile = async (dataString: string, fileNameWithExtension: string = 'file.json', blobProperties: BlobPropertyBag = { type: "application/json" }) => {
  const blob = new Blob([dataString], blobProperties);
  const href = await URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = href;
  link.download = fileNameWithExtension;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
