import { createReducer } from '@reduxjs/toolkit';
import { BeadlState } from './beadl.types';
import { fetchAllBeadlResources } from './beadl.actions';

const initialState : BeadlState = {
  loading: true,
  argumentTypes: { data: [], dataIndexMappedById: {} },
  dependencyTypes: { data: [], dataIndexMappedById: {} },
  expressionTypes: { data: [], dataIndexMappedById: {} },
  hardwares: { data: [], dataIndexMappedById: {} },
  resourceComponentDirections: { data: [], dataIndexMappedById: {} },
  resources: { data: [], dataIndexMappedById: {} },
  resourceComponents: { data: [], dataIndexMappedById: {} },
  resourceComponentTypes: { data: [], dataIndexMappedById: {} },
  resourceComponentValues: { data: [], dataIndexMappedById: {} },
  resourceTypes: { data: [], dataIndexMappedById: {} },
  virtualActionComponents: { data: [], dataIndexMappedById: {} },
  virtualActions: { data: [], dataIndexMappedById: {} },
  virtualEventComponents: { data: [], dataIndexMappedById: {} },
  virtualEvents: { data: [], dataIndexMappedById: {} },
};

export const beadlReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchAllBeadlResources.pending, (state) => ({
      ...state,
      loading: true,
    }))
    .addCase(fetchAllBeadlResources.rejected, (state) => ({
      ...state,
      loading: false,
    }))
    .addCase(fetchAllBeadlResources.fulfilled, (state, { payload }) => ({
      ...state,
      loading: false,
      argumentTypes: {
        ...state.argumentTypes,
        data: payload.argumentTypes,
      },
      expressionTypes: {
        ...state.expressionTypes,
        data: payload.expressionTypes,
      },
      dependencyTypes: {
        ...state.dependencyTypes,
        data: payload.dependencyTypes,
      },
      hardwares: {
        ...state.hardwares,
        data: payload.hardwares,
      },
      resourceComponentDirections: {
        ...state.resourceComponentDirections,
        data: payload.resourceComponentDirections,
      },
      resources: {
        ...state.resources,
        data: payload.resources,
      },
      resourceComponents: {
        ...state.resourceComponents,
        data: payload.resourceComponents,
      },
      resourceComponentTypes: {
        ...state.resourceComponentTypes,
        data: payload.resourceComponentTypes,
      },
      resourceComponentValues: {
        ...state.resourceComponentValues,
        data: payload.resourceComponentValues,
      },
      resourceTypes: {
        ...state.resourceTypes,
        data: payload.resourceTypes,
      },
      virtualActionComponents: {
        ...state.virtualActionComponents,
        data: payload.virtualActionComponents,
      },
      virtualActions: {
        ...state.virtualActions,
        data: payload.virtualActions,
      },
      virtualEventComponents: {
        ...state.virtualEventComponents,
        data: payload.virtualEventComponents,
      },
      virtualEvents: {
        ...state.virtualEvents,
        data: payload.virtualEvents,
      },
    }))
});
