import Select from "@components/core/Select";
import { StateSelectorProps } from "@customTypes/components/selectors/StateSelector";
import { RootState } from "@store/index";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { BeadlState } from "@customTypes/model/beadlEditor/beadlState";
import { Edge } from "react-flow-renderer";
import { itemIdentifier, renderLabel } from "../util";

const State = (props: StateSelectorProps) => {
  const {
    selected,
    onChange,
    placeholder = "Select a state",
    options,
    size,
    className,
    disabled,
  } = props;

  const states = useSelector<RootState, BeadlState[]>((state: RootState) =>
    state.beadlEditor.tabNameDataMap[state.beadlEditor.activeTab].flowElements
      .filter(
        (element) =>
          (element as Edge).source === undefined &&
          (element as Edge).target === undefined
      )
      .map((element) => element.data as BeadlState)
  );

  const handleChange = useCallback(
    (id: BeadlState["id"], item?: BeadlState) => {
      onChange && onChange(id, item);
    },
    [onChange]
  );

  const computedOptions = options || states;

  return (
    <Select
      disabled={disabled || computedOptions.length === 0}
      size={size}
      className={className}
      options={options || states}
      selected={selected}
      onChange={handleChange}
      placeholder={placeholder}
      labelRender={(item) => renderLabel(item, "name")}
      itemIdentifier={(item) => itemIdentifier(item, "name")}
    />
  );
};

export default State;
