import * as env from 'env-var';

export const awsCognitoConfig = {
  aws_cognito_region: env.get('REACT_APP_COGNITO_REGION').required().asString(),
  aws_user_pools_id: env.get('REACT_APP_COGNITO_POOL_ID').required().asString(),
  aws_user_pools_web_client_id: env.get('REACT_APP_COGNITO_CLIENT_ID').required().asString(),
};

export const CUSTOM_ERROR = 'CUSTOM_ERROR';

export const PAGINATION_LIMIT = 50;

export enum IRoutes {
  Forgot_Password = '/forgot-password',
  Home = '/',
  Login = '/',
  Logout = '/logout',
  Register = '/register',
  Reset_Password = '/reset-password',
}

export const COMBINED = "Combined";
export const CONFIGURATIONS = "Configurations";
export const BEADL_STATE = "BeadlState";
