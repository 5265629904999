import { Button } from "antd";
import { api } from "../../api";
import { withoutAuth } from "../../hocs";
import { useState } from "react";
import { IForgotPasswordType } from "types";
import { sendError, sendMessage } from "@util/helpers/notifications.helper";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { CustomInput } from "@components/FormFields";
import { useNavigate } from "react-router-dom";
import { IRoutes } from "../../config";

const validationSchema = Yup.object({
  email: Yup.string().trim().lowercase().email('Invalid email!')
    .required('Email is required!'),
});

const ForgotPassword = () => {
  const [ loading, setLoading ] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (payload: IForgotPasswordType) => {
    setLoading(true);

    try {
      await api.post<boolean>('/auth/forgot-password', payload);

      formik.resetForm();
      sendMessage(`Please check your inbox! If the email address ${payload.email} is linked to an account, an email with reset instructions has been sent.`)
      navigate(IRoutes.Home)
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  }

  const initialValues: IForgotPasswordType = {
    email: '',
  };

  const formik = useFormik<IForgotPasswordType>({
    initialValues: initialValues,
    validationSchema,
    onSubmit
  });


  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex items-center justify-center h-screen bg-indigo-100">
        <div className="w-2/3 lg:w-2/5 md:w-1/2">
          <div className="min-w-full p-10 bg-white shadow-lg">
              <h1 className="mb-6 text-2xl font-bold text-center text-gray-600">Forgot Password</h1>
              <div>
                <label className="block my-3 font-semibold text-left text-gray-800 text-md">Email</label>
                <CustomInput formik={formik} name="email" placeholder="Email" />
              </div>
              <Button
                htmlType="submit"
                loading={loading}
                type="primary"
                className="mt-6 font-semibold tracking-wide text-white cs_input"
              >
                Submit
              </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default withoutAuth(ForgotPassword);
