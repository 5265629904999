import { Button, Col, Row } from 'antd';
import React, { useState } from 'react'
import { ChangePassword } from './ChangePassword.component';
import { UpdateUserForm } from './UpdateUserForm.component';

export const ProfileActions = () => {
  const [visiblePassChange, setVisiblePassChange] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);

  return (
    <>
      <Row gutter={[16, 16]} className="px-5 py-0">
        <Col span={24}>
          <Button
            className="w-full truncate"
            onClick={() => setVisibleUpdate(true)}
          >
            Update profile
          </Button>
        </Col>
        <Col span={24}>
          <Button
            className="w-full truncate"
            onClick={() => setVisiblePassChange(true)}
          >
            Change password
          </Button>
        </Col>
      </Row>

      <ChangePassword
        visible={visiblePassChange}
        onCancel={() => setVisiblePassChange(false)}
      />
      <UpdateUserForm
        visible={visibleUpdate}
        onCancel={() => setVisibleUpdate(false)}
      />
    </>
  );
}
