import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

export const selectUserLogState = (state: RootState) => state.log;
export const selectAllUserLogsData = (state: RootState) => state.log;
export const selectLogTotalCount = (state: RootState) => state.log.totalCount;

export const userLogStateSelector = createSelector(selectUserLogState, (state) => state);

export const userAllLogsSelector = createSelector(
  selectAllUserLogsData, (state) => state,
);
