import { ConnectionMapping } from "@customTypes/model/beadlConfiguration/connectionMapping";
import { BeadlConfigurationState } from "@customTypes/store/beadlConfiguration";
import { ConnectionMappingState } from "@customTypes/store/beadlConfiguration";
import { PayloadAction } from "@reduxjs/toolkit";
import { getDataIndexMappedByField } from "@store/util";
import { stringToNumber } from "@util/helpers/stringToNumber.helper";

const filterNonRepeatingValues = (
  connectionMappings: ConnectionMapping[]
) => {
  const connectionMappingSet = new Set<string>();
  return connectionMappings.filter((connectionMapping) => {
    const isRepeating = connectionMappingSet.has(connectionMapping.name);
    if (isRepeating) {
      return false;
    }
    connectionMappingSet.add(connectionMapping.name);
    return true;
  });
};

export const connectionMappingState: ConnectionMappingState = {
  data: [],
  nonRepeatingData: [],
  dataIndexMappedById: {},
};

export const connectionMappingReducers = {
  setConnectionMappings: (
    state: BeadlConfigurationState,
    action: PayloadAction<ConnectionMapping[]>
  ) => {
    const { payload } = action;
    const nonRepeatingData = filterNonRepeatingValues(payload);
    return {
      ...state,
      connectionMapping: {
        data: payload.map((d) => ({
          ...d,
          resource_type_id: stringToNumber(d.resource_type_id),
          resource_id: stringToNumber(d.resource_id),
          dependency: { ...d.dependency, type_id: stringToNumber(d.dependency.type_id) },
        })),
        nonRepeatingData,
        dataIndexMappedById: getDataIndexMappedByField(payload, "id"),
      },
    };
  },

  updateConnectionMapping: (
    state: BeadlConfigurationState,
    { payload }: PayloadAction<ConnectionMapping>
  ) => {
    const data = state.connectionMapping.data.map((d) => (
      d.id !== payload.id ? d : payload
    ));
    const nonRepeatingData = filterNonRepeatingValues(data);

    return {
      ...state,
      connectionMapping: {
        ...state.connectionMapping,
        data,
        nonRepeatingData,
        dataIndexMappedById: getDataIndexMappedByField(data, "id"),
      },
    };
  },

  addNewConnectionMapping: (
    state: BeadlConfigurationState,
    { payload }: PayloadAction<ConnectionMapping>
  ) => {
    const data = [...state.connectionMapping.data, payload];
    const nonRepeatingData = filterNonRepeatingValues(data);

    return {
      ...state,
      connectionMapping: {
        ...state.connectionMapping,
        data,
        nonRepeatingData,
        dataIndexMappedById: getDataIndexMappedByField(data, "id"),
      },
    };
  },

  deleteConnectionMapping: (
    state: BeadlConfigurationState,
    { payload }: PayloadAction<ConnectionMapping>
  ) => {
    const getErrorMessage = (name: string, type: string) =>
      `${name} ${type} is dependent on this connection! If you want to remove this connection, first unlink the ${type}`;

    const duplicateData = state.connectionMapping.data
      .filter((d) => d.name === payload.name)
      .map((d) => d.id);

    const dependentAction = state.actionDefinition.data.find(
      (ad) => duplicateData.includes(ad.connection?.mapping_id as string)
    );

    if (dependentAction) {
      throw new Error(getErrorMessage(dependentAction.name, "action"));
    }

    const dependentEvent = state.eventDefinition.data.find(
      (ed) => duplicateData.includes(ed.connection?.mapping_id as string)
    );

    if (dependentEvent) {
      throw new Error(getErrorMessage(dependentEvent.name, "event"));
    }

    const data = state.connectionMapping.data.filter(
      (cm) => cm.id !== payload.id
    );

    return {
      ...state,
      connectionMapping: {
        ...state.connectionMapping,
        data,
        nonRepeatingData: filterNonRepeatingValues(data),
        dataIndexMappedById: getDataIndexMappedByField(data, "id"),
      },
    };
  },
};
