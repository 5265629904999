import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '..';

export const selectLoggedInUser = (state: RootState) => state.auth.user;
export const selectFirstTimeUser = (state: RootState) => state.auth.firstTimeUser;

export const userSelector = createSelector(
  selectLoggedInUser,
  (state) => state,
);

export const firstTimeUserSelector = createSelector(
  selectFirstTimeUser,
  (state) => state,
);
