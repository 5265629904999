// import Hardware from "@customTypes/model/hardware";
// import { RootState } from "@store/index";
import { myWorkspaceSelector } from '@store/workspace';
import { Typography } from 'antd'
import { useSelector } from 'react-redux';

export const FileDetail = () => {
  const { selectedItem } = useSelector(myWorkspaceSelector);
  // const hardwares = useSelector<RootState, Hardware[]>(
  //   (state: RootState) => state.hardwares.data
  // );

  if (!selectedItem) return <div />;

  return (
    <div className='flex flex-col flex-wrap justify-center w-4/5 px-5'>
      <Typography.Text className='text-base font-semibold text-center underline truncate'>Properties</Typography.Text>
      <Typography.Text className='text-left truncate'>Name: {selectedItem?.title}</Typography.Text>
      {/* <Typography.Text className='text-left'>Hardware: Bpod r0.5</Typography.Text>
      <Typography.Text className='text-left'>Start state: WaitFor Poke</Typography.Text>
      <Typography.Text className='text-left'># of trials: inf</Typography.Text> */}
    </div>
  );
}
