import { CustomCheckbox, CustomInput } from '@components/FormFields';
import { IChangePass, User } from '../../../../../types';
import { adminAllUsersSelector, updateUser } from '@store/user';
import { Button, Modal, Typography } from 'antd'
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { api } from '../../../../../api';
import { sendError, sendMessage } from '@util/helpers/notifications.helper';

interface Props {
  visible: boolean;
  onCancel: () => void;
}

const validationSchema = Yup.object({
  password: Yup.string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      'Password must contain number, uppercase, lowercase, one special character. Password must be at least 8 characters long!',
    ).required('Password is required'),
  rpassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords do not match!')
    .required('Confirm password is required!'),
});

export const PasswordChangeForm = ({ visible, onCancel }: Props) => {
  const [loading, setLoading] = useState(false);

  const { selectedUser } = useSelector(adminAllUsersSelector);

  const dispatch = useDispatch();

  const cancelEvent = () => {
    formik.resetForm();
    onCancel();
  };

  const onSubmit = async ({ password, isPermanentPassword }: IChangePass) => {
    if (!selectedUser?.id) return;
    setLoading(true);

    try {
      const payload = { password, isPermanentPassword, username: selectedUser.username };
      const { data } = await api.patch<User>(`/users/${selectedUser.id}/password`, payload);
      if (!data) throw new Error();

      dispatch(updateUser(data));

      sendMessage('Password updated successfully!');

      cancelEvent();
    } catch (err) {
      sendError(err);
    } finally {
      setLoading(false);
    }
  }

  const formik = useFormik<IChangePass>({
    initialValues: { password: '', rpassword: '', isPermanentPassword: false },
    validationSchema,
    onSubmit,
  });

  return (
    <Modal
      className='users-actions_modal'
      title={(
        <Typography.Text className='flex justify-center text-base font-bold'>
          {`Change password for ${selectedUser?.username}`}
        </Typography.Text>
      )}
      centered
      visible={visible}
      onCancel={cancelEvent}
      footer={false}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className='flex flex-wrap w-full gap-4 px-2 pr-10'>
          <div className='flex flex-col gap-1.5 w-3/5'>
            <label className='text-xs font-light' htmlFor="password">Password</label>
            <CustomInput formik={formik} name='password' type="password" placeholder='Password' />
          </div>

          <div className='flex flex-col gap-1.5 w-3/5'>
            <label className='text-xs font-light' htmlFor="rpassword">Repeat Password</label>
            <CustomInput formik={formik} name='rpassword' type="password" placeholder='Repeat Password' />
          </div>

          <div className='w-2/4'>
            <CustomCheckbox
              name="isPermanentPassword"
              formik={formik}
              placeholder="Permanent set password"
            />
          </div>

          <div className='w-2/4' />
          <div className='w-2/4' />
          <div className='w-2/4' />
          <div className='w-2/4' />
          <div className='w-2/4' />
        </div>

        <div className='flex justify-between w-full px-2 pt-10'>
          <Button type="ghost" className="w-2/5 btn-br-black" onClick={cancelEvent}>Cancel</Button>
          <Button type="ghost" className="w-2/5 btn-br-black" htmlType="submit" loading={loading}>
            Update Password
          </Button>
        </div>
      </form>
    </Modal>
  )
}