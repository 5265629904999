import { InputProps } from "@customTypes/components/core/Input";
import AntInput from "antd/lib/input";
import styled from "styled-components";

const StyledInput = styled(AntInput)<InputProps>`
  text-align: center;
  font-size: 14px;
`;

/** @category @components/core */
export const Input = ({ label, ...props }: InputProps) => (
  <StyledInput addonBefore={label} {...props} />
);

export default Input;
