import { Handle, HandleProps } from "react-flow-renderer";
import styled from "styled-components";

/** @category @components/BeadlEditor */
export const BeadlHandle = styled(Handle)<HandleProps>`
  top: 50%;
  border-radius: 0;
`;

export default BeadlHandle;
