import Select from "@components/core/Select";
import { BeadlEvent } from "@customTypes/model/beadlConfiguration/eventDefinition";
import { EventSelectorProps } from "@customTypes/components/selectors/EventSelector";
import { RootState } from "@store/index";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { itemIdentifier, renderLabel } from "../util";

import {
  STATE_EXPIRED_EVENT_ID,
  STATE_EXPIRED_EVENT_NAME,
} from "@util/constants";

const EventSelector = (props: EventSelectorProps) => {
  const {
    selected,
    onChange,
    placeholder = "Input BeadlEvent",
    options,
    disabled,
    className,
  } = props;

  const events = useSelector<RootState, BeadlEvent[]>(
    (state: RootState) => state.beadlConfiguration.eventDefinition.data
  );

  const handleChange = useCallback(
    (id: number) => {
      onChange && onChange(id);
    },
    [onChange]
  );

  const placeholderOrStateExpiredEventLabel = useMemo(
    () =>
      selected === STATE_EXPIRED_EVENT_ID
        ? `${STATE_EXPIRED_EVENT_NAME} expired`
        : placeholder,
    [selected, placeholder]
  );

  return (
    <Select
      className={className}
      options={options || events}
      selected={selected}
      onChange={handleChange}
      placeholder={placeholderOrStateExpiredEventLabel}
      labelRender={(item) => selected === STATE_EXPIRED_EVENT_ID ? `${STATE_EXPIRED_EVENT_NAME} expired` : renderLabel(item, "name")}
      itemIdentifier={(item) => itemIdentifier(item, "id")}
      disabled={disabled}
    />
  );
};

export default EventSelector;
