import { PayloadAction } from "@reduxjs/toolkit";
import {
  addEdge,
  ArrowHeadType,
  Edge,
  FlowElement,
  Node,
  removeElements,
} from "react-flow-renderer";
import { BeadlEditorDrawerState } from "@customTypes/store/beadlEditor";
import {
  BeadlEditorState,
  SetBaseStateNamePayload,
  StateAddOrUpdatePayload,
} from "@customTypes/store/beadlEditor";
import { BeadlState } from "@customTypes/model/beadlEditor/beadlState";
import { createBeadlState } from "@util/constructor/beadlState";
import { createFlowNode } from "@util/constructor/flowModel";
import {
  checkIfStateExpiredExists,
  checkIfStateExpiredRequired,
  getBeadlEditorStateNameIndexMap,
  updateActiveTabData,
} from "./util";
import { BeadlTransition } from "@customTypes/model/beadlEditor/beadlTransition";
import { getEdgeId } from "@util/helpers";
import { BEADL_EDGE, STATE_EXPIRED_EVENT_ID } from "@util/constants";
import { BeadlStateEvent } from "@customTypes/model/beadlEditor/beadlState/beadlStateEvent";

export const beadlEditorDrawerState: BeadlEditorDrawerState = {
  open: false,
  formData: createBeadlState(),
  baseStateName: undefined,
};

export const beadlEditorDrawerReducer = {
  changeDrawerOpen: (state: BeadlEditorState): BeadlEditorState => {
    return {
      ...state,
      drawerState: {
        ...state.drawerState,
        open: !state.drawerState.open,
      },
    };
  },
  setFormData: (
    state: BeadlEditorState,
    action: PayloadAction<BeadlState | undefined>
  ) => {
    const formData = action.payload || createBeadlState();
    return {
      ...state,
      drawerState: {
        ...state.drawerState,
        formData,
      },
    };
  },
  setBaseStateName: (
    state: BeadlEditorState,
    action: PayloadAction<SetBaseStateNamePayload>
  ) => {
    const baseStateName = action.payload;
    return {
      ...state,
      drawerState: {
        ...state.drawerState,
        baseStateName,
      },
    };
  },
  addOrUpdateNode: (
    state: BeadlEditorState,
    action: PayloadAction<StateAddOrUpdatePayload>
  ) => {
    const { updated, isDuplicate } = action.payload;
    const baseName = state.drawerState.baseStateName;
    let beadlState = { ...updated };

    const activeTabState = state.tabNameDataMap[state.activeTab];

    let stateNameIndexMap = { ...activeTabState.stateNameIndexMap };
    let flowElements = [...activeTabState.flowElements];

    if (checkIfStateExpiredRequired(beadlState)) {
      if (!checkIfStateExpiredExists(beadlState)) {
        const nextEvents: BeadlStateEvent[] = [...(beadlState.events || [])];
        nextEvents.unshift({
          event_id: STATE_EXPIRED_EVENT_ID,
        });
        beadlState.events = nextEvents;
      }
    } else {
      if (checkIfStateExpiredExists(beadlState)) {
        // `stateTimer` event shouldn't exist, but it does
        const nextEvents: BeadlStateEvent[] = [...(beadlState.events || [])];
        nextEvents.splice(0, 1);
        beadlState.events = nextEvents;
      }
    }

    let flowElement: FlowElement<BeadlState>;
    let index: number;
    if (baseName && !isDuplicate) {
      index = activeTabState.stateNameIndexMap[baseName];
      const previousFlowElement = flowElements[
        index
      ] as FlowElement<BeadlState>;

      // update current node data
      flowElement = createFlowNode(
        beadlState,
        (previousFlowElement as Node<BeadlState>).position
      );
      flowElements.splice(index, 1, flowElement);

      // update connections if name is changed
      flowElements.forEach((elementBase, idx, array) => {
        const element = elementBase as Edge;
        if (element.source && element.target) {
          const { source, target } = element;
          if (source !== baseName && target !== baseName) {
            return;
          }
          const newTransitionElement = { ...element } as Edge;
          if (source === baseName) {
            newTransitionElement.source = beadlState.name;
            newTransitionElement.id = getEdgeId({ ...newTransitionElement });
          } else {
            newTransitionElement.target = beadlState.name;
            newTransitionElement.id = getEdgeId({ ...newTransitionElement });
          }
          array.splice(idx, 1, newTransitionElement);
        } else if (elementBase.type === "beadl") {
          const nextBeadlElement = elementBase as FlowElement<BeadlState>;
          const { data } = nextBeadlElement;
          let affected = false;
          const nextEvents = data!.events?.map((currentEvent) => {
            const nextEvent = { ...currentEvent };
            if (currentEvent.triggered_state_id === baseName) {
              affected = true;
              nextEvent.triggered_state_id = beadlState.name;
            }
            return nextEvent;
          });
          if (affected) {
            array.splice(idx, 1, {
              ...nextBeadlElement,
              data: {
                ...(data as BeadlState),
                events: nextEvents,
              },
            });
          }
        }
      });
      delete stateNameIndexMap[baseName];
    } else {
      // add current node
      flowElement = createFlowNode(beadlState);
      flowElements.push(flowElement);
      index = flowElements.length - 1;
    }
    stateNameIndexMap[beadlState.name] = index;

    // remove all connections sourced by that state
    const previousTransitions = flowElements.filter((element) => {
      const transition = element as unknown as BeadlTransition;
      return transition.source === beadlState.name && !!transition.target;
    });
    flowElements = removeElements(previousTransitions, flowElements);
    stateNameIndexMap = getBeadlEditorStateNameIndexMap(flowElements);

    // add connections sourced by that state
    flowElements = (beadlState.events || []).reduce(
      (acc, element, elementIndex) => {
        if (element.triggered_state_id === undefined) {
          return acc;
        }

        const targetStateNameIndexTuple = Object.entries(
          stateNameIndexMap
        ).find(
          ([entryStateName]) => entryStateName === element.triggered_state_id
        );

        const edgePayload = {
          source: beadlState.name,
          sourceHandle: `${elementIndex}`,
          target:
            targetStateNameIndexTuple !== undefined
              ? targetStateNameIndexTuple[0]
              : "",
          targetHandle: "a",
        };
        return addEdge(
          {
            arrowHeadType: ArrowHeadType.ArrowClosed,
            type: BEADL_EDGE,
            id: getEdgeId(edgePayload),
            ...edgePayload,
          },
          acc
        );
      },
      flowElements
    );

    if (flowElements.length === 1) {
      const endBeadlState = createBeadlState({
        id: "-1",
        name: "End",
        duration: {
          value: "",
        },
        events: [],
        actions: [],
      });
      const finalFlowElement = createFlowNode(endBeadlState);
      flowElements.push(finalFlowElement);
    }
    stateNameIndexMap = getBeadlEditorStateNameIndexMap(flowElements);

    return updateActiveTabData(state, flowElements, stateNameIndexMap);
  },
};
