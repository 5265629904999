import { withAuth } from '../../../hocs'
import { DashboardLayout } from '../../../layouts/Dashboard.layout'
import { SuperAdminActions } from '../components/SuperAdminActions.component'
import { NotificationsTable } from './components/NotificationsTable.component'

const AdminNotifications = () => {
  return (
    <DashboardLayout
      navbarTitle="Notifications"
      leftSidebar={<SuperAdminActions />}
      mainComponent={<NotificationsTable />}
      hideRightSidebar
    />
  )
}

export default withAuth(AdminNotifications, ['Admin']);
