import { useDispatch, useSelector } from 'react-redux';
import { Button, Pagination, Popconfirm, Row, Switch, Table, Tag, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs';
import { adminAllUsersSelector, deselectUser, fetchAllUsers, selectUser, updateUser } from '@store/user';
import { useComponentDidMount } from '../../../../hooks';
import { User } from '../../../../types';
import { CheckCircleOutlined, DeleteOutlined, EditFilled, LockOutlined, UsergroupAddOutlined, UsergroupDeleteOutlined } from '@ant-design/icons';
import { userSelector } from '@store/auth';
import { ColumnsType } from 'antd/lib/table';
import { api } from '../../../../api';
import { useState } from 'react';
import { sendError, sendMessage } from '@util/helpers/notifications.helper';
import { PAGINATION_LIMIT } from '../../../../config';
import { AddUpdateForm } from './UserActions/AddUpdateForm.component';
import { PasswordChangeForm } from './UserActions/PasswordChangeForm.component';
import { DeleteUserModal } from './UserActions/DeleteUserModal.component';
import { Link } from 'react-router-dom';

interface Props {
  myId: string;
  updatingUserId: string;
  editUser: (val: User) => void;
  deleteUser: (val: User) => void;
  editUserPass: (val: User) => void;
  updateUserStatus: (user: User) => void;
  setVisibleAddEdit: (val: boolean) => void;
  setVisiblePassChange: (val: boolean) => void;
  setVisibleDelete: (val: boolean) => void;
  onAdminStatusUpdate: (user: User) => void;
  adminStatusLoading: string;
}

const limit = PAGINATION_LIMIT;

const columns = ({
  myId,
  updatingUserId,
  editUser,
  deleteUser,
  editUserPass,
  updateUserStatus,
  onAdminStatusUpdate,
  adminStatusLoading
}: Props): ColumnsType<User> => [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      sorter: (a, b) => a.firstName.localeCompare(b.firstName),
      render: (firstName, { id }: User) => (
        <Link to={`/admin/users/${id}`}>
          <Button type='link'>
            {firstName}
          </Button>
        </Link>
      )
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      sorter: (a, b) => a.lastName.localeCompare(b.lastName),
    },
    {
      title: 'Username',
      sorter: (a, b) => a.username.localeCompare(b.username),
      render: ({ username, group }: User) => (
        <Typography>
          {username}
          &nbsp;
          {group === 'Admin' && <Tag color="green">{group}</Tag>}
        </Typography>
      ),
    },
    {
      title: 'Email',
      render: ({ email, isEmailVerified }: User) => (
        <>
          {email}
          &nbsp;
          {isEmailVerified ? (
            <Tooltip title="Verified">
              <CheckCircleOutlined style={{ color: '#52c41a' }} />
            </Tooltip>
          ) : null}
        </>
      ),
      sorter: (a, b) => a.email.localeCompare(b.email)
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
      sorter: (a, b) => a.organization.title.localeCompare(b.organization.title),
      render: (organization) => organization?.title || 'NA',
    },
    {
      title: 'Create date',
      dataIndex: 'createdAt',
      render: (createdAt: string) => dayjs(createdAt).format('ll'),
    },
    {
      title: 'Last Login',
      render: ({ lastLogin }: User) => lastLogin?.createdAt ? dayjs(lastLogin.createdAt).format('ll') : 'NA',
    },
    {
      title: 'Actions',
      render: (user: User) => (
        <div className='flex gap-2.5'>
          <Switch
            checked={user.isActive}
            disabled={myId === user.id}
            loading={updatingUserId === user.id}
            onChange={() => updateUserStatus(user)}
          />

          <Tooltip placement="topLeft" title="Edit user">
            <Button icon={<EditFilled />} onClick={() => editUser(user)}
              className="relative btn-default_icons bottom-px" />
          </Tooltip>

          <Tooltip placement="topLeft" title="Edit password">
            <Button icon={<LockOutlined />} onClick={() => editUserPass(user)}
              className="btn-default_icons" />
          </Tooltip>

          {user.id !== myId && (
            <Tooltip placement="topLeft" title={user?.group === "Admin" ? 'Remove Admin access' : 'Grant Admin access'}>
              <Popconfirm
                title={`Are you sure you want to ${user?.group === "Admin" ? "remove" : "give"
                  } admin access ${user?.group === "Admin" ? "from" : "to"
                  } ${user?.username}?`}
                onConfirm={() => onAdminStatusUpdate(user)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  loading={adminStatusLoading === user.id}
                  icon={user?.group === "Admin"
                    ? <UsergroupDeleteOutlined />
                    : <UsergroupAddOutlined />
                  }
                  className="btn-default_icons"
                >
                </Button>
              </Popconfirm>
            </Tooltip>
          )}

          <Tooltip placement="topLeft" title="Delete user">
            {user.id !== myId && (
              <Button icon={<DeleteOutlined />} danger onClick={() => deleteUser(user)}
                className="btn-default_icons" />
            )}
          </Tooltip>
        </div>
      )
    }
  ];

export const UsersTable = () => {
  const dispatch = useDispatch();
  const [visibleAddEdit, setVisibleAddEdit] = useState(false);
  const [visiblePassChange, setVisiblePassChange] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [adminStatusLoading, setAdminStatusLoading] = useState('');

  const [defaultPage, setDefaultPage] = useState(1);
  const [updatingUserId, setUpdatingUserId] = useState('');

  const { users, totalCount } = useSelector(adminAllUsersSelector);
  const myId = useSelector(userSelector)?.id || '';

  useComponentDidMount(() => dispatch(fetchAllUsers({ payload: { limit, offset: 0 } })));

  const updateUserStatus = async (user: User) => {
    setUpdatingUserId(user.id);

    try {
      const status = !user.isActive;
      const { data } = await api.patch<User>(`/users/${user.id}/status`, { status });
      if (!data) throw new Error();

      sendMessage('User updated successfully!');

      dispatch(updateUser({ ...data, organization: user.organization }));
    } catch (err) {
      sendError(err);
    } finally {
      setUpdatingUserId('');
    }
  };

  const addNewUser = () => {
    dispatch(deselectUser());
    setVisibleAddEdit(true);
  };

  const editUser = (user: User) => {
    dispatch(selectUser(user));
    setVisibleAddEdit(true);
  };

  const deleteUser = (user: User) => {
    dispatch(selectUser(user));
    setVisibleDelete(true);
  };

  const editUserPass = (user: User) => {
    dispatch(selectUser(user));
    setVisiblePassChange(true);
  };

  const onCancel = (fn: (val: boolean) => void) => {
    dispatch(deselectUser());
    fn(false);
  };

  const onAdminStatusUpdate = async (user: User) => {
    if (!user) return;
    dispatch(selectUser(user));

    setAdminStatusLoading(user.id);

    try {
      const { status } = await api.patch(`/users/${user.id}/admin`);
      if (status !== 200) throw new Error();

      sendMessage("User's admin status updated successfully!");


      dispatch(updateUser({ ...user, group: user.group === "Admin" ? "User" : "Admin" }));
      dispatch(deselectUser());
    } catch (err) {
      sendError(err);
    } finally {
      setAdminStatusLoading('');
    }
  };

  return (
    <>
      <div className='flex justify-start'>
        <Button type="primary" onClick={addNewUser}>
          Add User
        </Button>
      </div>

      <Table
        rowKey="id"
        dataSource={users}
        columns={columns({
          myId, updatingUserId, updateUserStatus,
          setVisibleAddEdit, setVisiblePassChange, setVisibleDelete,
          editUser, deleteUser, editUserPass,
          onAdminStatusUpdate, adminStatusLoading
        })}
        pagination={false}
      />

      {totalCount > users.length && (
        <Row className='mt-5' justify="end">
          <Pagination
            simple
            current={defaultPage}
            total={totalCount}
            pageSize={limit}
            onChange={async (page: number) => {
              setDefaultPage(page)
              dispatch(fetchAllUsers({
                payload: {
                  limit,
                  offset: limit * (page - 1)
                }
              }))
            }}
          />
        </Row>
      )}

      <AddUpdateForm
        visible={visibleAddEdit}
        onCancel={() => onCancel(setVisibleAddEdit)}
      />

      <PasswordChangeForm
        visible={visiblePassChange}
        onCancel={() => onCancel(setVisiblePassChange)}
      />

      <DeleteUserModal
        visible={visibleDelete}
        onCancel={() => onCancel(setVisibleDelete)}
      />
    </>
  )
}
