import { useMemo } from "react";
import { TableProps } from "@customTypes/components/core/Table";
import styled from "styled-components";

import {
  CopyOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";

import AntTable from "antd/lib/table";
import Button from "antd/lib/button";

type EditableTableProps = Parameters<typeof Table>[0];
type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

const TableContainer = styled.div`
  &:hover {
    .action-button {
      opacity: 1;
    }
  }
  .action-button {
    opacity: 0;
  }

  .ant-table-tbody {
    & > tr {
      &:last-child {
        & > td {
          border-bottom: 0px;
        }
      }
    }
  }
`;

const ActionWrapperRow = styled.div`
  & > * {
    & + * {
      margin-left: 4px;
    }
  }
`;

const Table = <T extends {} = {}>(props: TableProps<T>) => {
  const { columns, data, actionConfig = {}, rowKey = "id", scroll } = props;
  const extendedColumns = useMemo(() => {
    const extended = [...columns];
    let totalAvailableSpacePercent = 100;
    const { add, ...rowActionConfig } = actionConfig;
    let rowActionCount = Object.values(rowActionConfig).reduce(
      (acc, item) => acc + (item?.enabled ? 1 : 0),
      0
    );
    if (add?.enabled && rowActionCount > 0) {
      const actionsColumnWidthValue = 3 * (1 + rowActionCount);
      const actionsColumnWidth = `${actionsColumnWidthValue}%`;
      totalAvailableSpacePercent -= actionsColumnWidthValue;

      extended.forEach(
        (column) =>
          (column.width =
            column.width ||
            `${(totalAvailableSpacePercent / (extended.length || 1)).toFixed(
              2
            )}%`)
      );
      extended.unshift({
        title: () =>
          actionConfig.add?.enabled ? (
            <Button
              key="add-action-button"
              className="action-button"
              onClick={() =>
                actionConfig.add?.action && actionConfig.add?.action()
              }
              icon={<PlusCircleOutlined />}
            />
          ) : (
            ""
          ),
        dataIndex: "TABLE_ACTIONS",
        render: (_: string, item: T, index: number) => (
          <ActionWrapperRow className="flex">
            {actionConfig.delete?.enabled && (
              <Button
                onClick={() =>
                  actionConfig.delete?.action &&
                  actionConfig.delete?.action(index, item)
                }
                className="action-button"
                icon={<DeleteOutlined />}
              />
            )}
            {actionConfig.duplicate?.enabled && (
              <Button
                onClick={() =>
                  actionConfig.duplicate?.action &&
                  actionConfig.duplicate?.action(index, item)
                }
                className="action-button"
                icon={<CopyOutlined />}
              />
            )}
          </ActionWrapperRow>
        ),
        width: actionsColumnWidth,
      });
    }

    return extended;
  }, [actionConfig, columns]);
  return (
    <TableContainer className="relative w-full h-full mb-8 overflow-y-auto bg-white rounded-lg shadow-lg">
      <AntTable
        rowKey={rowKey}
        dataSource={data}
        columns={extendedColumns as ColumnTypes}
        pagination={false}
        scroll={scroll}
      />
    </TableContainer>
  );
};

export default Table;
