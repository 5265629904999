import { CSSProperties, FC, ReactNode } from 'react';
import {
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';

interface P {
  id: string;
  index: number;
  children: ReactNode;
}
const getItemStyle = (
  draggableStyle?: DraggingStyle | NotDraggingStyle,
): CSSProperties => ({
  userSelect: 'none',
  ...draggableStyle,
});

export const DraggableItem: FC<P> = ({ id, index, children }: P) => (
  <Draggable draggableId={id} index={index}>
    {(provided, snapshot) => (
      <div
        ref={provided.innerRef}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...provided.draggableProps}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...provided.dragHandleProps}
        style={getItemStyle(provided.draggableProps.style)}
      >
        {children}
      </div>
    )}
  </Draggable>
);
