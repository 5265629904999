import { Modal, Typography } from 'antd'
import { useSelector } from 'react-redux';
import { UpdateForm } from './UpdateForm.component';
import { AddForm } from './AddForm.component';
import { selectAllNotifications } from '@store/notification';

interface Props {
  visible: boolean;
  onCancel: () => void;
}

export const AddUpdateForm = ({ visible, onCancel }: Props) => {
  const { selectedNotification } = useSelector(selectAllNotifications);

  return (
    <Modal
      className='users-actions_modal'
      title={(
        <Typography.Text className='flex justify-center text-base font-bold'>
          {`${selectedNotification ? `Edit notification for ${selectedNotification.user.username}` : 'Add new notification'}`}
        </Typography.Text>
      )}
      centered
      visible={visible}
      onCancel={onCancel}
      footer={false}
      width={800}
    >
      {selectedNotification?.id
        ? <UpdateForm onCancel={onCancel} />
        : <AddForm onCancel={onCancel} />}
    </Modal>
  )
}